<script lang="ts" setup>
import { computed } from 'vue';
import { ZodIssue } from 'zod';

interface Props {
  errors: ZodIssue[];
}
const { errors } = defineProps<Props>();
const error = computed(() => errors[0]?.message ?? '');
</script>

<template>
  <div v-if="error" class="h-2">
    <p class="text-red text-sm">
      {{ $t(error) }}
    </p>
  </div>
</template>
