import * as t from 'io-ts';
import { PurchaseIO } from '../entities/Purchase';
import { EventIO } from '../entities/Event';
import { ScheduleIO } from '../entities/Schedule';
import { SaleIO } from '../entities/Sale';

export const PurchaseCollectionIO = t.interface({
  included: t.interface({
    schedules: t.array(ScheduleIO),
    events: t.array(EventIO),
    sales: t.array(SaleIO),
  }),
  meta: t.interface({
    hasNext: t.boolean,
    pageIndex: t.number,
  }),
  purchases: t.array(PurchaseIO),
});

export type PurchaseCollectionResponse = t.TypeOf<typeof PurchaseCollectionIO>;
