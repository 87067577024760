import {
  EntityTypeEnumSchema,
  EventDto,
  EventDtoSchema,
  INPUT_LANGUAGES,
} from 'goout-schemas';
import { ENDPOINT } from '~/definitions/constants/endpoints';
import { useFormStore } from '~/store/form.store';
import { useGooutFetch } from 'goout-api';
import { SourceSchema } from 'goout-schemas/source.schema';

export function usePublishEvent() {
  const { general, category, performer, image, metadata_film, venue } =
    useFormStore();

  async function perform(): Promise<string | undefined> {
    const eventData: Partial<EventDto> = {
      attributes: {
        state: 'unapproved',
        mainCategory: category.data.primaryCategory,
        categories: category.data.selectedCategories,
        keywords: [],
        tagsManual: [],
        exhibitionMeta: {
          curator: '',
        },
        source: SourceSchema.enum.creator,
        hasTimeSlots: false,
        locales: INPUT_LANGUAGES.reduce((acc, language) => {
          if (general.data.name[language])
            acc[language] = {
              name: general.data.name[language],
              descriptionHtml: general.data.description[language],
            };
          return acc;
        }, {}),
        tags: category.data.tags,
        filmMeta: metadata_film.data.values,
        minorPerformers: metadata_film.data.values.minorPerformers,
      },
      relationships: {
        videos: {
          data: [],
        },
        images: {
          data: image.data.images.map((image) => ({
            id: image.id,
            type: EntityTypeEnumSchema.enum.images,
          })),
        },
        performers: {
          data: performer.data.selectedItems.map((performer) => ({
            id: performer.id,
            type: EntityTypeEnumSchema.enum.performers,
          })),
        },
        venue: {
          data: {
            id: venue.data.selectedItem.id,
            type: EntityTypeEnumSchema.enum.venues,
          },
        },
        revisions: {
          data: [],
        },
      },
      links: {
        self: '',
      },
      type: EntityTypeEnumSchema.enum.events,
    };

    EventDtoSchema.partial().parse(eventData);
    const { data } = await useGooutFetch<EventDto>(ENDPOINT.EVENTS)
      .post(eventData)
      .json();

    return data.value.data.id; // returns eventId that is then used in publishSchedule
  }

  return {
    perform,
  };
}
