<script lang="ts" setup>
import Button from 'goout-ui-kit/button/Button.vue';
import { computed } from 'vue';
import { useFormStore } from '~/store/form.store';
import { CategoryOptionProps } from './CategoryOption.vue';
const { category } = defineProps<Omit<CategoryOptionProps, 'index'>>();

const formStore = useFormStore();
const isMain = computed(
  () => formStore.category.data.primaryCategory === category
);
</script>

<template>
  <Button
    class="text-xs whitespace-nowrap"
    :class="{ '!select-none pointer-events-none': isMain }"
    :variant="isMain ? 'secondary' : 'tertiary'"
    size="xs"
    round
    @click="formStore.category.data.primaryCategory = category"
  >
    {{
      isMain
        ? $t('section.category.mainCategory')
        : $t('section.category.setMain')
    }}
  </Button>
</template>

<style lang="scss" scoped></style>
