<script lang="ts" setup>
import { computed, ref } from 'vue';

interface Props {
  text: string;
  position: 'top' | 'right' | 'bottom' | 'left';
  hideArrow?: boolean;
  hideOnMobile?: boolean;
  rounded?: boolean;
}
const props = withDefaults(defineProps<Props>(), {
  position: 'bottom',
});

const positionRef = ref(props.position);
const rect = ref<HTMLElement>();

// Offset the tooltip to avoid it overflowing the screen
const offset = computed(() => {
  if (!rect.value) return;

  const { width } = document.documentElement.getBoundingClientRect();
  const { left, right } = rect.value.getBoundingClientRect();

  // Margin from the edge
  const margin = 24;

  switch (props.position) {
    // If the tooltip is on the left or right, switch it to the opposite side
    case 'left':
      if (left < margin) positionRef.value = 'right';
      break;
    case 'right':
      if (right > width - margin) positionRef.value = 'left';
      break;
    // If the tooltip is on the top or bottom, offset position
    case 'top':
    case 'bottom':
      if (left < margin) return -left + margin;
      if (right > width - margin) return -(right - width + margin);
      break;
  }
});

const wrapperClass = {
  top: '-top-2 left-1/2',
  right: 'left-full top-1/2',
  bottom: '-bottom-2 left-1/2',
  left: 'right-full top-1/2',
};

const positionClass = {
  top: 'origin-bottom bottom-full -translate-x-1/2',
  right: 'origin-left translate-x-1 -translate-y-1/2',
  bottom: 'origin-top -translate-x-1/2',
  left: 'right-full origin-right -translate-x-1 -translate-y-1/2',
};

const arrowClass = {
  top: 'border-b-0 border-t-dark -top-2 left-1/2 -translate-x-1/2',
  right: 'border-l-0 border-r-dark -right-1 top-1/2 -translate-y-1/2',
  bottom: 'border-t-0 border-b-dark -bottom-2 left-1/2 -translate-x-1/2',
  left: 'border-r-0 border-l-dark -left-1 top-1/2 -translate-y-1/2',
};
</script>

<template>
  <div class="relative group">
    <slot />
    <div
      class="absolute"
      :class="wrapperClass[positionRef]"
      :style="{ transform: `translateX(${offset}px)` }"
    >
      <div
        ref="rect"
        class="opacity-0 transform z-10 scale-95 duration-250 pointer-events-none select-none absolute invisible inline-block whitespace-nowrap"
        :class="[
          { 'hidden sm:block': hideOnMobile },
          positionClass[positionRef],
        ]"
        w:group-hover="opacity-100 visible scale-100"
      >
        <span
          class="bg-dark text-snow-white text-xs inline-block"
          :class="{
            'rounded-3xl px-5 py-2': rounded,
            'rounded-md px-2 py-1.25': !rounded,
          }"
        >
          {{ text }}
        </span>
      </div>
    </div>

    <!-- Arrow -->
    <div
      v-if="!hideArrow"
      class="border-transparent border-5 scale-95 h-0 opacity-0 transform w-0 duration-250 invisible absolute"
      :class="[{ 'hidden sm:block': hideOnMobile }, arrowClass[positionRef]]"
      w:group-hover="opacity-100 visible scale-100"
    />
  </div>
</template>
