import { getEntityRelationShip, getLocalesIO, IdIO } from '../common';
import * as t from 'io-ts';
import { processEntity } from '../../serialization/entity';

export const DealAttributesIO = t.interface({
  startAt: t.string,
  endAt: t.string,
  priceCents: t.number,
  ticketCount: t.number,
});

export const DealRelationshipsIO = t.interface({
  sale: getEntityRelationShip('sale'),
});

export const DealIO = t.interface({
  id: IdIO,
  type: t.literal('deal'),
  url: t.string,
  locales: getLocalesIO(
    t.interface({
      name: t.string,
    })
  ),
  attributes: DealAttributesIO,
  relationships: DealRelationshipsIO,
});

export type DealRaw = t.TypeOf<typeof DealIO>;
const serialize = (entity: DealRaw) => processEntity(entity, {});
export type Deal = ReturnType<typeof serialize>;
