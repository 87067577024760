import z from 'zod';
import { DonutSchema } from '../donut.schema';

/**
 * Meta is a JSON object that contains additional information about the response.
 *
 * @see https://jsonapi.org/format/#document-meta
 */
export const JsonApiMetaSchema = z
  .object({
    nextScrollId: z
      .string()
      .nullish()
      .describe(
        'To fetch the next page take the value of meta.nextScrollId from the last response and pass it to the scrollId query parameter along the same parameters you used to fetch the first page'
      ),
    donut: DonutSchema.nullish().describe(
      "If you require to search what's around, use donuts=true, Donut D0 contains venues in the center itself. D1 contains venues in cities within 10 km from the center"
    ),
    featuredCities: z
      .array(z.number())
      .max(24)
      .describe(
        'lists ids of three cities that contain the most venues in that donut. Response can contain at most 24 venues'
      )
      .optional(),
  })
  .describe(
    'example: { "nextScrollId": "83879634-de71-492b-b640-b3060ea01c0c", "donut": "d6", "featuredCities": List [ 488505 ] }'
  );

export type Meta = z.infer<typeof JsonApiMetaSchema>;
