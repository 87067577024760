import { RouteRecordRaw, createRouter, createWebHistory } from 'vue-router';

import WizardPage from '~/components/pages/WizardPage.vue';
import Overview from '~/components/pages/Overview.vue';
import Introduction from './components/pages/Introduction.vue';
import { useTracking } from './composables/useTracking';
import { TabEnum } from './store';
import { useAuth } from 'goout-auth';

const routes: Array<RouteRecordRaw> = [
  {
    name: 'Overview',
    path: '/',
    component: Overview,
    children: [
      {
        name: 'EventEditModal',
        path: 'event/edit/:id',
        component: () =>
          import('./components/modals/EventEdit/EventEditModal.vue'),
        meta: { modal: true },
        children: [
          {
            name: 'ConfirmModalEdit',
            path: 'confirm',
            component: () =>
              import('./components/modals/ConfirmModal/ConfirmModal.vue'),
            meta: { modal: true },
          },
          {
            name: 'SuccessModalEdit',
            path: 'success',
            component: () =>
              import('./components/modals/SuccessModal/SuccessModal.vue'),
            meta: { modal: true },
          },
          {
            name: 'ErrorModalEdit',
            path: 'error',
            component: () =>
              import('./components/modals/ErrorModal/ErrorModal.vue'),
            meta: { modal: true },
          },
        ],
      },
      {
        name: 'EventDeleteModal',
        path: 'event/delete/:id',
        component: () =>
          import('./components/modals/EventDelete/EventDeleteModal.vue'),
        meta: { modal: true },
        children: [
          {
            name: 'ConfirmModalDelete',
            path: 'confirm',
            component: () =>
              import('./components/modals/ConfirmModal/ConfirmModal.vue'),
            meta: { modal: true },
          },
          {
            name: 'SuccessModalDelete',
            path: 'success',
            component: () =>
              import('./components/modals/SuccessModal/SuccessModal.vue'),
            meta: { modal: true },
          },
          {
            name: 'ErrorModalDelete',
            path: 'error',
            component: () =>
              import('./components/modals/ErrorModal/ErrorModal.vue'),
            meta: { modal: true },
          },
        ],
      },
      {
        name: 'EventSellModal',
        path: 'event/sell/:id',
        component: () =>
          import('./components/modals/EventSell/EventSellModal.vue'),
        meta: { modal: true },
        children: [
          {
            name: 'ConfirmModalSell',
            path: 'confirm',
            component: () =>
              import('./components/modals/ConfirmModal/ConfirmModal.vue'),
            meta: { modal: true },
          },
          {
            name: 'SuccessModalSell',
            path: 'success',
            component: () =>
              import('./components/modals/SuccessModal/SuccessModal.vue'),
            meta: { modal: true },
          },
          {
            name: 'ErrorModalSell',
            path: 'error',
            component: () =>
              import('./components/modals/ErrorModal/ErrorModal.vue'),
            meta: { modal: true },
          },
        ],
      },
    ],
  },
  {
    name: 'Intro',
    path: '/intro',
    component: Introduction,
  },
  {
    name: 'Wizard',
    path: '/wizard/:step',
    component: WizardPage,
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    redirect: '/',
  },
];

export const router = createRouter({
  routes,
  history: createWebHistory(),
});

const { trackPageView } = useTracking();
const { isLoggedIn } = useAuth();
router.beforeEach((to, from) => {
  if (!isLoggedIn.value) return;
  if (from.params.step === TabEnum.Publish && to.name === 'Overview') return;

  trackPageView({
    to,
  });
});
