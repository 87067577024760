<script setup lang="ts">
import Input from './Input.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import { useI18n } from 'vue-i18n';
import { useForgottenPassword } from '../api/useForgottenPassword';
import { computed, ref } from 'vue';
import { ModalTypeEnum } from '../utils/modalTypes';
import { useModalStore } from '../stores/modal.store';
import Spinner from './Spinner.vue';
import { useToast } from 'goout-ui-kit/composables/useToast';
import { z } from 'zod';

const { t } = useI18n();
const { switchForms } = useModalStore();
const { showToast } = useToast();
const recoveryEmail = ref('');
const { execute: recoverPassword, isFetching } =
  useForgottenPassword(recoveryEmail);

const handlePasswordRecovery = async () => {
  try {
    await recoverPassword(true);
    switchForms(ModalTypeEnum.forgottenPasswordSuccess);
  } catch (error) {
    showToast({
      heading: 'loginModule.error.forgottenPassword',
      message: 'loginModule.error.try.again.later',
      position: 'top-right',
    });
  }
};

const disabledButton = computed(() => {
  return !z.string().email().safeParse(recoveryEmail.value).success || isFetching.value;
});

const buttonVariant = computed(() =>
  disabledButton.value ? 'primary-disabled-blue' : 'primary'
);
</script>

<template>
  <div class="flex flex-col items-center w-full">
    <form class="relative w-full" @submit="handlePasswordRecovery">
      <Input
        v-model="recoveryEmail"
        class="w-full"
        :label="t('loginModule.input.email')"
        required
      />
      <div class="text-center my-6">
        <a
          class="cursor-pointer text-gray-dark hover:text-gray underline text-sm text-center"
          @click="switchForms(ModalTypeEnum.signIn)"
        >
          {{ $t('loginModule.backToLogin') }}
        </a>
      </div>
      <Button
        class="w-full"
        :variant="buttonVariant"
        :disabled="disabledButton"
        round
        @click="handlePasswordRecovery"
      >
        <Spinner v-if="isFetching" />
        <span v-else>
          {{ $t('loginModule.passwordRecover') }}
        </span>
      </Button>
    </form>
  </div>
</template>
