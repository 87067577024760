import * as z from 'zod';
import {
  RelationshipToOne,
  RelationshipToMany,
} from '../jsonApi/jsonApiRelationships.schema';
import { EntityTypeEnumSchema } from '../entityType.schema';

const DaysAcceptedSince = z.enum(['payment', 'first_check_in']);
const DealTicketCounts = z
  .object({
    count: z.number().int(),
    withSeat: z.number().int().nullish(),
    selectable: z.number().int().nullish(),
    packageSize: z.number().int().nullish(),
    minSelect: z.number().int(),
    maxSelect: z.number().int(),
  })
  .passthrough();

export const DealDtoAttributesSchema = z.object({
  startAt: z.string(),
  endAt: z.string(),
  priceCents: z.number().int(),
  isHidden: z.boolean(),
  isHiddenDesk: z.boolean(),
  hasNamedTickets: z.boolean(),
  daysAccepted: z.number().int().nullish(),
  daysAcceptedSince: DaysAcceptedSince.nullish(),
  ignoreDiscounts: z.boolean(),
  isSeatedDeal: z.boolean(),
  onSoldOutDealsSize: z.number().int(),
  ticketCounts: DealTicketCounts.nullish(),
  preOwnedTicketsMustBeActive: z.boolean().nullish(),
  preOwnedTicketsRequired: z.boolean().nullish(),
  partnerId: z.string().nullish(),
  color: z.number().int().nullish(),
  ticketSendDate: z.string().datetime({ offset: true }).nullish(),
});

export const DealDtoRelationshipsSchema = z.object({
  sale: RelationshipToOne('sales'),
  onSoldOutDeal: RelationshipToOne('deals'),
  requiredPreOwnedDeals: RelationshipToOne('deals'),
  discountsAllowed: RelationshipToMany('discounts'),
  symbol: z.any(),
});

export const DealDtoSchema = z.object({
  attributes: DealDtoAttributesSchema,
  id: z.string(),
  type: z.literal(EntityTypeEnumSchema.Values.deals),
  relationships: DealDtoRelationshipsSchema,
});
