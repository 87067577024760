import z from 'zod';
import { VenueDtoSchema } from '../venue/venue.schema';
import { ScheduleDtoSchema } from '../schedule/schedule.schema';
import { UserDtoSchema } from '../user/user.schema';
import { EventDtoSchema } from '../event/event.schema';
import { DealDtoSchema } from '../deal/deal.schema';
import { DiscountDtoSchema } from '../discount/discount.schema';
import { SaleDtoSchema } from '../sale/sale.schema';
import { ContactDtoSchema } from '../contact/contact.schema';
import { PerformerDtoSchema } from '../performer/performer.schema';

/**
 * JSON API data zod object
 */
export const JsonApiDataSchema = z.discriminatedUnion('type', [
  VenueDtoSchema,
  ScheduleDtoSchema,
  SaleDtoSchema,
  ContactDtoSchema,
  UserDtoSchema,
  EventDtoSchema,
  DealDtoSchema,
  DiscountDtoSchema,
  PerformerDtoSchema,
]);
