import { z } from 'zod';

export const filmSchema = z.object({
  selectedItem: z
    .object({
      id: z.string(),
      title: z.string(),
      url: z.string(),
    })
    .partial(),
  values: z
    .object({
      originalName: z.string().nullable(),
      countryIsos: z.array(z.string()),
      minorPerformers: z.array(z.string()),
      director: z.string(),
      released: z.string(),
      length: z.string(),
      tags: z.array(z.string()),
    })
    .partial(),
});
