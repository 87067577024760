import { ref } from 'vue';
import { User } from 'goout-schemas';
import { logout } from '../api/logout';

const user = ref<User | null>(null);

/**
 * User is logged in only if user is not null and required permissions are met ADMIN, ORGANISER, etc.
 */
const isLoggedIn = ref(false);

/**
 * If true the user is not yet logged in and we are fetching the user data
 * This is useful for showing loading spinners
 * */
const isFetching = ref(false);
const isAdminOnly = ref(false);
const isFetchingGoogle = ref(false);
const isFetchingFacebook = ref(false);
export const useAuth = () => {
  
  return {
    user,
    isLoggedIn,
    isFetching,
    isFetchingGoogle,
    isFetchingFacebook,
    isAdminOnly,
    logout,
  };
};
