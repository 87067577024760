import { createI18n } from 'vue-i18n';
import type { I18n } from 'vue-i18n';
import { useCookie } from 'goout-utils/cookie';
import { showKeyForMissingTranslations } from 'goout-utils/i18n';

import cs from '../locales/cs.json';
import en from '../locales/en.json';
import { nextTick } from 'vue';
import { LanguageEnum } from 'goout-schemas';

export const SUPPORTED_LOCALES = ['cs', 'en'];
export const FALLBACK_LOCALE = 'en';

function getValidLocale(locale: string): string {
  return SUPPORTED_LOCALES.includes(locale) ? locale : FALLBACK_LOCALE;
}

const locale: string = getValidLocale(
  useCookie().get('language') || navigator.language || FALLBACK_LOCALE
);

export const i18n: I18n = createI18n({
  locale,
  fallbackLocale: FALLBACK_LOCALE,
  legacy: false,
  globalInjection: true,
  messages: {
    en,
    cs,
  },
});

export async function setupI18n() {
  showKeyForMissingTranslations(i18n as any);
  setI18nLanguage(i18n.global.locale.toString());
  await loadLocaleMessages(i18n, locale);
}

export function setI18nLanguage(locale: string) {
  const validLocale = getValidLocale(locale);
  i18n.global.locale = validLocale;
  useCookie().set('language', validLocale);
  document.querySelector('html')!.setAttribute('lang', validLocale);
}

export function getCurrentLocale() {
  return i18n.global.locale.toString() as LanguageEnum;
}

export async function loadLocaleMessages(i18n: I18n, locale: string) {
  // Load locale messages with dynamic import
  const messages = await import(`../locales/${locale}.json`);

  // Set locale and locale message
  i18n.global.setLocaleMessage(locale, messages.default);

  // Return nextTick to ensure the locale is fully loaded
  return nextTick();
}
