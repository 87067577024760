import {
  IdIO,
  getLocalesIO,
  EntityStateIO,
  getEntityRelationShip,
  optional,
} from '../common';
import * as t from 'io-ts';
import { processEntity } from '../../serialization/entity';

export const PerformerAttributesIO = t.interface({
  state: EntityStateIO,
  mainCategory: t.string,
  categories: t.array(t.string),
  tags: t.array(t.string),
  countriesIso: t.array(t.string),
  keywords: t.array(t.string),
  updatedAt: t.string,
  siteUrl: optional(t.string),
  sourceUrl: optional(t.string),
  counts: t.interface({
    events: optional(t.number),
    followers: optional(t.number),
  }),
  metaTitle: optional(t.string),
  metaDescription: optional(t.string),
});

export const PerformerRelationshipsIO = t.interface({
  images: t.array(getEntityRelationShip('image')),
  videos: t.array(getEntityRelationShip('video')),
});

export const PerformerIO = t.interface({
  id: IdIO,
  type: t.literal('performer'),
  url: t.string,
  locales: getLocalesIO(
    t.interface({
      name: t.string,
      description: t.string,
      descriptionHtml: t.string,
      metaDescription: optional(t.string),
      metaTitle: optional(t.string),
      siteUrl: optional(t.string),
    })
  ),
  relationships: PerformerRelationshipsIO,
  attributes: PerformerAttributesIO,
});

export type PerformerRaw = t.TypeOf<typeof PerformerIO>;
const serialize = (entity: PerformerRaw) => processEntity(entity, {});
export type Performer = ReturnType<typeof serialize>;
