import { Event, Performer, Schedule, Venue } from 'goout-schemas';
import { defineStore } from 'pinia';
import { ref, computed } from 'vue';

export const useDataStore = defineStore('data', () => {
  const schedules = ref<Schedule[]>([]);
  const venues = ref<Venue[]>([]);
  const performers = ref<Performer[]>([]);

  function setSchedules(schedulesData: Schedule[]) {
    schedules.value = schedulesData;
  }

  function getEventById(id: string): Event | undefined {
    return schedules.value.find(
      (schedule: Schedule) => schedule.event.id === id
    )?.event;
  }

  function getScheduleById(id: string): Schedule | undefined {
    return schedules.value.find(
      (schedule: Schedule) => schedule.event.id === id
    );
  }

  const sortedSchedules = computed(() =>
    schedules.value.sort((a, b) => {
      const today = new Date().getTime();
      const startAtDiffA = Math.abs(new Date(a.startAt).getTime() - today);
      const startAtDiffB = Math.abs(new Date(b.startAt).getTime() - today);

      if (startAtDiffA !== startAtDiffB) {
        return startAtDiffA - startAtDiffB;
      }

      // For descending order by id, swap 'a' and 'b' in localeCompare
      return b.event.id.localeCompare(a.event.id);
    })
  );

  return {
    schedules: sortedSchedules,
    venues,
    performers,
    setSchedules,
    getEventById,
    getScheduleById,
  };
});
