<script lang="ts" setup>
import { ref } from 'vue';
import  Dropdown, { Props } from '../dropdown/Dropdown.vue';

defineProps<Props>();
const contextMenu = ref(null);
</script>

<template>
    <div ref="contextMenu">
        <Dropdown v-slot="slotProps" :items="items">
            <slot :open="slotProps.open" />
        </Dropdown>
    </div>
</template>