<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M2 6H22"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M12 2V22"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path d="M7 2V6" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" />
    <path
      d="M17 2V6"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M2 18H22"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M7 22V18"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M17 22V18"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M22 2H2V22H22V2Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>
