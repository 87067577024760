<script setup lang="ts">
import { z } from 'zod';
import { useForm } from '~/composables/useForm';
import Error from '~/components/general/Error.vue';
import InputLocaleSwitcher from '~/components/general/InputLocaleSwitcher.vue';
import Editor from 'goout-ui-kit/editor/Editor.vue';
import { LanguageEnum } from 'goout-schemas';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { LocalizedInput } from '~/types/form.types';

export interface TextInputProps {
  label: string;
  schema: z.ZodSchema<unknown>;
  required?: boolean;
}
const locale = ref<LanguageEnum>('cs');

const { schema } = defineProps<TextInputProps>();

const model = defineModel<LocalizedInput>({
  default: {
    en: '',
    cs: '',
    de: '',
    pl: '',
    sk: '',
    uk: '',
  },
});

const { errors } = useForm({ model, schema });

const handleLocaleUpdate = (value: LanguageEnum) => {
  locale.value = value;
};
const { t } = useI18n();

const tooltips = computed(() => ({
  Bold: t('wizard.editor.bold'),
  Italic: t('wizard.editor.italic'),
  Underline: t('wizard.editor.underline'),
  H1: t('wizard.editor.h1'),
  H2: t('wizard.editor.h2'),
  H3: t('wizard.editor.h3'),
  ListUnordered: t('wizard.editor.listUnordered'),
  ListOrdered: t('wizard.editor.listOrdered'),
}));
</script>

<template>
  <div class="relative">
    <Editor v-model="model[locale]" :label="$t(label)" :tooltips :locale />
    <Error v-if="errors" :errors />
    <div class="absolute top-15 right-4 origin-center">
      <InputLocaleSwitcher :locale @update:model-value="handleLocaleUpdate" />
    </div>
  </div>
</template>
