import { IdIO, optional, getEntityRelationShip } from '../common';
import * as t from 'io-ts';
import { processEntity } from '../../serialization/entity';

export const PurchaseStateIO = t.union([t.literal('paid'), t.literal('what?')]);

export const PurchaseAttributesIO = t.interface({
  priceCents: t.number,
  ticketCount: t.number,
  firstName: optional(t.string),
  lastName: optional(t.string),
  email: optional(t.string),
  phone: optional(t.string),
  // TODO: union type for countries?
  countryIso: optional(t.string),
  paymentSource: optional(t.string),
  hasMarketingAgreed: optional(t.boolean),
  paidAt: optional(t.string),
  // TODO: create union type for purchase state
  state: optional(t.string),
  reservationExpiresAt: optional(t.string),
  // TODO: What is this for?
  // additionalFields: []
});

export const PurchaseIO = t.interface({
  id: IdIO,
  type: t.literal('purchase'),
  attributes: PurchaseAttributesIO,
  relationships: t.interface({
    sale: getEntityRelationShip('sale'),
  }),
});

export type PurchaseRaw = t.TypeOf<typeof PurchaseIO>;
const serialize = (entity: PurchaseRaw) => processEntity(entity, {});
export type Purchase = ReturnType<typeof serialize>;
