import { createFetch } from '@vueuse/core';

export const useGooutFetch = createFetch({
  options: {
    async beforeFetch({ options }) {
      options.headers = {
        'CF-Access-Client-Id': 'ea8553adfbcad6bec65593d2f83832b2.access',
        'CF-Access-Client-Secret':
          '134042fd0d2e1fbba59e369d83e9032590d512359e48164d02bfb2f4e5299c66',
      };
      return { options };
    },
  },
});
