import z from 'zod';
import { EntityTypeEnumSchema } from '../entityType.schema';
import { Prettify } from '../jsonApi/utils/helpers';

export const ImageDtoAttributesSchema = z.object({
  copyright: z.string().nullish().describe('© 2014 Jane Doe.'),
  name: z.string().nullish().describe('Jane Doe'),
  origin: z.string().nullish().describe('https://bandwebsite.com'),
  url: z.string().nullish().describe('https://goout.net/i/089/897920-383.jpg'),
});

export const ImageDtoSchema = z.object({
  attributes: ImageDtoAttributesSchema,
  id: z.coerce.string(),
  type: z.literal(EntityTypeEnumSchema.Values.images),
  relationships: z.null().or(z.object({})),
});

export const ImageSchema = ImageDtoAttributesSchema.merge(
  z.object({
    id: z.string(),
    type: z.literal(EntityTypeEnumSchema.Values.images),
  })
);

export type ImageDto = z.infer<typeof ImageDtoSchema>;
export type ImageDtoAttributes = z.infer<typeof ImageDtoAttributesSchema>;

export type Image = Prettify<
  {
    type: typeof EntityTypeEnumSchema.Values.images;
    id: string;
  } & ImageDtoAttributes
>;
