<script setup lang="ts" generic="T extends ComboboxSingleItem">
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue';
import Spinner from './Spinner.vue';
import { debounce } from '~/utils/debounce.util';
import { computed, ref, watch } from 'vue';
import { onClickOutside } from '@vueuse/core';

export type ComboboxSingleItem = {
  id: string;
  title: string;
  url?: string;
  description?: string;
};

interface Props {
  items: T[];
  isFetching?: boolean;
  placeholder: string;
  isSelect?: boolean;
}

const { items = [], isSelect = false } = defineProps<Props>();

const DEBOUNCE_TIME = 300;
const query = defineModel<string>('query');
const selected = defineModel<T[]>('selected');
const isOpen = ref(false);
const comboboxRef = ref(null);
onClickOutside(comboboxRef, () => (isOpen.value = false));

const handleInputChange = debounce((event: Event) => {
  query.value = (event.target as HTMLInputElement).value;
}, DEBOUNCE_TIME);

function displayValue() {
  return query.value || '';
}

const filteredItems = computed(() => {
  return items.filter(
    (item) =>
      !selected.value?.find((selectedItem) => selectedItem.id === item.id) &&
      query.value &&
      item.title.toLowerCase().includes(query.value.toLowerCase())
  );
});

const notFound = computed(() => {
  return filteredItems.value.length === 0;
});

watch(selected, () => {
  isOpen.value = false;
  query.value = '';
});
</script>

<template>
  <div>
    <Combobox ref="comboboxRef" v-model="selected" autocomplete="off" multiple>
      <div class="relative">
        <div class="relative w-full h-17 cursor-default flex">
          <ComboboxInput
            class="h-full bg-snow-white outline-none text-gray font-medium w-full px-4 py-4 transition-all focus:shadow-border focus:shadow-blue focus:bg-gray-50"
            :placeholder
            autocomplete="off"
            :display-value="displayValue"
            @change="handleInputChange($event)"
            @input="isOpen = true"
            @focus="query && (isOpen = true)"
            @click="isSelect ? (isOpen = true) : null"
          />
        </div>
        <TransitionRoot :show="isOpen" @after-leave="query = ''">
          <div
            class="bg-white shadow-default mt-3 min-w-72 w-full pl-6 z-10 absolute"
          >
            <ComboboxOptions
              class="bg-white w-full min-w-72 min-h-14 max-h-72 overflow-auto flex flex-col"
            >
              <div
                v-if="notFound && !isFetching && query"
                class="relative cursor-default select-none px-4 py-2 text-gray-700"
              >
                {{ $t('category.tags.noMatches') }}
              </div>
              <Spinner
                v-else-if="isFetching"
                class="flex self-center align-middle mt-2 text-blue"
              />
              <div v-else class="flex flex-col">
                <div v-for="(item, idx) in items" :key="item.id">
                  <ComboboxOption
                    v-if="
                      !selected?.find(
                        (selectedItem) => selectedItem.id === item.id
                      )
                    "
                    v-slot="{ active, disabled }"
                    :value="item"
                    @click="
                      isOpen = false;
                      query = '';
                    "
                  >
                    <div
                      class="flex py-3 gap-x-3 relative select-none cursor-pointer items-center transition-colors"
                      :class="{
                        'text-blue': active,
                        'text-gray-900': !active,
                        'opacity-40 cursor-default': disabled,
                      }"
                    >
                      <img
                        v-if="item.url"
                        :src="item.url"
                        class="w-12 h-12 rounded-full object-cover bg-cover mr-2"
                        :alt="item.title"
                        :lazy="idx > 5"
                      />
                      <div
                        v-else
                        class="w-12 h-12 rounded-full object-cover bg-cover mr-2 bg-gray-light"
                      ></div>

                      <div class="flex flex-col justify-start">
                        <h4 class="text-sm font-medium">
                          {{ item.title }}
                        </h4>
                        <p
                          v-if="item.description"
                          class="text-gray font-normal"
                        >
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </ComboboxOption>
                </div>
              </div>
            </ComboboxOptions>
          </div>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>
