import z from 'zod';

export const EntityTypeEnumSchema = z.enum([
  'cities',
  'contacts',
  'contact',
  'contact_users',
  'deals',
  'discounts',
  'events',
  'event',
  'guestlists',
  'guestlist_deals',
  'guestlist_users',
  'guestlist_purchases',
  'halls',
  'hall_blocks',
  'hall_symbols',
  'check_ins',
  'images',
  'image',
  'performers',
  'purchases',
  'purchase_transactions',
  'sales',
  'seats',
  'seat_tickets',
  'schedules',
  'tickets',
  'users',
  'venue',
  'venues',
  'videos',
  'hall_logs',
  'parents',
  'related',
]);
export type EntityType = z.infer<typeof EntityTypeEnumSchema>;
