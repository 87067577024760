<script setup lang="ts">
import { ref, computed } from 'vue';
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue';

import {
  EventCategoriesWithTags,
  Tag as TagType,
  EventCategory,
} from 'goout-data';
import Tag from './Tag.vue';
import { useFormStore } from '~/store/form.store';
import { onClickOutside } from '@vueuse/core';

const formStore = useFormStore();

const query = ref('');
const isOpen = ref(false);
const comboboxRef = ref(null);
onClickOutside(comboboxRef, () => (isOpen.value = false));

const formattedEventCategories = computed(() => {
  return Object.entries(EventCategoriesWithTags)
    .filter(([cat]) =>
      formStore.category.data.selectedCategories.includes(cat as EventCategory)
    )
    .map(([category, details]) => {
      return { category, tags: details?.tags || [] };
    });
});

const filterTagsByQuery = (tags: readonly TagType[]) => {
  return tags.filter(
    (tag) =>
      tag.toLowerCase().includes(query.value.toLowerCase()) &&
      !formStore.category.data.tags.includes(tag)
  );
};

const categoryContainsTags = (category: string) => {
  const tagsByCategory = formattedEventCategories.value.find(
    (cat) => cat.category === category
  )?.tags;
  if (!tagsByCategory) return false;

  const allAvaliableTags =
    filterTagsByQuery(
      tagsByCategory?.filter(
        (tag) => !formStore.category.data.tags.includes(tag)
      )
    ).length > 0;
  return allAvaliableTags && tagNotExists.value;
};

const tagNotExists = computed(
  () =>
    filterTagsByQuery(
      formattedEventCategories.value
        .map((c) => c.tags)
        .filter((tag) =>
          formStore.category.data.tags.includes(tag as unknown as TagType)
        )
        .flat() as TagType[]
    ).length === 0
);
</script>

<template>
  <div ref="comboboxRef">
    <Combobox
      v-slot="{ open }"
      v-model="formStore.category.data.tags"
      multiple
      nullable
    >
      <div class="relative">
        <div class="relative w-full h-17 cursor-default flex">
          <ComboboxInput
            class="h-full bg-snow-white outline-none text-gray-dark font-medium w-full px-4 py-4 transition-all focus:shadow-border focus:shadow-blue focus:bg-gray-50"
            autocomplete="off"
            :placeholder="$t('category.tags.add.tags')"
            @change="query = $event.target.value"
            @focus="isOpen = true"
          />
        </div>
        <TransitionRoot :show="isOpen" @after-leave="query = ''">
          <div
            class="bg-white shadow-default mt-3 min-w-72 w-full pt-3 pl-6 z-10 absolute"
          >
            <ComboboxOptions
              class="bg-white w-full min-w-72 min-h-72 max-h-72 overflow-auto z-10"
            >
              <div
                v-for="({ category, tags }, idx) in formattedEventCategories"
                :key="idx"
                class="mb-8 sticky"
              >
                <span
                  v-if="categoryContainsTags(category)"
                  :key="category"
                  class="bg-white font-medium text-xs text-gray mb-2 top-0 block sticky z-10 pb-6"
                >
                  {{ $t(`general.tags.${category}`) }}
                </span>
                <div
                  v-else-if="idx === 0"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  {{ $t('category.tags.noMatches') }}
                </div>
                <div class="flex flex-row flex-wrap gap-3">
                  <ComboboxOption
                    v-for="tag in filterTagsByQuery(tags)"
                    :key="tag"
                    v-slot="{ active }"
                    :value="tag"
                  >
                    <Tag
                      :name="tag"
                      class="cursor-pointer hover:bg-gray/10"
                      :class="{ 'bg-gray/10': active }"
                    />
                  </ComboboxOption>
                </div>
              </div>
            </ComboboxOptions>
          </div>
        </TransitionRoot>
      </div>
      <slot :open name="tags" />
    </Combobox>
  </div>
</template>
