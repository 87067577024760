import { useGooutFetch } from 'goout-api';
import { USER_TRACKING } from 'goout-auth/src/api/endpoints';
import { useCookies } from '@vueuse/integrations/useCookies';

import { reactive, ObjectDirective } from 'vue';
import { getCurrentLocale } from '~/i18n';
import type {
  Platform,
  Browser,
  UserTracked,
  UserTrackedActionDto,
  ClickTrackingArgs,
  UserTrackedItemDto,
  UserTrackedEventDto,
  PageViewTrackingArgs,
  TrackingDirectiveArgs,
} from '~/composables/useTracking.d';

const EVENT_TYPE_PAGE_VIEW = 'pageView';
const EVENT_TYPE_CLICK = 'click';

/**
 * Composable for tracking user events
 * Uses endpoint /services/user/v1/tracking
 * If the structure of the tracking data changes, the trackingVersion should be updated and discussed with the team beforehand
 * @returns tracking functions
 */
export function useTracking() {
  function trackPageView({ to }: PageViewTrackingArgs) {
    const view = to.path.slice(1).replace('/', '_').toLowerCase();
    const trackingData = createTrackingData(EVENT_TYPE_PAGE_VIEW, { view });
    setTimeout(() => {
      useGooutFetch(USER_TRACKING).post(trackingData);
    });
  }

  /**
   * Tracks a click event
   * @param label - Label of the event
   * @param category - Category of the event
   * @param type - Type of the event
   * @param items - Items of the event
   * @returns void
   * @example trackClick({ label: 'button_label', category: 'button', type: 'button_click' })
   */
  function trackClick({
    label,
    category,
    type,
    items = [],
  }: ClickTrackingArgs) {
    const trackingData = createTrackingData(
      EVENT_TYPE_CLICK,
      {
        label,
        category,
        type,
      },
      items
    );

    setTimeout(() => {
      useGooutFetch(USER_TRACKING).post(trackingData);
    });
  }

  return {
    trackPageView,
    trackClick,
  };
}

/**
 * Creates tracking data
 * @param eventType - Type of the event
 * @param action - Action of the event
 * @param items - Items of the event
 * @returns tracking data
 */
function createTrackingData(
  eventType: UserTrackedEventDto['type'],
  action: Partial<UserTrackedActionDto>,
  items: UserTrackedItemDto[] = []
): UserTracked {
  return reactive<UserTracked>({
    trackingVersion: 1,
    event: {
      type: eventType,
      time: new Date().toISOString(),
      url: window.location.href,
      session: useCookies().get('GF'),
    },
    identity: {
      userId: useCookies().get('UID'),
      language: getCurrentLocale(),
    },
    device: {
      platform: detectPlatform(),
      browser: detectBrowser(),
    },
    action: {
      label: action.label || '',
      category: action.category || null,
      view: action.view || convertRouteToViewProperty(window.location.pathname),
      type: action.type || eventType,
    },
    items,
  });
}

/**
 * Directive for tracking clicks on elements
 * Recommender approach, since there is no need to import the composable
 * @example <button v-tracking="{ category: 'button', view: 'main_page', label: 'button_label' }">Click me</button>
 * @param category - Category of the element
 * @param view - View of the element
 * @param label - Label of the element
 * @returns void
 */
export const vTrackingDirective: ObjectDirective<
  HTMLElement,
  TrackingDirectiveArgs
> = {
  mounted(el: HTMLElement, binding) {
    const { category, view, label } = binding.value;
    const trackingData = createTrackingData(EVENT_TYPE_CLICK, {
      label: label || el.innerText.trim(),
      category,
      view:
        typeof view === 'string'
          ? view
          : convertRouteToViewProperty(window.location.pathname),
      type: `${convertTagNametoFriendlyName(el.tagName.toLowerCase())}_click`,
    });

    const handleClick = () => {
      setTimeout(() => {
        useGooutFetch(USER_TRACKING).post(trackingData);
      });
    };

    el.addEventListener('click', handleClick);
    (el as any)._handleClick = handleClick;
  },
  unmounted(el: HTMLElement) {
    el.removeEventListener('click', (el as any)._handleClick);
  },
};

/**
 * Returns the platform of the user e.g. Windows, OSX, Linux, Android, iOS, Unknown
 * @returns platform
 */
function detectPlatform(): Platform {
  if (typeof navigator === 'undefined') return 'Unknown';
  const userAgent = navigator.userAgent;

  const platforms: {
    platform: Platform;
    regex: RegExp;
  }[] = [
    { platform: 'Windows', regex: /Win/ },
    { platform: 'OSX', regex: /Mac/ },
    { platform: 'Linux', regex: /Linux/ },
    { platform: 'Android', regex: /Android/ },
    { platform: 'iOS', regex: /iPhone|iPad|iPod/ },
  ];

  for (const { platform, regex } of platforms) {
    if (regex.test(userAgent)) {
      return platform;
    }
  }

  return 'Unknown';
}

/**
 * Returns the browser of the user e.g. Chrome, Firefox, Safari, Edge, IE, Unknown
 */
function detectBrowser(): Browser {
  if (typeof navigator === 'undefined') return 'Unknown';
  const userAgent = navigator.userAgent;

  // Detecting browser
  if (userAgent.includes('Chrome')) {
    return 'Chrome';
  } else if (userAgent.includes('Firefox')) {
    return 'Firefox';
  } else if (userAgent.includes('Safari')) {
    return 'Safari';
  } else if (userAgent.includes('Edge')) {
    return 'Edge';
  } else if (userAgent.includes('MSIE') || userAgent.includes('Trident/')) {
    return 'IE';
  } else {
    return 'Unknown';
  }
}

/**
 * Converts the route to a view property
 * @param route - Route to convert
 * @returns view property
 */
function convertRouteToViewProperty(route: string): string {
  if (route === '/') return 'main_page';
  return route.slice(1).replace('/', '_').toLowerCase();
}

/**
 * Converts the tag name to a friendly name
 * @param tag - Tag to convert
 * @returns friendly name of the tag e.g. link instead of a
 */
function convertTagNametoFriendlyName(tag: string): string {
  switch (tag.toLowerCase()) {
    case 'a':
      return 'link';
    default:
      return tag;
  }
}
