import { IdIO, getLocalesIO } from '../common';
import * as t from 'io-ts';
import { processEntity } from '../../serialization/entity';

export const CityAttributesIO = t.interface({
  isFeatured: t.boolean,
  countryIso: t.string,
  isFictiveCountry: t.boolean,
});

export const CityIO = t.interface({
  id: IdIO,
  type: t.literal('city'),
  locales: getLocalesIO(
    t.interface({
      regionName: t.string,
      name: t.string,
    })
  ),
  attributes: CityAttributesIO,
  relationships: t.interface({}),
});

export type CityRaw = t.TypeOf<typeof CityIO>;
const serialize = (entity: CityRaw) => processEntity(entity, {});
export type City = ReturnType<typeof serialize>;
