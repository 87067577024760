
/**
 * We use XHR since fetch doesn't support progress events for file uploads
 * @param url
 * @param param1
 * @returns
 */
export function sendFileViaXHR(
  url: string,
  {
    data,
    signal,
    onProgress,
    method = 'POST', // Default to POST if method is not specified
  }: {
    data: FormData;
    signal: AbortSignal;
    onProgress: (progress: number) => void;
    method?: 'POST' | 'PUT';
  }
): Promise<void> {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open(method, url, true);
    xhr.setRequestHeader('CF-Access-Client-Id', 'ea8553adfbcad6bec65593d2f83832b2.access');
    xhr.setRequestHeader('CF-Access-Client-Secret', '134042fd0d2e1fbba59e369d83e9032590d512359e48164d02bfb2f4e5299c66');
    const progressObj = xhr.upload || xhr;
    progressObj.onprogress = function (e) {
      if (e.lengthComputable) {
        const progressPct = Math.round((e.loaded / e.total) * 100);
        onProgress(progressPct);
      }
    };

    xhr.onload = () => {
      try {
        (xhr.status >= 400 ? reject : resolve)(JSON.parse(xhr.response));
      } catch (error) {
        reject('Could not parse JSON');
      }
    };

    xhr.onerror = () => reject(new Error('Network error'));

    signal.onabort = () => {
      xhr.abort();
      reject(new DOMException('The user aborted a request.', 'AbortError'));
    };

    xhr.send(data);
  });
}

