<script setup lang="ts">
import { useJsonApiClient } from 'goout-api';
import { ComboboxSingleItem } from '~/components/general/ComboboxSingle.vue';
import ComboboxMultiple from '~/components/general/ComboboxMultiple.vue';
import TabContainer from '~/components/layout/TabContainer.vue';
import SelectedItem from '~/components/general/SelectedItem.vue';

import { useFormStore } from '~/store/form.store';
import { ref, computed, ComputedRef } from 'vue';
import { getCurrentLocale } from '~/i18n';
import { useSortable } from '@vueuse/integrations/useSortable';

const { performer } = useFormStore();
const locale = getCurrentLocale();
const query = ref('');

const { data, isFetching, execute } = useJsonApiClient({
  entity: 'performers',
  include: ['images'],
  query: query.value,
  immediate: false,
  language: locale,
  baseUrl: 'entities/v2',
});

const items: ComputedRef<ComboboxSingleItem[]> = computed(() =>
  data.value.map((performer) => ({
    id: performer.id,
    title: performer.locales?.[locale]?.name ?? '',
    url: performer.images[0]?.url || '',
    description: String(performer.countriesIso)
      .toUpperCase()
      .replace(/,/g, ', '),
  }))
);

const listRef = ref<HTMLElement | null>(null);

useSortable(listRef, performer.data.selectedItems, {
  animation: 150,
  handle: '.handle',
  onStart: (evt) => {
    const elementBeingDragged = evt.item; // evt.item refers to the current DOM element being dragged
    elementBeingDragged.classList.add('sorting');
  },
  onEnd: (evt) => {
    const elementBeingDragged = evt.item;
    elementBeingDragged.classList.remove('sorting');
  },
});
</script>

<template>
  <TabContainer
    :title="$t('wizard.tabs.performer.title')"
    :perex="$t('wizard.tabs.performer.perex')"
  >
    <ComboboxMultiple
      v-model:selected="performer.data.selectedItems"
      v-model:query="query"
      :items
      :placeholder="$t('general.search.performer.optional')"
      :is-fetching="!!isFetching"
      @update:query="execute"
    />

    <div ref="listRef" class="pt-3">
      <SelectedItem
        v-for="(per, idx) in performer.data.selectedItems.map((item) => ({
          id: item.id,
          title: item.title,
          url: item.url,
          description: item.description,
        }))"
        :key="per.id"
        :is-sortable="performer.data.selectedItems.length > 1"
        :item="per"
        @remove="performer.data.selectedItems.splice(idx, 1)"
      />
    </div>
  </TabContainer>
</template>

<style lang="scss" scoped>
.sorting {
  opacity: 0;
}
</style>
