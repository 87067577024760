import * as t from 'io-ts';
import { ScheduleIO } from '../entities/Schedule';
import { IdIO, optional } from '../common';
import { CityIO } from '../entities/City';
import { EventIO } from '../entities/Event';
import { ImageIO } from '../entities/Image';
import { PerformerIO } from '../entities/Performer';
import { SaleIO } from '../entities/Sale';
import { VenueIO } from '../entities/Venue';
import { VideoIO } from '../entities/Video';

export const ScheduleCollectionIO = t.interface({
  included: t.interface({
    cities: t.array(CityIO),
    events: t.array(EventIO),
    images: t.array(ImageIO),
    performers: t.array(PerformerIO),
    sales: t.array(SaleIO),
    venues: t.array(VenueIO),
    videos: t.array(VideoIO),
  }),
  meta: t.interface({
    donut: optional(t.string),
    featuredCities: optional(t.array(IdIO)),
    nextScrollId: optional(t.string),
  }),
  schedules: t.array(ScheduleIO),
});

export type ScheduleCollectionResponse = t.TypeOf<typeof ScheduleCollectionIO>;
