import { LanguageEnum } from 'goout-schemas';
import { LocalizedInput } from '~/types/form.types';

/**
 * Find the first non-empty value in the localizedInput object
 * @param localizedInput example: { en: 'Hello', de: 'Hallo' }
 * @param locale
 * @returns
 */
export function findLocaleWithValue(
  localizedInput: LocalizedInput,
  locale?: LanguageEnum
) {
  if (locale && localizedInput[locale] !== '') return localizedInput[locale];
  // If the current locale is empty, return the first non-empty locale
  return Object.values(localizedInput).find((value) => value != '') || '-';
}
