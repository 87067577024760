import { computed, Ref } from 'vue';
import { FORGOTTEN_PASSWORD } from './endpoints';
import { useGooutFetch } from 'goout-api';
import qs from 'qs';

export function useForgottenPassword(email: Ref<string>) {
  const url = computed(() => {
    const queryString = qs.stringify({ email: email.value });
    return `${FORGOTTEN_PASSWORD}?${queryString}`;
  });
  return useGooutFetch(url, { immediate: false }).post();
}
