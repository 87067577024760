import {
  CurrencyTypeIO,
  getEntityRelationShip,
  IdIO,
  optional,
} from '../common';
import * as t from 'io-ts';
import { processEntity } from '../../serialization/entity';

export const SaleStateIO = t.union([
  t.literal('active'),
  t.literal('sold_out'),
  t.literal('hidden'),
  t.literal('scheduled'),
  t.literal('ended'),
  t.literal('cancelled'),
]);

export const SaleAttributesIO = t.interface({
  saleUrl: optional(t.string),
  state: SaleStateIO,
  isDonation: t.boolean,
  isSeason: t.boolean,
  isSeasonWithSeparateTickets: t.boolean,
  startAt: optional(t.string),
  currency: optional(CurrencyTypeIO),
  saleType: optional(t.string),
});

export const SaleIO = t.interface({
  id: IdIO,
  type: t.literal('sale'),
  url: t.string,
  attributes: SaleAttributesIO,
  relationships: t.interface({
    schedule: getEntityRelationShip('schedule'),
    contacts: t.array(getEntityRelationShip('contact')),
    deals: t.array(getEntityRelationShip('deal')),
  }),
});

export type SaleRaw = t.TypeOf<typeof SaleIO>;
const serialize = (entity: SaleRaw) => processEntity(entity, {});
export type Sale = ReturnType<typeof serialize>;
