import * as t from 'io-ts';
import { IdIO, optional } from '../common';
import { processEntity } from '../../serialization/entity';

export const ImageIO = t.interface({
  id: IdIO,
  type: t.literal('image'),
  attributes: t.interface({
    url: t.string,
    copyright: optional(t.string),
    name: optional(t.string),
    origin: optional(t.string),
  }),
  relationships: t.interface({}),
});

export type ImageRaw = t.TypeOf<typeof ImageIO>;
const serialize = (entity: ImageRaw) => processEntity(entity, {});
export type Image = ReturnType<typeof serialize>;
