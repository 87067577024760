<script lang="ts" setup>
import { useFormStore } from '~/store/form.store';
import Button from 'goout-ui-kit/button/Button.vue';
import ImageEditModal from './ImageEditModal.vue';
import { ref } from 'vue';
import { computed } from 'vue';
import { Image } from '~/definitions/schemas/image/image.schema';

const formStore = useFormStore();
const openEdit = ref(false);
const isImageLoaded = ref(false);
const { image } = defineProps<{
  image: Image;
}>();

function deleteImage(id: string) {
  // We have to delete by index in the array, because same images can have same id returned from the server
  const getIndex = formStore.image.data.images.findIndex(
    (image) => image.id === id
  );
  formStore.image.data.images.splice(getIndex, 1);
}

function cancelImageUpload(id: string) {
  if (formStore.image.data.images[id].controller) {
    formStore.image.data.images[id].controller.abort();
    deleteImage(id);
  }
}

function openEditModal() {
  openEdit.value = true;
}

const imageUrl = computed(() => image.url ?? URL.createObjectURL(image.file));
const isUploaded = computed(() => image.progress === 100 || image.url);

function onImageLoad() {
  isImageLoaded.value = true;
}
</script>

<template>
  <div class="relative min-h-[152px] max-h-[152px]">
    <ImageEditModal
      v-if="isUploaded"
      :id="image.id"
      :key="image.id"
      v-model="openEdit"
      :url="imageUrl"
    />
    <Button
      class="absolute w-6 h-6 !p-1.5 top-1 right-1 z-10"
      :variant="isUploaded ? 'primary-dark' : 'tertiary'"
      size="xs"
      icon="Close"
      round
      @click="isUploaded ? deleteImage(image.id) : cancelImageUpload(image.id)"
    />
    <Button
      v-if="isUploaded"
      class="absolute w-6 h-6 !p-1 bottom-1 right-1 z-10"
      variant="tertiary"
      size="xs"
      icon="Dots"
      round
      @click="openEditModal"
    />
    <img
      class="w-full h-full aspect bg-snow-white/50 object-cover image-transition"
      :class="{ 'is-loaded': isImageLoaded }"
      :src="imageUrl"
      alt="uploaded"
      @load="onImageLoad"
    />
    <div
      v-if="!isUploaded"
      class="absolute inset-0 bg-snow-white/74 w-full h-full"
    >
      <div
        class="absolute inset-0 h-full border-b-3 border-blue"
        :style="`width: ${image.progress}%`"
        role="progressbar"
        :aria-valuenow="image.progress || 100"
        aria-valuemin="0"
        aria-valuemax="100"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.image-transition {
  transition: opacity 0.7s ease-in-out;
  opacity: 0;
}

.image-transition.is-loaded {
  opacity: 1;
}
</style>
