import {
  IdIO,
  getEntityRelationShip,
  CurrencyTypeIO,
  CountryIsoLowerCaseIO,
  optional,
} from '../common';
import * as t from 'io-ts';
import { processEntity } from '../../serialization/entity';

export const ContactAttributesIO = t.interface({
  name: t.string,
  contactType: t.string,
  nameInternal: optional(t.string),
  countryIso: CountryIsoLowerCaseIO,
  vatId: optional(t.string),
  vatIdSecondary: optional(t.string),
  companyId: optional(t.string),
  address: t.string,
  city: t.string,
  postalCode: t.string,
  vatRate: optional(t.number),
  birthDate: optional(t.string),
  accounts: t.array(
    t.interface({
      id: IdIO,
      localAccount: optional(t.string),
      swift: optional(t.string),
      iban: optional(t.string),
      name: optional(t.string),
      currency: CurrencyTypeIO,
    })
  ),
});

export const ContactRelationshipsIO = t.interface({
  children: t.array(getEntityRelationShip('contact')),
  users: t.array(getEntityRelationShip('user')),
});

export const ContactIO = t.interface({
  id: IdIO,
  type: t.literal('contact'),
  relationships: ContactRelationshipsIO,
  attributes: ContactAttributesIO,
});

export type ContactRaw = t.TypeOf<typeof ContactIO>;
const serialize = (entity: ContactRaw) => processEntity(entity, {});
export type Contact = ReturnType<typeof serialize>;
