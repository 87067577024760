import { z } from 'zod';
import { EntityTypeEnumSchema } from '../../entityType.schema';

export const HAS_ONE = 'hasOne';
export const HAS_MANY = 'hasMany';

export function convertZodFieldToDevourAttribute(zodField: z.ZodTypeAny) {
  if (zodField instanceof z.ZodEnum) {
    return '';
  } else if (zodField instanceof z.ZodString) {
    return '';
  } else if (zodField instanceof z.ZodNumber) {
    return 0;
  } else if (
    zodField.description === 'one' &&
    zodField instanceof z.ZodOptional
  ) {
    const unwrappedSchema = zodField.unwrap();
    const type = EntityTypeEnumSchema.parse(
      unwrappedSchema['_def']['innerType']['_def']
        .shape()
        ['data']['_def'].shape()['type']['_def']['value']
    );
    return {
      jsonApi: HAS_ONE,
      type,
    };
  } else if (zodField.description === 'many') {
    const type = EntityTypeEnumSchema.parse(
      zodField['_def'].shape().data['_def']?.type?._def?.shape()?.type?._def?.value
    );
    return {
      jsonApi: HAS_MANY,
      type,
    };
  } else if (zodField instanceof z.ZodArray) {
    const itemType = zodField.element;
    if (itemType instanceof z.ZodEnum || itemType instanceof z.ZodString) {
      return [];
    }
  } else if (zodField instanceof z.ZodObject) {
    return convertZodToDevourModel(zodField);
  } else if (zodField instanceof z.ZodUnion) {
    // Handle optional fields
    return zodField.options.some((option) => option instanceof z.ZodUndefined)
      ? null
      : '';
  }

  return null; // Fallback for unhandled types
}

/**
 * Converts a Zod schema to a Devour model
 * @param zodSchema
 * @returns Devour model
 */
export function convertZodToDevourModel<T extends z.ZodObject<any>>(
  zodSchema: T
) {
  const devourAttributes: { [x: string]: string } = {};
  Object.keys(zodSchema.shape).forEach((key) => {
    const devourAttribute = convertZodFieldToDevourAttribute(
      zodSchema.shape[key]
    );

    devourAttributes[key] = devourAttribute;

    if (devourAttribute && 'data' in devourAttribute) {
      devourAttributes[key] = devourAttribute.data;
    }
  });

  return devourAttributes;
}
