<script setup lang="ts">
import TabContainer from '~/components/layout/TabContainer.vue';
import { useFormStore } from '~/store/form.store';
import CategoryOptions from './CategoryOptions.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import CategoryList from './CategoryList.vue';
import { watch } from 'vue';
const { category } = useFormStore();

watch(
  () => category.data.selectedCategories,
  () => {
    if (
      category.data.isConfirmed &&
      category.data.selectedCategories.length === 0
    ) {
      category.data.isConfirmed = false;
    }
  },
  { immediate: true, deep: true }
);
</script>

<template>
  <TabContainer
    :title="$t('wizard.tabs.category.title')"
    :perex="$t('wizard.tabs.category.perex')"
  >
    <div v-if="!category.data.isConfirmed">
      <CategoryOptions />
      <Button
        v-if="category.data.selectedCategories.length > 0"
        class="fixed bottom-1/12 mb-8 left-6/12 transform -translate-x-6/12 !hover:bg-dark-hover"
        variant="primary-dark"
        size="lg"
        round
        @click="category.data.isConfirmed = true"
      >
        {{ $t('category.button.confirm.selection') }}
      </Button>
    </div>
    <CategoryList v-else>
      <Button
        class="my-3 text-blue hover:text-blue hover:opacity-70"
        variant="text"
        size="lg"
        round
        @click="category.data.isConfirmed = false"
      >
        {{ $t('category.change.categories') }}
      </Button>
    </CategoryList>
  </TabContainer>
</template>
