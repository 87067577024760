import { getIdentifiedError, UnknownApiError } from 'goout-utils/error';
import qs from 'qs';

interface ApiCallOptions {
  data?: Record<string, unknown>;
  expectEmptyJsonResponse?: boolean;
  query?: Record<string, any>;
}

export const apiCall = async (
  url: string,
  options: ApiCallOptions = {},
  method = 'GET'
) => {
  let response: Response;
  try {
    const urlWithQuery = options.query
      ? `${url}?${qs.stringify(options.query, { arrayFormat: 'brackets' })}`
      : url;
    response = await fetch(urlWithQuery, {
      method,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'CF-Access-Client-Id': 'ea8553adfbcad6bec65593d2f83832b2.access',
        'CF-Access-Client-Secret': '134042fd0d2e1fbba59e369d83e9032590d512359e48164d02bfb2f4e5299c66'
      },
      ...(options.data && {
        body: JSON.stringify(options.data),
      }),
      credentials: 'include',
    });
  } catch (error: any) {
    throw getIdentifiedError(error, error.status);
  }

  // Check if the response is ok
  if (!response.ok) {
    throw getIdentifiedError(new Error(`HTTP error! status: ${response.status}`), response.status);
  }

  // Handle the response based on the expectEmptyJsonResponse flag
  if (options.expectEmptyJsonResponse) {
    return; // No need to parse the response
  }

  // Parse the response
  try {
    const responseString = await response.text();
    const json = responseString ? JSON.parse(responseString) : {};
    return json;
  } catch (error: any) {
    throw new UnknownApiError(error);
  }
};
