<script lang="ts" setup>
import Modal from '~/components/general/Modal.vue';
import Icon from 'goout-icons/Icon.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

defineProps<{
  title: string;
  description: string;
}>();

const open = ref<boolean>(true);
const router = useRouter();

const handleClose = () => {
  router.push({ name: 'Overview' });
  open.value = false;
};

const handleSubmit = () => {
  open.value = false;
  router.push({ name: 'Overview' });
};
</script>

<template>
  <Modal
    :open
    :on-close="handleClose"
    class="px-10"
    width="md"
    @submit="handleSubmit"
  >
    <template #title>
      <div class="flex gap-2">
        <Icon name="Close" class="text-red" />
        <span>{{ $t(title) }}</span>
      </div>
    </template>
    <template #description>
      {{ $t(description) }}
    </template>

    <template #confirm>
      <span>{{ $t('btn.ok') }}</span>
    </template>
  </Modal>
</template>

<style lang="scss" scoped></style>
