import { useOAuth } from '../api/oauth';

export async function handleRedirectBackFromOAuth(isAdminOnly: boolean) {
  await useOAuth({
    isAdminOnly,
  });

  // Get the current URL
  let currentUrl = new URL(window.location.href);

  // Remove the query parameters and hash
  currentUrl.search = '';
  currentUrl.hash = '';
  // Update the window location without reloading the page
  window.history.replaceState({}, document.title, currentUrl.toString());

  return true;
}
