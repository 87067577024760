{
    "btn.close": "Zavřít",
    "btn.delete": "Potvrdit",
    "btn.send": "Odeslat",
    "btn": {
        "continue": "Pokračovat",
        "ok": "Ok"
    },
    "category": {
        "button": {
            "confirm": {
                "selection": "Potvrdit"
            }
        },
        "change": {
            "categories": "Upravit kategorie"
        },
        "tags": {
            "add": {
                "tags": "Přidat štítky"
            },
            "noMatches": "Nenalezeno"
        }
    },
    "error.invalid_email": "Neplatný e-mail",
    "error.invalid_url": "Adresa je neplatná",
    "error.name_too_short": "Název je příliš krátký.",
    "error.required": "Údaj je povinný",
    "event.delete.description": "Opravdu chcete akci trvale smazat?",
    "event.delete.title": "Smazat",
    "event.edit.description": "V současné době neumožňujeme manuální úpravu akce. Detailně nám popište, co byste chtěli změnit, a my se o to postaráme.",
    "event.edit.title": "Editovat akci",
    "event.list.title.past": "Proběhlé akce",
    "event.list.title.published": "Zveřejněné akce",
    "event.list.title.scheduled": "Budoucí akce",
    "event.sell.description": "Napište nám kontaktní e-mail a detaily ohledně zahájení předprodeje. Budeme od vás potřebovat:\n\nNázev prodejní kategorie či kategorií\nKapacity jednotlivých kategorií\nCeny vstupenek v jednotlivých kategoriích\nZačátek a konec předprodeje (přesný den a čas)",
    "event.sell.title": "Spustit předprodej na akci",
    "event.startAt": "Začátek akce",
    "event": {
        "edit": "Úprava akce",
        "organizer": "Pořadatel akce",
        "sell": {
            "description": "Napište nám kontaktní e-mail a detaily ohledně zahájení předprodeje. Budeme od vás potřebovat:\n\nNázev prodejní kategorie či kategorií\nKapacity jednotlivých kategorií\nCeny vstupenek v jednotlivých kategoriích\nZačátek a konec předprodeje (přesný den a čas)",
            "title": "Spustit předprodej na akci"
        },
        "startAt": "Začátek akce"
    },
    "general.error.publish": "Při zveřejňování akce došlo k chybě.",
    "general.error.title": "Chyba",
    "general": {
        "confirm": "Potvrdit",
        "contact": {
            "organizer": "Pořadatel akce",
            "performer": "Umělci"
        },
        "search": {
            "film": "Hledat v databázi",
            "organizer": {
                "optional": "Hledat v databázi (nepovinný údaj)"
            },
            "performer": {
                "optional": "Hledat v databázi (nepovinný údaj)"
            },
            "venue": "Hledat v databázi"
        },
        "tags": {
            "CANCELLED": "Zrušeno",
            "CLASSICAL_MUSIC": "Klasická hudba",
            "DEBATE": "Debata",
            "EXHIBITIONS_ART": "Umělecké výstavy",
            "EXHIBITIONS_ENDING_SOON": "Brzy končící",
            "EXHIBITIONS_JUST_OPENED": "Právě zahájené",
            "EXHIBITIONS_UPCOMING": "Připravované výstavy",
            "NATURE": "Příroda",
            "NATURE_PARK": "Park",
            "WORLD_MUSIC": "World music",
            "actors": "Herci",
            "actors_actresses": "Herci a Herečky",
            "actors_actressess": "Herci a herečky",
            "actresses": "Herečky",
            "ad": "Audiopopisy",
            "ambient": "Ambient",
            "architecture": "Architektura a urbanismus",
            "article": "Magazín",
            "article_anquette": "Anketa",
            "article_art": "Umění",
            "article_cinema": "Kino",
            "article_design": "Design",
            "article_fashion": "Móda",
            "article_hm": "HM",
            "article_interviews": "Rozhovory",
            "article_introducing": "Novinky",
            "article_invites": "Pozvánky",
            "article_literature": "Literatura",
            "article_news": "Zprávy",
            "article_photoreports": "Fotoreporty",
            "article_points_of_interest": "Zajímavosti",
            "article_portraits": "Portréty",
            "article_reports": "Reporty",
            "article_reviews": "Recenze",
            "article_series": "Seriál",
            "artists": "Umělci",
            "augmented_reality": "Rozšířená realita",
            "bands": "Kapely",
            "bar": "Bar",
            "blues": "Blues",
            "brewery": "Pivovar",
            "cafe": "Kavárna",
            "cafe_literary": "Literární",
            "cancelled": "Zrušeno",
            "chanson": "Šanson",
            "charity": "Charitativní akce",
            "choirs": "Sbor",
            "cinema": "Kina",
            "cinema_art": "Artové kino",
            "cinema_multiplex": "Multiplex",
            "cinema_open_air": "Letní kina",
            "cinema_single_auditorium": "Jeden sál",
            "classical": "Klasická",
            "club": "Kluby",
            "clubbing": "Parties",
            "clubbing_indie": "Indie",
            "clubbing_latin": "Latinskoamerická hudba",
            "comedian": "Komici a komičky",
            "comedians": "Komici a komičky",
            "comedy": "Komedie",
            "concert_hall": "Koncertní sály",
            "concerts": "Koncerty",
            "concerts_blues": "Blues",
            "concerts_folklore": "Folklór",
            "concerts_gospel": "Gospel",
            "concerts_industrial": "Industrial",
            "concerts_latin_american": "Latinskoamerická hudba",
            "concerts_noise": "Noise",
            "concerts_release_party": "Křest",
            "concerts_soul": "Soul",
            "concerts_swing": "Swing",
            "concerts_trip_hop": "Trip hop",
            "conference": "Konference",
            "country": "Country",
            "cultural_centre": "Kulturní centra",
            "cultural_centre_cultural_houses": "Kultuní centrum",
            "cultural_centre_national_centres": "Národní dům",
            "current": "Aktuální",
            "dance": "Dance",
            "dernier": "Derniéra",
            "discussion": "Diskuse",
            "djs": "DJs",
            "do_not_show": "Nezobrazovat",
            "documentary": "Dokument",
            "drama": "Drama",
            "drawings": "Kresba",
            "eco": "Eco",
            "electro": "Electro",
            "electro_swing": "Electro swing",
            "electronic": "Elektronika",
            "english_friendly": "English friendly",
            "event": "Akce",
            "exhibition_comics": "Komiks",
            "exhibition_finissage": "Dernisáž",
            "exhibition_folk_art": "Lidové umění",
            "exhibition_objects": "Objekty",
            "exhibition_site_specific": "Site-specific",
            "exhibition_sound_art": "Sound art",
            "exhibition_typography": "Typografie",
            "exhibitions": "Výstavy",
            "exhibitions_architecture": "Architektura a urbanismus",
            "exhibitions_classic": "Klasické",
            "exhibitions_contemporary": "Současné umění",
            "exhibitions_design": "Design",
            "exhibitions_fashion": "Móda",
            "exhibitions_graphics": "Grafika",
            "exhibitions_history": "Historie",
            "exhibitions_illustrations": "Ilustrace",
            "exhibitions_installations": "Instalace",
            "exhibitions_mixed_media": "Kombinovaná technika",
            "exhibitions_monographic": "Monografická výstava",
            "exhibitions_new_media": "Nová média",
            "exhibitions_paintings": "Malba",
            "exhibitions_performance": "Performance",
            "exhibitions_permanent": "Stálá výstava",
            "exhibitions_photography": "Fotografie",
            "exhibitions_science_technology": "Věda a technika",
            "exhibitions_sculptures": "Plastiky",
            "exhibitions_street_art": "Street art",
            "exhibitions_vernisage": "Vernisáž",
            "exhibitions_video": "Video",
            "experimental": "Experimentální",
            "fairy_tale": "Pohádka",
            "female_singers": "Zpěvačky",
            "festival_permanent": "Permanent",
            "festivals": "Festivaly",
            "festivals_art_design": "Art\/design",
            "festivals_films": "Filmové festivaly",
            "festivals_food": "Food festival",
            "festivals_multigenre": "Multižánrové",
            "festivals_music": "Hudební",
            "festivals_other": "Ostatní",
            "festivals_theatre": "Divadelní",
            "film": "Filmy",
            "film_action": "Akční",
            "film_adventure": "Dobrodružný",
            "film_animated": "Animovaný",
            "film_biographical": "Životopisný",
            "film_biography": "Biografie",
            "film_crime": "Krimi",
            "film_czech": "Český",
            "film_dance": "Taneční",
            "film_disaster": "Katastrofický",
            "film_erotic": "Erotický",
            "film_exploitation": "Exploatační",
            "film_family": "Rodinný",
            "film_fantasy": "Fantasy",
            "film_for_children": "Pro děti",
            "film_historic": "Historický",
            "film_horror": "Horor",
            "film_music": "Hudební",
            "film_mystery": "Mysteriózní",
            "film_new": "Filmové novinky",
            "film_noir": "Noir",
            "film_psychological": "Psychologický",
            "film_recording": "Záznam",
            "film_road_movie": "Road movie",
            "film_romantic": "Romantický",
            "film_sci_fi": "Sci-Fi",
            "film_short": "Krátkometrážní",
            "film_student": "Studentský",
            "film_thriller": "Thriller",
            "film_war": "Válečný",
            "film_western": "Western",
            "filterAll": "Vše",
            "filter_all": "Vybrat vše",
            "folk": "Folk",
            "folklore": "Lidová hudba",
            "for_children": "Pro děti",
            "for_children_educational": "Vzdělávací",
            "for_children_fun_experience": "Zábava a zážitky",
            "for_me": "Mohlo by se ti líbit",
            "foreign": "Zahraniční",
            "free": "Zdarma",
            "funk": "Funk",
            "gallery": "Galerie",
            "gallery_classical_art": "Klasické umění",
            "gallery_contemporary_art": "Současné umění",
            "gallery_design": "Design",
            "gallery_modern_art": "Moderní umění",
            "gallery_photography": "Fotografie",
            "gallery_sculpture": "Socha",
            "gastronomy": "Gastro",
            "gastronomy_brunch": "Brunch",
            "gastronomy_courses": "Kurzy",
            "gastronomy_dinner": "Večeře",
            "gastronomy_market": "Trhy",
            "gastronomy_tasting": "Degustace",
            "gastronomy_vege_vegan": "Vege\/vegan",
            "gospel": "Gospel",
            "handicraft": "Ruční práce",
            "hardcore": "Hardcore",
            "hip_hop": "Hip-Hop",
            "in_city": "Jiné akce",
            "in_city_anniversary_party": "Oslava",
            "in_city_architecture": "Architektura a urbanismus",
            "in_city_art_and_design": "Umění a design",
            "in_city_auction": "Dražba",
            "in_city_ball": "Ples",
            "in_city_burlesque": "Burleska",
            "in_city_city_fashion": "Móda",
            "in_city_city_markets": "Trh",
            "in_city_course": "Kurz",
            "in_city_educational": "Vzdělávání",
            "in_city_fair": "Veletrh",
            "in_city_fun_and_experience": "Zábava a zážitky",
            "in_city_game": "Hra",
            "in_city_guided_tour": "Komentovaná prohlídka",
            "in_city_history": "Historie",
            "in_city_literature": "Literatura",
            "in_city_meeting": "Meeting",
            "in_city_networking": "Networking",
            "in_city_outdoor": "Venkovní akce",
            "in_city_poetry": "Poezie",
            "in_city_quiz": "Kvíz",
            "in_city_release": "Křest",
            "in_city_science_tech": "Věda a technologie",
            "in_city_sport": "Sport",
            "in_city_swap": "Swap",
            "in_city_talk_show": "Talk show",
            "in_city_travels": "Cestování",
            "in_city_walk": "Vycházka",
            "in_city_webinar": "Webinář",
            "in_city_well_being": "Well-being",
            "indie": "Alternativa\/Indie",
            "indoor_space": "Vnitřní prostor",
            "jam_session": "Jam session",
            "jazz": "Jazz",
            "k_pop": "K-pop",
            "latin_american": "Latinskoamerická hudba",
            "liked": "Uložené",
            "local": "Domácí",
            "male_singers": "Zpěváci",
            "metal": "Metal",
            "museum": "Muzea",
            "museum_historical": "Historické",
            "museum_natural_science": "Přírodní vědy",
            "museum_observatory": "Observatoř",
            "museum_technical": "Technické",
            "music": "Hudba",
            "musical": "Muzikál",
            "musicians": "Hudebníci",
            "new": "Nové",
            "new_age": "New Age",
            "newly_announced": "Novinky",
            "noise": "Noise",
            "online": "Online akce",
            "open_decks": "Open decks",
            "orchestras": "Orchestr",
            "order_by_friends": "Podle kamarádů",
            "order_by_start": "Blížící se akce",
            "organizer": "Pořadatel akce",
            "other_venue": "Další místa",
            "partner_internal": "Interní partner",
            "party_breakbeat": "Breakbeat",
            "party_dancehall": "Dancehall",
            "party_disco": "Disco",
            "party_drum_and_bass": "Drum and bass",
            "party_dub": "Dub",
            "party_dubstep": "Dubstep",
            "party_edm": "EDM",
            "party_grime": "Grime",
            "party_hardstyle": "Hardstyle",
            "party_house": "House",
            "party_oldies": "Oldies",
            "party_techno": "Techno",
            "party_trance": "Trance",
            "party_trap": "Trap",
            "past": "Proběhlé",
            "performer": "Umělci",
            "performers_top": "Doporučení umělci",
            "performing": "Vystupující",
            "photographers": "Fotografové",
            "play": "Divadlo",
            "play_alternative": "Alternativní",
            "play_ballet": "Balet",
            "play_black": "Černé divadlo",
            "play_cabaret": "Kabaret",
            "play_dance": "Tanec",
            "play_for_schools": "Pro školy",
            "play_immersive": "Imerzivní divadlo",
            "play_improvisation": "Improvizace",
            "play_literature": "Literární",
            "play_magic": "Kouzla",
            "play_melodrama": "Melodrama",
            "play_musical": "Hudební",
            "play_new_circus": "Nový cirkus",
            "play_one_person_show": "Monodrama",
            "play_opera": "Opera",
            "play_pantomime": "Pantomima",
            "play_performance": "Performance",
            "play_physical": "Fyzické divadlo",
            "play_puppets": "Loutkové",
            "play_scenic_reading": "Scénické čtení",
            "play_slam_poetry": "Slam poetry",
            "play_stand_up_comedy": "Stand-up comedy",
            "play_tragedy": "Tragédie",
            "pop": "Pop",
            "popular": "Populární",
            "post_punk": "Post-punk",
            "postponed": "Odloženo",
            "postponed_indefinitely": "Odloženo na neurčito",
            "premier": "Premiéra",
            "projection": "Projekce",
            "pub": "Hospoda",
            "public_person": "Veřejně známé osoby",
            "public_persons": "Veřejně známé osoby",
            "public_rehersal": "Veřejná zkouška",
            "public_space": "Veřejný prostor",
            "punk": "Punk",
            "r_n_b": "R'n'B",
            "rap": "Rap",
            "rappers": "Rappeři",
            "recommended": "Redakční tipy",
            "recommended_in_category": "Naše tipy v hlavní kategorii",
            "recording": "Záznam",
            "reggae": "Reggae",
            "restaurant": "Restaurace",
            "restaurant_czech": "Česká restaurace",
            "restaurant_italian": "Italská restaurace",
            "rock": "Rock",
            "sale_on_venue": "Vstupenky na místě",
            "schedule": "Harmonogram",
            "schedule_3d": "3D",
            "schedule_all_time": "Celé období",
            "schedule_calendar": "Vybrat dny",
            "schedule_month": "Tento měsíc",
            "schedule_next_week": "Příští týden",
            "schedule_next_year": "Příští rok",
            "schedule_today": "Dnes",
            "schedule_tomorrow": "Zítra",
            "schedule_week": "Tento týden",
            "schedule_weekend": "Tento víkend",
            "schedule_year": "Tento rok",
            "sdh": "Titulky pro neslyšící a nedoslýchavé",
            "show": "Show",
            "sight": "Památka",
            "sight_castle": "Zámek",
            "sight_palace": "Palác",
            "sight_summer_house": "Letní prostor",
            "ska": "Ska",
            "sold_out": "Vyprodáno",
            "soul": "Soul",
            "sound_dubbed": "Dabing",
            "sound_original": "Filmová hudba",
            "sport": "Sport",
            "sport_venue": "Sportovní areál",
            "storytelling": "Storytelling",
            "swing": "Swing",
            "talk": "Přednáška",
            "tea_room": "Čajovna",
            "tech_house": "Tech house",
            "template": "Šablona",
            "theatre": "Divadla",
            "theatre_alternative": "Alternativní",
            "theatre_dance": "Taneční",
            "theatre_groups": "Divadelní spolky",
            "theatre_music": "Hudební",
            "theatre_opera": "Opera",
            "theatres": "Divadla",
            "trap": "Trap",
            "trip_hop": "Trip hop",
            "uk_bass": "UK bass",
            "venue": "Místa",
            "venue_top": "Oblíbené místo",
            "voluntary": "Dobrovolné",
            "workshop": "Workshop"
        },
        "tip": "Tip"
    },
    "image": {
        "choose": {
            "three": {
                "max": {
                    "five": "Fotografie musí být v rozlišení minimálně 1000 × 670 px. Neměly by obsahovat žádný text ani vodoznak."
                }
            }
        },
        "copyright": "Copyright",
        "drag": {
            "zone": "Přetáhni sem"
        },
        "edit": {
            "title": "Upravit fotografii"
        },
        "name": "Název",
        "origin": "Zdroj",
        "save": "Uložit",
        "upload": {
            "helper": {
                "text": "Nejlépe fungují fotografie, které zachycují přímo vystupující umělce, umělkyně či soubory, případně vyobrazení děl samotných (výstavy, screenshoty z filmů, divadelní scény). Nepoužívejte žádná loga, obaly hudebních alb, plakáty apod."
            },
            "link": "Nahrát z počítače"
        }
    },
    "images.error.too.many": "Došlo k překročení limitu počtu fotografií.",
    "images.error.upload": "Chyba při ukládání fotografie",
    "layout.mainPage.addNewEventButton": "Přidat novou akci",
    "layout.mainPage.myEvents": "Moje akce",
    "layout.mainPage.showLess": "Zobrazit méně",
    "layout.mainPage.showMore": "Zobrazit více",
    "layout": {
        "mainPage": {
            "showLess": "Zobrazit méně",
            "showMore": "Zobrazit více"
        }
    },
    "modal.confirm.delete.desc": "Tato akce je nevratná.",
    "modal.confirm.delete.title": "Opravdu chcete akci trvale smazat?",
    "modal.confirm.edit.desc": "Všechny změny jsou uloženy.",
    "modal.confirm.edit.title": "Akce byla změněna",
    "modal.confirm.sell.desc": "Potvrďte kliknutím na tlačítko níže.",
    "modal.confirm.sell.title": "Opravdu odeslat zprávu?",
    "modal.error.delete.desc": "Zkuste opakovat akci.",
    "modal.error.delete.title": "Něco se pokazilo",
    "modal.error.edit.desc": "Zkuste opakovat akci.",
    "modal.error.edit.title": "Něco se pokazilo",
    "modal.error.sell.desc": "Zkuste opakovat akci.",
    "modal.error.sell.title": "Něco se pokazilo",
    "modal.success.delete.desc": "Akce byla smazána.",
    "modal.success.delete.title": "Hotovo",
    "modal.success.edit.desc": "Děkujeme! Ozveme se vám do 24 hodin.",
    "modal.success.edit.title": "Zpráva odeslána",
    "modal.success.sell.desc": "Děkujeme! Ozveme se vám do 24 hodin.",
    "modal.success.sell.title": "Zpráva odeslána",
    "modal": {
        "event": {
            "delete": "Smazat",
            "edit": "Upravit"
        }
    },
    "overview": {
        "feedback": {
            "title": "Dejte nám feedback"
        },
        "give": {
            "us": {
                "feedback": {
                    "text": "Chceme se pro vás zlepšovat a vaše zpětná vazba je duležitá"
                }
            }
        }
    },
    "promoter": {
        "form": {
            "field": {
                "country": {
                    "option": {
                        "cz": "Česko",
                        "de": "Německo",
                        "pl": "Polsko",
                        "sk": "Slovensko"
                    }
                }
            }
        }
    },
    "section": {
        "category": {
            "mainCategory": "Hlavní kategorie",
            "setMain": "Nastavit jako hlavní kategorii"
        },
        "photo": {
            "label": "Popis fotografie"
        }
    },
    "tab.category": "Kategorie",
    "tab.date": "Datum konání",
    "tab.image": "Fotografie",
    "tab.metadata_film": "Film",
    "tab.metadata_play": "Divadlo",
    "tab.organizer": "Pořadatel akce",
    "tab.performer": "Umělci",
    "tab.publish": "Zveřejnit",
    "tab.venue": "Místo konání",
    "tab": {
        "general": "Název a popis"
    },
    "tabs": {
        "publish": {
            "summary": "Přehled údajů"
        }
    },
    "topbar": {
        "logout": "Odhlásit se"
    },
    "venue.input": "Místo",
    "wizard.intro.firstEvent.addYourEvent": "Spustili jsme novou funkci:",
    "wizard.intro.firstEvent.button": "To chci!",
    "wizard.intro.firstEvent.title": "Přidej svoji akci na GoOut",
    "wizard.intro.steps.one.perex": "Kdo vystupuje? Kde se akce koná? Kdy začíná? Proč si ji nenechat ujít?",
    "wizard.intro.steps.one.title": "Vyplň základní informace o akci",
    "wizard.intro.steps.three.perex": "Po zveřejnění můžeme dále pomoct i s nastavením předprodeje.",
    "wizard.intro.steps.three.title": "Nahraj akci přímo na GoOut",
    "wizard.intro.steps.two.perex": "V interaktivním formuláři snadno vyplníš všechny potřebné informace.",
    "wizard.intro.steps.two.title": "Přidej fotky a další detaily",
    "wizard.tabs.category.perex": "Vyber libovolné množství kategorií. Můžeš k nim přidat i žánrové štítky.",
    "wizard.tabs.category.title": "Kategorie",
    "wizard.tabs.film.countryIsos": "Původ",
    "wizard.tabs.film.director": "Režie",
    "wizard.tabs.film.length": "Délka",
    "wizard.tabs.film.minorPerformers": "Hrají",
    "wizard.tabs.film.originalName": "Původní název",
    "wizard.tabs.film.released": "Premiéra",
    "wizard.tabs.film.search": "Hledat v databázi",
    "wizard.tabs.film.tags": "Žánr",
    "wizard.tabs.metadata_film.perex": "Na této stránce můžete vyplnit relevantní doplňující informace.",
    "wizard.tabs.metadata_film.title": "Údaje o filmu",
    "wizard.tabs.metadata_play.perex": "Na této stránce můžete vyplnit relevantní doplňující informace.",
    "wizard.tabs.metadata_play.title": "Údaje o hře",
    "wizard.tabs.play.actors": "Hrají",
    "wizard.tabs.play.author": "Autor",
    "wizard.tabs.play.director": "Režie",
    "wizard.tabs.play.length": "Délka",
    "wizard.tabs.play.name": "Název hry",
    "wizard.tabs.play.premiere": "Premiéra",
    "wizard": {
        "back": "Zpět",
        "editor": {
            "bold": "Tučně",
            "h1": "Nadpis 1",
            "h2": "Nadpis 2",
            "h3": "Nadpis 3",
            "italic": "Kurzíva",
            "listOrdered": "Číslovaný seznam",
            "listUnordered": "Nečíslovaný seznam",
            "underline": "Podtržení"
        },
        "intro": {
            "firstEvent": {
                "button": "To chci!"
            },
            "steps": {
                "one": {
                    "perex": "Kdo vystupuje? Kde se akce koná? Kdy začíná? Proč si ji nenechat ujít?",
                    "title": "Vyplň základní informace o akci"
                },
                "three": {
                    "perex": "Po zveřejnění můžeme dále pomoct i s nastavením předprodeje.",
                    "title": "Nahraj akci přímo na GoOut"
                },
                "two": {
                    "perex": "V interaktivním formuláři snadno vyplníš všechny potřebné informace.",
                    "title": "Přidej fotky a další detaily"
                }
            },
            "title": "Přidej svoji akci na GoOut"
        },
        "next": "Pokračovat",
        "publish": "Zveřejnit akci",
        "tabs": {
            "date": {
                "add": "Přidat další termín",
                "end": {
                    "date": "Datum konce",
                    "time": "Čas konce"
                },
                "perex": "Kdy se vaše akce koná?",
                "start": {
                    "date": "Datum začátku",
                    "time": "Čas začátku"
                },
                "title": "Termín akce"
            },
            "general": {
                "categories": "Kategorie",
                "description": "Popis akce",
                "editor": {
                    "optional": "Popis akce (nepovinný údaj)"
                },
                "name": "Název akce",
                "perex": "Vyplňte název vaší akce. Doporučujeme také přidat pár vět o akci samotné.",
                "performers": "Umělci",
                "tags": "Štítky",
                "title": "Základní informace",
                "venue": "Místo konání",
                "web": {
                    "optional": "Web akce (nepovinný údaj)"
                }
            },
            "image": {
                "perex": "U každé nové akce je nutné přidat minimálně jednu fotografii. Ideální počet je 3 až 5, maximální počet je 5.",
                "title": "Přidat fotografie"
            },
            "organizer": {
                "perex": "Kdo akci pořádá? Máte pořadatelský profil na GoOut? Je vaše akce pořádaná někým, kdo jej má?",
                "title": "Pořadatel akce"
            },
            "performer": {
                "perex": "V tomto kroku můžete vybrat vystupující umělce z naší databáze.",
                "title": "Přidat vystupující"
            },
            "publish": {
                "categories": "Kategorie",
                "description": "Popis akce",
                "name": "Název akce ",
                "perex": "Jsou všechny údaje v pořádku? Pokud ano, uložte akci a my ji umístíme na kulturní přehled GoOut.",
                "performers": "Umělci",
                "tags": "Štítky",
                "title": "Poslední kontrola",
                "venue": "Místo konání",
                "web": "Web akce"
            },
            "venue": {
                "perex": "Pro pokračování je nutné zadat místo konání akce. Můžete vybrat jakékoliv místo z naší datábaze.",
                "title": "Místo konání"
            }
        }
    }
}