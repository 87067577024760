import { defineStore } from 'pinia';
import { computed, reactive, ref, watch } from 'vue';
import { TabEnum } from './tabs.store';
import { useRouteParams } from '@vueuse/router';
import { watchDebounced } from '@vueuse/core';
import { DEBOUNCE_TIME } from '~/composables/useForm';
import {
  generalSchema,
  categorySchema,
  venueSchema,
  performerSchema,
} from '~/definitions/schemas/';
import { imagesSchema } from '~/definitions/schemas/image/image.schema';
import {
  CategoryTabState,
  GeneralTabState,
  PerformerTabState,
  FormTabState,
  VenueTabState,
  ImageTabState,
  PlayMetadataData,
  FilmMetadataData,
  DateTabState,
  OrganizerTabState,
  PublishTabState,
} from '~/types/form.types';
import {
  initialGeneralState,
  initialCategoryState,
  initialVenueState,
  initialPerformerState,
  initialImageState,
  initialMetadataFilmState,
  initialMetadataPlayState,
  initialDateState,
  initialOrganizerState,
  initialPublishState,
} from '~/store/initFormStore';
import { Router, useRoute, useRouter } from 'vue-router';
import { filmSchema } from '~/definitions/schemas/metadata/film.schema';
import { playSchema } from '~/definitions/schemas/metadata/play.schema';
import { datesSchema } from '~/definitions/schemas/date/date.schema';
import { organizerSchema } from '~/definitions/schemas/organizer/organizer.schema';
import { publishSchema } from '~/definitions/schemas/publish/publish.schema';
import { usePublishEvent } from '~/api/usePublishEvent';
import { usePublishSchedule } from '~/api/usePublishSchedule';
import { stringifyToBase25 } from 'goout-utils';
import { useToast } from '~/composables/useToast';
import qs from 'qs';
import * as Sentry from '@sentry/vue';
import { getCurrentLocale } from '~/i18n';

export const useFormStore = defineStore(
  'form',
  () => {
    const currentTab = useRouteParams('step', 'general');
    const route = useRoute();
    const router = useRouter();
    const isSubmitting = ref(false);
    const toast = useToast();

    const general = reactive<FormTabState<GeneralTabState>>(
      initialGeneralState()
    );
    const category = reactive<FormTabState<CategoryTabState>>(
      initialCategoryState()
    );
    const venue = reactive<FormTabState<VenueTabState>>(initialVenueState());
    const performer = reactive<FormTabState<PerformerTabState>>(
      initialPerformerState()
    );
    const image = reactive<FormTabState<ImageTabState>>(initialImageState());
    const metadata_film = reactive<FormTabState<FilmMetadataData>>(
      initialMetadataFilmState()
    );
    const metadata_play = reactive<FormTabState<PlayMetadataData>>(
      initialMetadataPlayState()
    );
    const date = reactive<FormTabState<DateTabState>>(initialDateState());
    const organizer = reactive<FormTabState<OrganizerTabState>>(
      initialOrganizerState()
    );
    const publish = reactive<FormTabState<PublishTabState>>(
      initialPublishState()
    );

    const tabSchemaMapper: Record<
      TabEnum,
      { schema: any; data: FormTabState<any> }
    > = {
      [TabEnum.General]: {
        schema: generalSchema,
        data: general,
      },
      [TabEnum.Category]: {
        schema: categorySchema,
        data: category,
      },
      [TabEnum.Venue]: {
        schema: venueSchema,
        data: venue,
      },
      [TabEnum.Performer]: {
        schema: performerSchema,
        data: performer,
      },
      [TabEnum.MetadataFilm]: {
        schema: filmSchema,
        data: metadata_film,
      },
      [TabEnum.MetadataPlay]: {
        schema: playSchema,
        data: metadata_play,
      },
      [TabEnum.Image]: {
        schema: imagesSchema,
        data: image,
      },
      [TabEnum.Date]: {
        schema: datesSchema,
        data: date,
      },
      [TabEnum.Organizer]: {
        schema: organizerSchema,
        data: organizer,
      },
      [TabEnum.Publish]: {
        schema: publishSchema,
        data: publish,
      },
    };

    function validateTabData() {
      const { data: tabData, schema } = tabSchemaMapper[currentTab.value];
      if (!tabData.data || !schema) return;
      const result = schema.safeParse(tabData.data);
      if (!result.success) {
        tabSchemaMapper[currentTab.value].data.isValid = false;
      } else {
        tabSchemaMapper[currentTab.value].data.isValid = true;
      }
    }

    async function submit() {
      const locale = getCurrentLocale();
      const subdomain = window.location.hostname.split('.').at(0);
      const isLoc = subdomain?.includes('loc');
      const isDev = subdomain?.includes('dev');
      const isProd = window.location.hostname === 'https://goout.net';
      const url = isLoc
        ? 'https://loc.goout.net:3000' || 'https://loc.goout.net:8080'
        : isDev
        ? 'https://dev.goout.net'
        : isProd
        ? 'https://goout.net'
        : 'https://goout.net';

      const newTab = window.open(`${url}/creator/loading`, '_blank');

      try {
        isSubmitting.value = true;
        const eventId = await usePublishEvent().perform();

        if (eventId) {
          const result = await usePublishSchedule().perform(eventId);
          const firstSchedule = result[0];
          if (firstSchedule.status === 'fulfilled' && firstSchedule.value.ok) {
            const schedule = await firstSchedule.value.json();

            if (!schedule) {
              Sentry.captureException('Schedule not found');
            }

            router.push({
              name: 'Overview',
            });

            if (!window.location.host.includes('loc')) resetStore();

            handleNewTabAndRedirect(
              newTab,
              url,
              locale,
              schedule,
              router,
              schedule.data.attributes.locales[locale]?.siteUrl,
              eventId
            );
          }
        }
      } catch (error) {
        toast.showToast({
          message: 'general.error.publish',
          position: 'top-right',
          showCloseIcon: true,
          duration: 5000,
        });
        newTab?.close();
      } finally {
        isSubmitting.value = false;
      }
    }

    function resetStore() {
      Object.assign(general, initialGeneralState());
      Object.assign(category, initialCategoryState());
      Object.assign(venue, initialVenueState());
      Object.assign(performer, initialPerformerState());
      Object.assign(image, initialImageState());
      Object.assign(metadata_film, initialMetadataFilmState());
      Object.assign(metadata_play, initialMetadataPlayState());
      Object.assign(date, initialDateState());
      Object.assign(organizer, initialOrganizerState());
      Object.assign(publish, initialPublishState());

      // Also refresh local storage
      window.localStorage.removeItem('form');
    }

    watchDebounced(
      () => tabSchemaMapper[currentTab.value].data,
      validateTabData,
      { deep: true, debounce: DEBOUNCE_TIME, immediate: true }
    );

    const isCurrentTabDataValid = computed(
      () => tabSchemaMapper[currentTab.value].data.isValid
    );

    // Watch for changes in the route and mark the tab as visited
    watch(
      () => route.params.step,
      (newStep) => {
        if (newStep as TabEnum)
          tabSchemaMapper[newStep as TabEnum].data.isVisited = true;
      },
      { immediate: true }
    );

    return {
      general,
      category,
      venue,
      performer,
      metadata_film,
      metadata_play,
      image,
      date,
      organizer,
      publish,
      validateTabData,
      isCurrentTabDataValid,
      submit,
      isSubmitting,
      resetStore,
    };
  },
  {
    persist: true,
  }
);

interface QueryParams {
  [key: string]: any;
}

async function handleNewTabAndRedirect(
  newTab: Window | null,
  url: string,
  locale: string,
  schedule: any,
  router: Router,
  link: string,
  eventId: string
) {
  const queryParams: QueryParams = {
    creator: 'true',
    link,
    fetchFresh: true,
  };

  const queryString = qs.stringify(queryParams);

  const finalUrl = `${url}/${locale}/creator/sz${stringifyToBase25(
    schedule.data.id
  )}?${queryString}`;

  if (newTab !== null) {
    // Set up an event listener to handle messages from the new tab
    window.addEventListener('message', (event) => {
      // Check the origin of the message to ensure it's from a trusted source
      const expectedOrigin = new URL(url).origin;
      if (event.origin !== expectedOrigin) {
        Sentry.captureException(`Unexpected origin: ${event.origin}`);
        return;
      }
      if (event.data === 'navigate-back') {
        window.focus();
        newTab.close();
        router.push({
          name: 'EventSellModal',
          params: { id: eventId },
        });
      }
    });

    // Set the new tab URL after setting up the message listener
    newTab.location.href = finalUrl;
  }
}
