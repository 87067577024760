import AuthModal from './components/AuthModal.vue';
import { logout } from './api/logout';
import { createApp } from 'vue';
import { i18n } from './i18n';
import { useAuth } from './composables/useAuth';

export interface CreateAuthModalOptions {
  /**
   * If true, the modal will be removed from the DOM after it is closed
   * e.g For Creator this is false for Discovery this is true
   */
  removable?: boolean;

  /**
   * If true, the modal will only be accessible to admin users
   */
  isAdminOnly?: boolean;
}
/**
 * Create auth modal and mount it to the body
 * @returns void
 */
function createAuthModal({ removable = false, isAdminOnly = false }: CreateAuthModalOptions = {}) {
  // Create anchor element to mount the app to
  const anchor = document.createElement('div');
  anchor.id = 'auth-modal';
  document.body.appendChild(anchor);

  const app = createApp(AuthModal, {
    removable,
    isAdminOnly
  });
  app.use(i18n);
  app.mount('#auth-modal');
}

export { logout, createAuthModal, useAuth };
