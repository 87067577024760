<script setup lang="ts">
import TabContainer from '~/components/layout/TabContainer.vue';
import LocalizedTextInput from '~/components/general/LocalizedTextInput.vue';
import TextInput from '~/components/general/TextInput.vue';

import LocalizedEditor from '~/components/general/LocalizedEditor.vue';
import { useFormStore } from '~/store/form.store';
import { generalSchema } from '~/definitions/schemas/';

const { general } = useFormStore();
</script>

<template>
  <TabContainer
    :title="$t('wizard.tabs.general.title')"
    :perex="$t('wizard.tabs.general.perex')"
  >
    <div class="flex flex-col gap-y-7">
      <LocalizedTextInput
        v-model="general.data.name"
        :schema="generalSchema.shape.name"
        :debounce-time="1000"
        :label="$t('wizard.tabs.general.name')"
        required
      />

      <LocalizedEditor
        v-model="general.data.description"
        :schema="generalSchema.shape.description"
        :label="$t('wizard.tabs.general.editor.optional')"
      />

      <TextInput
        v-model="general.data.web"
        :schema="generalSchema.shape.web"
        :label="$t('wizard.tabs.general.web.optional')"
      />
    </div>
  </TabContainer>
</template>
