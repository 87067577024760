<script lang="ts" setup></script>

<template>
  <div class="flex p-5 gap-4 w-full border-snow-white border justify-around">
    <span class="text-blue font-medium text-sm">{{ $t('general.tip') }}</span>
    <p class="text-gray-dark">
      {{ $t('image.upload.helper.text') }}
    </p>
  </div>
</template>

<style lang="scss" scoped></style>
