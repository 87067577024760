<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g role="presentation" fill="currentColor">
      <path
        d="M12,5.06666667 C11.0611111,5.06666667 10.1638889,5.25 9.30833333,5.61666667 C8.45833333,5.98333333 7.72222222,6.47777778 7.1,7.1 C6.47777778,7.72222222 5.98333333,8.45833333 5.61666667,9.30833333 C5.25,10.1638889 5.06666667,11.0611111 5.06666667,12 C5.06666667,12.9388889 5.25,13.8361111 5.61666667,14.6916667 C5.98333333,15.5416667 6.47777778,16.2777778 7.1,16.9 C7.72222222,17.5222222 8.45833333,18.0166667 9.30833333,18.3833333 C10.1638889,18.75 11.0611111,18.9333333 12,18.9333333 C12.9388889,18.9333333 13.8361111,18.75 14.6916667,18.3833333 C15.5416667,18.0166667 16.2777778,17.5222222 16.9,16.9 C17.5222222,16.2777778 18.0166667,15.5416667 18.3833333,14.6916667 C18.75,13.8361111 18.9333333,12.9388889 18.9333333,12 C18.9333333,11.0611111 18.75,10.1638889 18.3833333,9.30833333 C18.0166667,8.45833333 17.5222222,7.72222222 16.9,7.1 C16.2777778,6.47777778 15.5416667,5.98333333 14.6916667,5.61666667 C13.8361111,5.25 12.9388889,5.06666667 12,5.06666667 Z M12,4 C13.0833333,4 14.1194444,4.21111111 15.1083333,4.63333333 C16.1027778,5.05555556 16.9555556,5.62222222 17.6666667,6.33333333 C18.3777778,7.04444444 18.9444444,7.89722222 19.3666667,8.89166667 C19.7888889,9.88055556 20,10.9166667 20,12 C20,13.0833333 19.7888889,14.1194444 19.3666667,15.1083333 C18.9444444,16.1027778 18.3777778,16.9555556 17.6666667,17.6666667 C16.9555556,18.3777778 16.1027778,18.9444444 15.1083333,19.3666667 C14.1194444,19.7888889 13.0833333,20 12,20 C10.9166667,20 9.88055556,19.7888889 8.89166667,19.3666667 C7.89722222,18.9444444 7.04444444,18.3777778 6.33333333,17.6666667 C5.62222222,16.9555556 5.05555556,16.1027778 4.63333333,15.1083333 C4.21111111,14.1194444 4,13.0833333 4,12 C4,10.9166667 4.21111111,9.88055556 4.63333333,8.89166667 C5.05555556,7.89722222 5.62222222,7.04444444 6.33333333,6.33333333 C7.04444444,5.62222222 7.89722222,5.05555556 8.89166667,4.63333333 C9.88055556,4.21111111 10.9166667,4 12,4 Z"
      />
      <polygon
        points="15.502 8.199 16.466 8.927 10.968 16.259 7.53 12.821 8.38 11.961 10.837 14.418"
      />
    </g>
  </svg>
</template>