<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" class="fill">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11 0V11H22L11 22L0 11L11 0Z"
      fill="currentColor"
    />
  </svg>
</template>
