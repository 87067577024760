<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 11a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z" fill="currentColor" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 9a6.002 6.002 0 0 1-5 5.917V20h4v2H7v-2h4v-5.083A6.002 6.002 0 0 1 12 3a6 6 0 0 1 6 6Zm-6 4a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
      fill="currentColor"
    />
  </svg>
</template>
