<script setup lang="ts">
import TabContainer from '~/components/layout/TabContainer.vue';
import FilmInputs from './FilmInputs.vue';
import PlayInputs from './PlayInputs.vue';
import { useTabsStore } from '~/store/tabs.store';
import { shallowRef } from 'vue';
const tabsStore = useTabsStore();

const metadataTypeComponentMapper = shallowRef({
  film: FilmInputs,
  play: PlayInputs,
});
</script>

<template>
  <TabContainer
    :title="$t(`wizard.tabs.${tabsStore.currentTab}.title`)"
    :perex="$t(`wizard.tabs.${tabsStore.currentTab}.perex`)"
  >
    <component :is="metadataTypeComponentMapper[tabsStore.currentTabType]" />
  </TabContainer>
</template>
