<script setup lang="ts">
import TabContainer from '~/components/layout/TabContainer.vue';
import SelectedItem from '~/components/general/SelectedItem.vue';

import { useFormStore } from '~/store/form.store';
import { ref, computed, watch } from 'vue';
import ComboboxMultiple, {
  ComboboxSingleItem,
} from '~/components/general/ComboboxMultiple.vue';
import { useSortable } from '@vueuse/integrations/useSortable';
import { useSessionStore } from '~/store/session.store';
import { useI18n } from 'vue-i18n';
const { organizer } = useFormStore();
const query = ref('');
const { t } = useI18n();
const { user } = useSessionStore();

const items = computed<ComboboxSingleItem[]>(() =>
  user.contacts?.map((contact) => ({
    id: contact.id?.toString() || '',
    title: contact?.name || '',
    description:
      t(
        `promoter.form.field.country.option.${contact.countryIso?.toLowerCase()}`
      ) || '',
  }))
);

const listRef = ref<HTMLElement | null>(null);

useSortable(listRef, organizer.data.selectedItems, {
  animation: 150,
  handle: '.handle',
  onStart: (evt) => {
    const elementBeingDragged = evt.item; // evt.item refers to the current DOM element being dragged
    elementBeingDragged.classList.add('sorting');
  },
  onEnd: (evt) => {
    const elementBeingDragged = evt.item;
    elementBeingDragged.classList.remove('sorting');
  },
});

const isPrefilled = computed(() => items.value?.length === 1 || false);

// If item length is 0 and query is not empty or selected item is already in the list, show no matches
watch(
  items,
  () => {
    if (items.value && items.value.length === 1) {
      organizer.data.selectedItems = [items.value[0]];
    }
  },
  { immediate: true }
);
</script>

<template>
  <TabContainer
    :title="$t('wizard.tabs.organizer.title')"
    :perex="$t('wizard.tabs.organizer.perex')"
  >
    <ComboboxMultiple
      v-if="!isPrefilled"
      v-model:selected="organizer.data.selectedItems"
      v-model:query="query"
      :items
      :placeholder="$t('general.search.organizer.optional')"
      is-select
    />

    <div ref="listRef" class="pt-3">
      <SelectedItem
        v-for="(org, idx) in organizer.data.selectedItems.map((item) => ({
          id: item.id,
          title: item.title,
          url: item.url,
          description: item.description,
        }))"
        :key="org.id"
        :item="org"
        :is-sortable="!isPrefilled"
        :is-removable="!isPrefilled"
        :has-image="false"
        @remove="organizer.data.selectedItems.splice(idx, 1)"
      />
    </div>
  </TabContainer>
</template>

<style lang="scss" scoped>
.sorting {
  opacity: 0;
}
</style>
