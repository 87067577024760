import { useCookie, UnauthorizedError, InternalServerError } from "goout-utils";
import { OAUTH_LOGIN_FB, OAUTH_LOGIN_GOOGLE } from "./endpoints";
import { apiCall } from "./fetch";

/**
 * Connects the user with the OAuth provider to Goout
 * @param accessToken
 * @returns
 */
export async function connectFromOAuthToGoout(accessToken: string) {
  try {
    const connectEndpoints = {
      facebook: OAUTH_LOGIN_FB,
      google: OAUTH_LOGIN_GOOGLE,
    };

    const strategy = useCookie().get('goout-auth.strategy');
    if (!strategy) {
      throw new Error('auth strategy is missing');
    }

    return await apiCall(
      connectEndpoints[strategy as keyof typeof connectEndpoints],
      {
        query: {
          accessToken,
        },
        expectEmptyJsonResponse: true,
      },
      'GET'
    );

  } catch (error: any) {
    console.error('useOAuthConnect error', error);

    if (error.status === 401) {
      throw new UnauthorizedError();
    } else if (error.status >= 500) {
      throw new InternalServerError();
    }
  }
}
