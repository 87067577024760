<script lang="ts" setup>
import { CategoryOptionProps } from './CategoryOption.vue';
import CategoryImage from './CategoryImage.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import { useFormStore } from '~/store/form.store';
import CategorySetMainButton from './CategorySetMainButton.vue';
import { onMounted } from 'vue';
import { EventCategoriesWithTags } from 'goout-data';

const { category } = defineProps<Omit<CategoryOptionProps, 'index'>>();

const formStore = useFormStore();

function removeCategory(category: string) {
  // Remove category from selected categories
  formStore.category.data.selectedCategories =
    formStore.category.data.selectedCategories.filter(
      (selectedCategory) => selectedCategory !== category
    );

  // If the removed category was the primary category, set the first category as primary
  setFirstCategoryAsPrimary();

  // Remove all tags from the removed category
  formStore.category.data.tags = formStore.category.data.tags.filter(
    (tag) => !EventCategoriesWithTags[category].tags.includes(tag)
  );
}

function setFirstCategoryAsPrimary() {
  formStore.category.data.primaryCategory =
    formStore.category.data.selectedCategories[0];
}

onMounted(() => setFirstCategoryAsPrimary());
</script>

<template>
  <div class="flex items-center justify-between py-4">
    <div class="flex items-center">
      <CategoryImage class="pr-5" :category :size="12" />
      <h4 class="text-md font-medium">
        {{ $t(`general.tags.${category}`) }}
      </h4>
    </div>
    <div class="justify-self-end flex items-center gap-x-4">
      <CategorySetMainButton :category />
      <Button
        icon="Close"
        variant="tertiary"
        size="xs"
        class="rounded-full h-9 w-9 !bg-white border-1 border-gray/20 text-black !p-2.5"
        @click="removeCategory(category)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
