import z from 'zod';

export const INPUT_LANGUAGES = ['cs', 'en', 'de', 'pl', 'sk', 'uk', 'deprecated_fi'] as const

export const LanguageEnumSchema = z
  .enum(['', ...INPUT_LANGUAGES])
  .refine((val) => Object.keys(val).length > 0, {
    message: 'At least one locale must be defined',
  });

export type LanguageEnum = z.infer<typeof LanguageEnumSchema>;