import z from 'zod';
import { VenueDtoSchema } from '../venue/venue.schema';
import { CityDtoSchema } from '../city/city.schema';
import { ImageDtoSchema } from '../image/image.schema';
import { ScheduleDtoSchema } from '../schedule/schedule.schema';
import { UserDtoSchema } from '../user/user.schema';
import { SaleDtoSchema } from '../sale/sale.schema';
import { EventDtoSchema } from '../event/event.schema';
import { DealDtoSchema } from '../deal/deal.schema';
import { ContactDtoSchema } from '../contact/contact.schema';
import { PerformerDtoSchema } from '../performer/performer.schema';
import { VideoDtoSchema } from '../video/video.schema';

/**
 * JSON API included zod object
 */
export const JsonApiIncludedSchema = z.discriminatedUnion('type', [
  VenueDtoSchema,
  CityDtoSchema,
  ImageDtoSchema.omit({ relationships: true }), //Since images do not have relationships, we can omit it
  ScheduleDtoSchema,
  UserDtoSchema,
  SaleDtoSchema,
  EventDtoSchema,
  DealDtoSchema,
  ContactDtoSchema,
  PerformerDtoSchema,
  VideoDtoSchema,
]);
