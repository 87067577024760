import z from 'zod';
import { CurrencyEnumSchema } from '../currency.schema';
import {
  RelationshipToMany,
  RelationshipToOne,
} from '../jsonApi/jsonApiRelationships.schema';
import { EntityTypeEnumSchema } from '../entityType.schema';

const SaleStateEnum = z.enum([
  'active',
  'sold_out',
  'hidden',
  'scheduled',
  'ended',
  'cancelled',
]);

const SaleTypeEnum = z.enum([
  'regular',
  'voucher',
  'season',
  'membership',
  'merchandise',
  'queue_required',
]);

const SaleRequiresEnum = z.enum([
  'donation',
  'hall',
  'password_protected',
  'sale_discounts',
  'min_ticket_select',
  'max_ticket_select',
  'named_tickets',
  'inner_sales',
  'pre_owned_tickets',
  'promo_code',
  'service_fee_ios',
  'queue',
]);

const SaleDtoPaymentMethods = z.object({
  vouchers: z.boolean(),
  transfersPayU: z.boolean(),
  transfersGooglePay: z.boolean(),
  applePay: z.boolean(),
  czTransfersTwisto: z.boolean(),
  plTransfersTwisto: z.boolean(),
  czBenefits: z.boolean(),
  skBenefits: z.boolean(),
  payPal: z.boolean(),
  sazka: z.boolean(),
  transfersSimple: z.boolean(),
  reservations: z.boolean(),
  invoices: z.boolean(),
  reservationInvoices: z.boolean(),
});

const SaleDtoTracking = z.object({
  sklikId: z.string().nullish(),
  facebookPixelId: z.string().nullish(),
  googleAnalyticsId: z.string().nullish(),
  googleAdsId: z.string().nullish(),
});

const AdditionalFieldType = z.enum(['text', 'select']);
const AdditionalFieldOption = z.object({ key: z.string(), value: z.string() });

const AdditionalField = z.object({
  name: z.string(),
  required: z.boolean(),
  hidden: z.boolean(),
  hiddenDesk: z.boolean(),
  type: AdditionalFieldType,
  options: z.array(AdditionalFieldOption).nullish(),
});

const SaleDtoLocale = z.object({
  formMessage: z.string().nullish(),
  ticketMessage: z.string().nullish(),
  ticketFullMessage: z.string().nullish(),
  voucherUseMessage: z.string().nullish(),
  cancellationMessage: z.string().nullish(),
  termsAndConditionsUrl: z.string().nullish(),
  field1: z.string().nullish(),
  field2: z.string().nullish(),
  field3: z.string().nullish(),
  field4: z.string().nullish(),
  field5: z.string().nullish(),
  field6: z.string().nullish(),
  fields: z.array(AdditionalField).nullish(),
});

export const SaleDtoAttributesSchema = z.object({
  currency: CurrencyEnumSchema.nullish(),
  startAt: z.string().nullish(),
  saleUrl: z.string().nullish(),
  state: SaleStateEnum.nullish(),
  isDonation: z.boolean().nullish(),
  isSeason: z.boolean().nullish(),
  isSeasonWithSeparateTickets: z.boolean().nullish(),
  isVoucher: z.boolean().nullish(),
  saleType: SaleTypeEnum.nullish(),
  tags: z.array(z.string()).nullish(),
  requires: z.array(SaleRequiresEnum).nullish(),
  maxTicketSelect: z.number().int().nullish(),
  serviceFeeCents: z.number().int().nullish(),
  precisionCents: z.number().int().nullish(),
  posId: z.string().nullish(),
  priceOnVenue: z.string().nullish(),
  hallSymbolsDetail: z.boolean().nullish(),
  emailRequired: z.boolean().nullish(),
  promoCodeProtected: z.boolean().nullish(),
  paymentMethods: SaleDtoPaymentMethods.nullish(),
  partnerId: z.string().nullish(),
  showInnerSales: z.boolean().nullish(),
  locales: SaleDtoLocale.nullish(),
  tracking: SaleDtoTracking.nullish(),
});

export const SaleDtoRelationshipsSchema = z.object({
  schedule: RelationshipToOne('schedules'),
  organizer: RelationshipToOne('users'),
  hall: RelationshipToOne('halls'),
  innerSaleHalls: RelationshipToMany('halls'),
  deals: RelationshipToMany('deals'),
  discounts: RelationshipToMany('discounts'),
  checkIns: RelationshipToMany('check_ins'),
  innerSales: RelationshipToMany('sales'),
});

const SaleDtoLinksSchema = z.object({
  self: z.string(),
});

export const SaleDtoSchema = z.object({
  attributes: SaleDtoAttributesSchema,
  id: z.string(),
  type: z.literal(EntityTypeEnumSchema.Values.sales),
  relationships: SaleDtoRelationshipsSchema,
  links: SaleDtoLinksSchema,
});

export type SaleDto = z.infer<typeof SaleDtoSchema>;
export type SaleDtoAttributes = z.infer<typeof SaleDtoAttributesSchema>;
export type SaleDtoRelationships = z.infer<typeof SaleDtoRelationshipsSchema>;
export type SaleDtoLocale = z.infer<typeof SaleDtoLocale>;
