<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M1.99902 12C1.99902 12 3.81672 4.72681 11.9994 4.72681C20.182 4.72681 21.9997 12 21.9997 12C21.9997 12 20.182 19.2732 11.9994 19.2732C3.81672 19.2732 1.99902 12 1.99902 12Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M22 3L2 21"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M11.9999 15.6366C14.0083 15.6366 15.6365 14.0084 15.6365 12C15.6365 9.99156 14.0083 8.3634 11.9999 8.3634C9.99144 8.3634 8.36328 9.99156 8.36328 12C8.36328 14.0084 9.99144 15.6366 11.9999 15.6366Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
  </svg>
</template>
