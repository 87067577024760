<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 2H14" stroke="#999999" stroke-width="2" />
    <path d="M2 6H14" stroke="#999999" stroke-width="2" />
    <path d="M2 10H14" stroke="#999999" stroke-width="2" />
    <path d="M2 14H14" stroke="#999999" stroke-width="2" />
  </svg>
</template>
