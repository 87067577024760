import { defineStore } from 'pinia';
import { onMounted, computed, ref } from 'vue';
import { ContactDtoAttributes, User } from 'goout-schemas';
import { useAuth } from 'goout-auth';
import { useRouter } from 'vue-router';
import { useLocalStorage } from '@vueuse/core';

export const useSessionStore = defineStore('session', () => {
  const user = ref<User>({} as User);
  const contacts = ref<User['contacts']>([]);
  const selectedContact = ref<ContactDtoAttributes>({} as ContactDtoAttributes);
  const { logout } = useAuth();
  const router = useRouter();
  const userData = useLocalStorage('userData', user);

  const authenticate = async () => {
    // First check local storage for user data

    if (!userData.value && !userData.value[0]) {
      await logout();
      router.push({ name: 'Overview' });
      return;
    }

    user.value = userData.value;
    contacts.value = userData.value.contacts;

    // Select the first contact by default
    selectedContact.value = user.value.contacts[0];
  };

  const contactIds = computed(() =>
    contacts.value?.map((contact) => contact.id)
  );

  onMounted(async () => {
    await authenticate();
  });

  return { authenticate, user, contactIds, selectedContact };
});
