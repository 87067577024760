<script setup lang="ts">
import { ref, watch } from 'vue';
import {
  Listbox,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue';
import { getCurrentLocale } from '~/i18n';
import { LanguageEnum } from 'goout-schemas';
import { computed } from 'vue';
import { useWindowSize } from '@vueuse/core';
import { languageName } from 'goout-utils';

const { locale } = defineProps<{
  locale: LanguageEnum;
}>();

const selectedLocale = ref(locale || getCurrentLocale());
const { width: windowWidth } = useWindowSize();

const optionsRef = ref();

const sortedLocalesSelectedFirst = computed(() => Object.keys(languageName));

// Position the options to the left or right if they are outside the viewport
watch(
  () => optionsRef.value?.el,
  () => {
    if (optionsRef.value && optionsRef.value.el) {
      const {
        left,
        right,
        width: elWidth,
      } = optionsRef.value.el.getBoundingClientRect();

      if (left < 0) {
        optionsRef.value.el.style.left = `${0 + elWidth / 2}px`;
      } else if (right > windowWidth.value) {
        optionsRef.value.el.style.left = `${
          windowWidth.value - right - elWidth / 2
        }px`;
      }
    }
  }
);
</script>
<template>
  <Listbox v-model="selectedLocale" horizontal>
    <div class="relative">
      <ListboxButton
        class="w-8 h-8 flex items-center justify-center border border-gray/24 rounded-full"
      >
        <span class="block truncate text-gray-dark text-sm">{{
          selectedLocale
        }}</span>
      </ListboxButton>

      <transition
        leave-active-class="transition duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <ListboxOptions
          ref="optionsRef"
          class="absolute z-50 w-32 mt-1 max-h-60 w-full overflow-auto bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
        >
          <ListboxOption
            v-for="locale in sortedLocalesSelectedFirst"
            v-slot="{ active, selected }"
            :key="locale"
            :value="locale"
            as="template"
          >
            <li
              class="hover:text-blue"
              :class="[
                active ? 'bg-amber-100 text-amber-900' : 'text-gray-900',
                'relative cursor-pointer select-none py-2 pl-4',
              ]"
            >
              <span
                :class="[
                  selected ? 'font-medium' : 'font-normal',
                  'block truncate',
                ]"
                >{{ languageName[locale] }}</span
              >
              <span
                v-if="selected"
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
              />
            </li>
          </ListboxOption>
        </ListboxOptions>
      </transition>
    </div>
  </Listbox>
</template>
