<template>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    class="stroke"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m19 9-4-4"
      fill="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7 21H3v-4L18 2l4 4L7 21Z"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
