<template>
  <svg
    data-testid="spinner"
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    class="animate-spin"
  >
    <g clip-path="url(#clip0_3672_5039)">
      <path
        d="M12 1V5"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        opacity="0.9"
        d="M19.7782 4.22198L16.9502 7.04998"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        opacity="0.8"
        d="M23 12H19"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        opacity="0.7"
        d="M19.7782 19.778L16.9502 16.95"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        opacity="0.6"
        d="M12 23V19"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        opacity="0.5"
        d="M4.22168 19.778L7.04968 16.95"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        opacity="0.4"
        d="M1 12H5"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
      <path
        opacity="0.3"
        d="M4.22168 4.22198L7.04968 7.04998"
        stroke="currentColor"
        stroke-width="2"
        stroke-miterlimit="10"
        stroke-linecap="square"
      />
    </g>
    <defs>
      <clipPath id="clip0_3672_5039">
        <rect width="24" height="24" fill="currentColor" />
      </clipPath>
    </defs>
  </svg>
</template>
