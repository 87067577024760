<template>
  <svg
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      role="presentation"
      d="M23.5278 9.75037C23.6776 10.5521 23.7598 11.3921 23.7598 12.2702C23.7598 19.1248 19.171 24 12.24 24C5.61003 24 0.23999 18.63 0.23999 12C0.23999 5.37004 5.61003 0 12.24 0C15.4808 0 18.1871 1.19236 20.265 3.12775L16.8817 6.51101V6.50367C15.6218 5.30396 14.0241 4.68869 12.24 4.68869C8.27964 4.68869 5.06231 8.03377 5.06231 11.9941C5.06231 15.953 8.27964 19.3069 12.24 19.3069C15.8318 19.3069 18.2767 17.2511 18.7804 14.4317H12.24V9.75183L23.5278 9.75037Z"
      fill="currentColor"
    />
  </svg>
</template>