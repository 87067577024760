<script lang="ts" setup>
import TextInput from '~/components/general/TextInput.vue';
import { useFormStore } from '~/store/form.store';
import { z } from 'zod';
import { playSchema } from '~/definitions/schemas/metadata/play.schema';
import Datepicker from '../DateTab/Datepicker.vue';
import NumberInput from '~/components/general/NumberInput.vue';

const { metadata_play } = useFormStore();
/**
 * Generates an array of objects containing the key, schema and optional status of each input in the film schema.
 */
const playMetadataInputs = Object.keys(playSchema.shape.values.shape).map(
  (key) => ({
    key,
    schema: playSchema.shape.values.shape[key],
    optional: playSchema.shape.values.shape[key] instanceof z.ZodOptional,
  })
);
</script>

<template>
  <!-- Loop through the zod schema fields for given category and render a TextInput component for each input -->
  <div v-for="input in playMetadataInputs" :key="input.key">
    <Datepicker
      v-if="input.key === 'premiereDate'"
      v-model="metadata_play.data.values.premiereDate"
      class="flex-1 mb-5"
      :label="$t('wizard.tabs.play.premiere')"
    />
    <NumberInput
      v-else-if="input.key === 'length'"
      v-model="metadata_play.data.values[input.key]"
      class="mb-5"
      :schema="input.schema"
      :label="$t(`wizard.tabs.play.${input.key}`)"
      :required="!input.optional"
    />
    <TextInput
      v-else
      v-model="metadata_play.data.values[input.key]"
      class="mb-5"
      :schema="input.schema"
      :label="$t(`wizard.tabs.play.${input.key}`)"
      :required="!input.optional"
    />
  </div>
</template>

<style lang="scss" scoped></style>
