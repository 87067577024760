<script setup lang="ts">
import { useJsonApiClient } from 'goout-api';
import ComboboxSingle, {
  ComboboxSingleItem,
} from '~/components/general/ComboboxSingle.vue';
import TabContainer from '~/components/layout/TabContainer.vue';

import { useFormStore } from '~/store/form.store';
import { ref, ComputedRef, computed } from 'vue';
import { getCurrentLocale } from '~/i18n';

const { venue } = useFormStore();
const locale = getCurrentLocale();
const query = ref('');

const { data, isFetching, execute } = useJsonApiClient({
  entity: 'venues',
  include: ['images', 'cities'],
  query: query.value,
  immediate: false,
  baseUrl: 'entities/v2',
});

const items: ComputedRef<ComboboxSingleItem[]> = computed(() => {
  return data.value.map((venue) => {
    const venueLocales = venue.locales ?? {};
    const cityLocales = venue.city?.locales ?? {};
    const address = venue.address ?? '';
    const cityName = cityLocales[locale]?.name ?? '';
    const countryIso = venue.city?.countryIso?.toUpperCase() ?? '';

    return {
      id: venue.id,
      title: venueLocales[locale]?.name ?? '',
      url: venue.images?.[0]?.url ?? '',
      description: `${address}, ${cityName} ${countryIso}`,
    };
  });
});
</script>

<template>
  <TabContainer
    :title="$t('wizard.tabs.venue.title')"
    :perex="$t('wizard.tabs.venue.perex')"
  >
    <ComboboxSingle
      v-model:selected="venue.data.selectedItem"
      v-model:query="query"
      :items
      :label="$t('venue.input')"
      :placeholder="$t('general.search.venue')"
      :is-fetching="!!isFetching"
      @update:query="execute"
    />
  </TabContainer>
</template>
