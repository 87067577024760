import { z } from 'zod';
import { dateSchema as dateFormatSchema } from '../date.schema';

const time = z.object({
  hour: z.number().min(0).max(23).or(z.literal('')),
  minute: z.number().min(0).max(59).or(z.literal('')),
});

const timeOptional = z.object({
  hour: z.number().min(0).max(23).or(z.literal('')),
  minute: z.number().min(0).max(59).or(z.literal('')),
});

const dateSchema = z.object({
  startDate: dateFormatSchema.or(z.literal('')),
  endDate: dateFormatSchema.or(z.literal('')),
  startTime: time,
  endTime: timeOptional,
});

export type DateSchema = z.infer<typeof dateSchema>;

export const datesSchema = z.object({
  dates: z
    .array(dateSchema)
    .refine((dates) => {
      // this is true if dates are valid or empty, the empty are removed by the filter
      const dateIsComplete = dates.some((date) => {
        return checkCompleteDate(date);
      });

      return dateIsComplete;
    })
    .transform((dates) => dates.filter((date) => date.startDate !== '')),
});

function checkCompleteDate(date: DateSchema) {
  return (
    date.startDate !== '' &&
    date.endDate !== '' &&
    date.startTime.hour !== '' &&
    date.startTime.minute !== ''
  );
}

export function checkIncompleteDate(date: DateSchema) {
  return (
    (date.startDate !== '' &&
      (date.endDate === '' ||
        date.startTime.hour === '' ||
        date.startTime.minute === '')) ||
    (date.startDate === '' &&
      (date.endDate !== '' ||
        date.startTime.hour !== '' ||
        date.startTime.minute !== ''))
  );
}
