<script lang="ts" setup>
import TabContainer from '~/components/layout/TabContainer.vue';
import ImageDropZone from './ImageDropZone.vue';
import ImageHelperText from './ImageHelperText.vue';
import ImagePreview from './ImagePreview.vue';
import { useFormStore } from '~/store/form.store';
import { computed, onMounted, onUnmounted } from 'vue';

const formStore = useFormStore();

const images = computed(() => Object.values(formStore.image.data.images));

function abortAllImageUploads() {
  formStore.image.data.images.forEach((image) => {
    if (image.controller) {
      image.controller.abort();
    }
  });
}
onMounted(() => {
  window.addEventListener('beforeunload', abortAllImageUploads);
});

onUnmounted(() => {
  window.removeEventListener('beforeunload', abortAllImageUploads);
});
</script>

<template>
  <TabContainer
    :title="$t('wizard.tabs.image.title')"
    :perex="$t('wizard.tabs.image.perex')"
  >
    <div class="grid grid-cols-3 gap-2 relative mb-6">
      <ImagePreview
        v-for="(image, idx) in images"
        :key="idx"
        class="relative"
        :image="image"
      />
    </div>
    <ImageDropZone />
    <ImageHelperText class="mb-22 mt-6" />
  </TabContainer>
</template>

<style lang="scss" scoped></style>
