<script lang="ts" setup>
import Modal from '~/components/general/Modal.vue';
import { computed, ref, onActivated, onDeactivated } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import EventBox from '~/components/events/EventBox.vue';
import { Schedule } from 'goout-schemas';
import { useDataStore } from '~/store/data.store';
import { ModalRoute, useModal } from '~/composables/useModal';
import { sendResponse } from '~/composables/useForm';
import { ENDPOINT } from '~/definitions/constants/endpoints';
import { useGooutFetch } from 'goout-api';
import { useEventBus } from '@vueuse/core';

const route = useRoute();
const router = useRouter();
const dataStore = useDataStore();
const open = ref<boolean>(true);
const { callback } = useModal();
const bus = useEventBus('refetch-schedules');

const handleSubmit = () => {
  callback.value = () => {
    return submit();
  };
  router.push({ name: ModalRoute.ConfirmModalDelete });
};

const handleClose = () => {
  open.value = false;
  router.push({ name: 'Overview' });
};

const submit = async () => {
  const { statusCode } = await useGooutFetch(
    ENDPOINT.EVENTS + `/${route.params.id}`
  ).delete();
  const response = sendResponse(statusCode);
  bus.emit('refetch-schedules');
  return response;
};

const schedule = computed<Schedule | undefined>(() =>
  dataStore.getScheduleById(route.params.id as string)
);

onActivated(() => {
  open.value = true;
});

onDeactivated(() => {
  open.value = false;
});
</script>

<template>
  <Modal
    :open
    class="px-10"
    rounded="3xl"
    :on-close="handleClose"
    :divider="false"
    @submit="handleSubmit"
  >
    <template #title>
      {{ $t('event.delete.title') }}
    </template>

    <template #description>
      {{ $t('event.delete.description') }}
    </template>

    <template #content>
      <EventBox
        v-if="schedule"
        :id="schedule.event.id"
        :date="schedule.startAt"
        :event-image-url="schedule.event.images.at(0)?.url"
        :locales="schedule.event.locales"
        :venue="schedule.venue"
        :scheduled-date="schedule.publishedAt"
        compact
      />
    </template>

    <template #close>
      <span>{{ $t('btn.close') }}</span>
    </template>

    <template #confirm>
      <span>{{ $t('btn.delete') }}</span>
    </template>
  </Modal>
</template>
