<script setup lang="ts">
import { z } from 'zod';
import { useForm } from '~/composables/useForm';
import InputLocaleSwitcher from '~/components/general/InputLocaleSwitcher.vue';
import { LanguageEnum } from 'goout-schemas';
import { ref } from 'vue';
import TextInput from '~/components/general/TextInput.vue';
import { LocalizedInput } from '~/types/form.types';
import { FormSchemaArgs } from '../../composables/useForm';

type T = LocalizedInput;

export type TextInputProps = {
  label: string;
  schema: z.ZodSchema<unknown>;
} & Pick<FormSchemaArgs<T>, 'debounceTime'>;

const locale = ref<LanguageEnum>('cs');

const { schema, debounceTime } = defineProps<TextInputProps>();

const model = defineModel<LocalizedInput>({
  default: {
    en: '',
    cs: '',
    de: '',
    pl: '',
    sk: '',
    uk: '',
  },
});

const { errors } = useForm({
  model,
  schema,
  debounceTime,
});

const handleLocaleUpdate = (value: LanguageEnum) => {
  locale.value = value;
};
</script>
<template>
  <div class="relative">
    <TextInput v-model="model[locale]" :label :schema :errors />

    <div class="absolute top-1/4 right-4 origin-center">
      <InputLocaleSwitcher :locale @update:model-value="handleLocaleUpdate" />
    </div>
  </div>
</template>
