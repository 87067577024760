<script setup lang="ts">
import Button from 'goout-ui-kit/button/Button.vue';
import TabComponent from './Tab.vue';

export interface Tab {
  id: string;
  title: string;
  component: InstanceType<any>;
  isValid: boolean;
  isVisited: boolean;
  order: number;
}

interface Props {
  tabs: Tab[];
  currentTab: Tab['id'];
  isIntro?: boolean;
  isLastTab?: boolean;
  nextButtonDisabled: boolean;
}

withDefaults(defineProps<Props>(), {
  isLastTab: false,
});
const emit = defineEmits<{
  (e: 'onBackButton'): void;
  (e: 'onNextButton'): void;
  (e: 'onTabClick', tab: Tab): void;
  (e: 'publish'): void;
}>();

</script>

<template>
  <footer class="fixed bottom-0 w-full">
    <ul v-if="!isIntro" class="flex w-full" role="tablist">
      <TabComponent
        v-for="(tab) in tabs"
        :key="tab.id"
        :tab="tab"
        :is-current="currentTab === tab.id"
        :is-visited="tab.isVisited"
        :is-valid="tab.isValid"
        @click="emit('onTabClick', tab)"
      />
    </ul>
    <div class="bg-white h-16 flex justify-between">
      <Button
        variant="text-dark"
        size="lg"
        class="!px-8 !justify-start back-button !min-w-min"
        icon="Chevron"
        icon-class="text-gray transform rotate-90 !h-2.5 !w-2.5 mr-4"
        @click="emit('onBackButton')"
      >
        <slot name="backButton">Back</slot>
      </Button>

      <div class="flex">
        <slot name="additionalButton" />

        <Button
          :variant="isLastTab ? 'primary' : 'primary-dark'"
          :disabled="nextButtonDisabled"
          size="lg"
          class="w-45"
          @click="isLastTab ? emit('publish') : emit('onNextButton')"
        >
          <template v-if="isIntro">
            <slot name="continueButton">Continue</slot>
          </template>

          <template v-else-if="isLastTab">
            <slot name="publishButton">Publish now</slot>
          </template>

          <template v-else><slot name="nextButton">Next</slot></template>
        </Button>
      </div>
    </div>
  </footer>
</template>

<style lang="scss">
.back-button:hover {
  svg {
    @apply text-blue;
  }
}
</style>
