import Avatar from './src/Avatar.vue';
import Bold from './src/Bold.vue';
import Clock from './src/Clock.vue';
import Close from './src/Close.vue';
import Edit from './src/Edit.vue';
import Error from './src/Error.vue';
import Eye from './src/Eye.vue';
import EyeClosed from './src/EyeClosed.vue';
import Globe from './src/Globe.vue';
import GoOut from './src/GoOut.vue';
import GoOutMobile from './src/GoOutMobile.vue';
import H1 from './src/H1.vue';
import H2 from './src/H2.vue';
import H3 from './src/H3.vue';
import Check from './src/Check.vue';
import Chevron from './src/Chevron.vue';
import Images from './src/Images.vue';
import Institution from './src/Institution.vue';
import Italic from './src/Italic.vue';
import Language from './src/Language.vue';
import ListOrdered from './src/ListOrdered.vue';
import ListUnordered from './src/ListUnordered.vue';
import Logo from './src/Logo.vue';
import Menu from './src/Menu.vue';
import Pin from './src/Pin.vue';
import Plus from './src/Plus.vue';
import Search from './src/Search.vue';
import Spinner from './src/Spinner.vue';
import Underline from './src/Underline.vue';
import Warning from './src/Warning.vue';
import Edit2 from './src/Edit2.vue';
import Export from './src/Export.vue';
import Tickets from './src/Tickets.vue';
import CheckRounded from './src/CheckRounded.vue';
import Google from './src/Google.vue';
import GoogleColor from './src/GoogleColor.vue';
import Facebook from './src/Facebook.vue';
import MenuNew from './src/MenuNew.vue';
import Dots from './src/Dots.vue';
import Movie from './src/Movie.vue';
import Info from './src/Info.vue';
import Reorder from './src/Reorder.vue';
import FacebookColor from './src/FacebookColor.vue';
import QuestionMark from './src/QuestionMark.vue';
import User from './src/User.vue';

export default {
  Avatar,
  Bold,
  Clock,
  Close,
  Edit,
  Error,
  Eye,
  EyeClosed,
  FacebookColor,
  Globe,
  GoogleColor,
  GoOut,
  H1,
  H2,
  H3,
  Check,
  Chevron,
  Images,
  Institution,
  Italic,
  Language,
  ListOrdered,
  ListUnordered,
  Logo,
  Menu,
  Pin,
  Plus,
  Search,
  Spinner,
  Underline,
  User,
  Warning,
  Edit2,
  Export,
  Tickets,
  CheckRounded,
  Google,
  Facebook,
  GoOutMobile,
  MenuNew,
  Dots,
  Movie,
  Info,
  Reorder,
  QuestionMark,
};
