<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 14 14"
    fill="none"
    class="stroke"
  >
    <path
      d="M11.4625 0.2625C11.1125 -0.0875 10.5875 -0.0875 10.2375 0.2625L0 10.5V14H3.5L13.7375 3.7625C14.0875 3.4125 14.0875 2.8875 13.7375 2.5375L11.4625 0.2625Z"
      fill="currentColor"
    />
  </svg>
</template>
