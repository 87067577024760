import { eventCategories } from 'goout-data';
import { z } from 'zod';

const categoryEnumSchema = z.enum(Object.values(eventCategories) as [string]);
export const categorySchema = z
  .object({
    selectedCategories: z.array(categoryEnumSchema).min(1),
    primaryCategory: categoryEnumSchema,
    isConfirmed: z.boolean(),
    tags: z.array(z.string()).optional(),
  })
  .refine((data) => data.isConfirmed === true, {
    message: 'User must confirm the category selection.',
  });
