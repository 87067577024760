import * as z from 'zod';
import {
  RelationshipToMany,
  RelationshipToOne,
} from '../jsonApi/jsonApiRelationships.schema';
const DiscountKind = z.enum(['deal', 'promo', 'promo_unique', 'promo_emails']);
const DiscountMethod = z.enum(['absolute', 'relative', 'target']);
const DiscountDtoLocale = z.object({ name: z.string() });

export const DiscountDtoAttributesSchema = z.object({
  startAt: z.string(),
  endAt: z.string(),
  kind: DiscountKind,
  method: DiscountMethod,
  valueCents: z.number().int(),
  isHidden: z.boolean(),
  isHiddenDesk: z.boolean(),
  locales: DiscountDtoLocale,
});

export const DiscountDtoRelationshipsSchema = z.object({
  sale: RelationshipToOne('sales'),
  contact: RelationshipToOne('users'),
  dealsAllowed: RelationshipToMany('deals'),
});

export const DiscountDtoSchema = z.object({
  attributes: DiscountDtoAttributesSchema,
  id: z.string(),
  type: z.literal('discounts'),
  relationships: DiscountDtoRelationshipsSchema,
});
