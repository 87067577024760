<script setup lang="ts" generic="T extends ComboboxSingleItem">
import {
  Combobox,
  ComboboxInput,
  ComboboxOptions,
  ComboboxOption,
  TransitionRoot,
} from '@headlessui/vue';
import Spinner from './Spinner.vue';
import { debounce } from '~/utils/debounce.util';
import { computed, ref } from 'vue';

export type ComboboxSingleItem = {
  id: string;
  title: string;
  url?: string;
  description?: string;
};

interface Props {
  items: T[];
  isFetching: boolean;
  placeholder?: string;
  label?: string;
}

const { items, isFetching } = defineProps<Props>();

const DEBOUNCE_TIME = 300;
const query = defineModel<string>('query');
const selected = defineModel<T>('selected');
const isFocused = ref(false);

const handleInputChange = debounce((event: Event) => {
  query.value = (event.target as HTMLInputElement).value;
}, DEBOUNCE_TIME);

function displayValue(item: ComboboxSingleItem) {
  return item ? item.title : '';
}

const notFound = computed(() => {
  return items.filter((item) => item.id !== selected.value?.id).length;
});
</script>

<template>
  <div>
    <Combobox v-model="selected" autocomplete="off" nullable>
      <div class="relative">
        <div class="relative w-full h-17 cursor-default flex">
          <ComboboxInput
            class="h-full bg-snow-white outline-none text-dark !placeholder-gray-dark font-medium w-full px-4 py-4 transition-all focus:shadow-border focus:shadow-blue focus:bg-gray-50"
            :class="{ 'pt-8': selected }"
            autocomplete="off"
            :placeholder="selected ? '' : placeholder"
            :display-value="(item) => displayValue(item as ComboboxSingleItem)"
            @change="handleInputChange($event)"
            @focus="isFocused = true"
            @blur="isFocused = false"
          />
          <span
            v-if="selected && label"
            class="text-gray transform transition-all top-1/2 -translate-y-1/2 left-4 absolute pointer-events-none"
            :class="{
              'has-value': isFocused || Object.keys(selected).length > 0,
            }"
          >
            {{ $t(label) }}
          </span>
        </div>
        <TransitionRoot @after-leave="query = ''">
          <div
            class="bg-white shadow-default mt-3 min-w-72 w-full pl-6 z-10 absolute"
          >
            <ComboboxOptions
              class="bg-white w-full min-w-72 min-h-14 max-h-72 overflow-auto flex flex-col"
            >
              <div
                v-if="!notFound && !isFetching && query"
                class="relative cursor-default select-none px-4 py-2 text-gray-700"
              >
                {{ $t('category.tags.noMatches') }}
              </div>
              <Spinner
                v-if="isFetching"
                class="flex self-center align-middle mt-2 text-blue"
              />
              <div v-else class="flex flex-col">
                <div v-for="(item, idx) in items" :key="item.id">
                  <ComboboxOption v-slot="{ active, disabled }" :value="item">
                    <div
                      class="flex py-3 gap-x-3 relative select-none cursor-pointer items-center transition-colors"
                      :class="{
                        'text-blue': active,
                        'text-gray-900': !active,
                        'opacity-40 cursor-default': disabled,
                      }"
                    >
                      <img
                        v-if="item.url"
                        :src="item.url"
                        class="w-12 h-12 rounded-full object-cover bg-cover mr-2"
                        :alt="item.title"
                        :lazy="idx > 5"
                      />
                      <div
                        v-else
                        class="w-12 h-12 rounded-full object-cover bg-cover mr-2 bg-gray-light"
                      ></div>

                      <div class="flex flex-col justify-start">
                        <h4 class="text-sm font-medium">
                          {{ item.title }}
                        </h4>
                        <p
                          v-if="item.description"
                          class="text-gray-lighter text-sm font-medium"
                        >
                          {{ item.description }}
                        </p>
                      </div>
                    </div>
                  </ComboboxOption>
                </div>
              </div>
            </ComboboxOptions>
          </div>
        </TransitionRoot>
      </div>
    </Combobox>
  </div>
</template>

<style lang="scss" scoped>
.has-value {
  transform: translateY(-110%);
  font-size: 11px;
}

label:focus-within > .text-gray {
  transform: translateY(-110%);
  font-size: 11px;
}
</style>
