import { useGooutFetch } from 'goout-api';
import { EntityTypeEnumSchema, StateEnumSchema } from 'goout-schemas';
import { Ref, computed } from 'vue';
import { USERS } from './endpoints';

/**
 * Create a new user
 * @param email
 */
export function useSignUp(email: Ref<string>) {
  const data = computed(() => ({
    attributes: {
      email: email.value,
      state: StateEnumSchema.enum.UNAPPROVED,
      isPublic: false,
    },
    relationships: {
      contacts: {
        data: [],
      },
      guestlists: {
        data: [],
      },
    },
    type: EntityTypeEnumSchema.enum.users,
    links: {
      self: '',
    },
    id: '',
  }));

  return useGooutFetch(USERS + '?sendInvite=false', { immediate: false }).post(
    data
  );
}
