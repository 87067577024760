<script lang="ts" setup>
const imagePath = 'https://static.goout.net/common/categories/event/';

interface Props {
  category: string;
  size?: number;
}

const { size = 18 } = defineProps<Props>();

const sizeClass = `h-${size} min-w-${size}`;
</script>

<template>
  <div
    class="relative rounded-full pointer-events-none min-w-18"
    v-bind="$attrs"
  >
    <img
      class="rounded-full"
      :class="sizeClass"
      :src="`${imagePath}${category}.png`"
      :alt="category"
    />
  </div>
</template>

<style lang="scss" scoped></style>
