<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_484_2365)">
            <path d="M23 5H1V23H23V5Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
            <path d="M6 1H18" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
            <path d="M6 19L15 11L19 19H6Z" stroke="currentColor" stroke-width="2" stroke-miterlimit="10"
                stroke-linecap="square" />
            <path
                d="M7 12C8.10457 12 9 11.1046 9 10C9 8.89543 8.10457 8 7 8C5.89543 8 5 8.89543 5 10C5 11.1046 5.89543 12 7 12Z"
                fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_484_2365">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>