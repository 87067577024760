import { ref } from 'vue';
import { SubmitResponse } from './useForm';

export const ModalRoute = {
  EventEditModal: 'EventEditModal',
  EventDeleteModal: 'EventDeleteModal',
  ConfirmModalEdit: 'ConfirmModalEdit',
  ConfirmModalDelete: 'ConfirmModalDelete',
  ConfirmModalSell: 'ConfirmModalSell',
  SuccessModalSell: 'SuccessModalSell',
  ErrorModalSell: 'ErrorModalSell',
  SuccessModalEdit: 'SuccessModalEdit',
  SuccessModalDelete: 'SuccessModalDelete',
  ErrorModalEdit: 'ErrorModalEdit',
  ErrorModalDelete: 'ErrorModalDelete',
  EventSellModal: 'EventSellModal',
} as const;

export type ModalRoute = typeof ModalRoute[keyof typeof ModalRoute];

const callback = ref<() => Promise<Partial<SubmitResponse> | undefined>>();
export function useModal() {
  return {
    callback,
  };
}
