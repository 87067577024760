import { z } from 'zod';
import { ContactSchema } from '../contact/contact.schema';
import { UserDtoAttributesSchema, UserDtoRelationshipsSchema } from './user.schema';

export const UserStateSchema = z.object({
  authenticated: z.boolean(),
  userData: UserDtoAttributesSchema.merge(UserDtoRelationshipsSchema),
  contacts: z.array(ContactSchema),
});

export type UserState = z.infer<typeof UserStateSchema>;
