import { ComboboxSingleItem } from '~/components/general/ComboboxSingle.vue';
import { LocalizedInput } from '~/types/form.types';
import { EventCategory, Tag } from 'goout-data';
const initialGeneralState = () => ({
  isValid: false,
  isVisited: false,
  data: {
    name: createLocaleStructure(),
    description: createLocaleStructure(),
    web: '',
  },
});

const initialCategoryState = () => ({
  isValid: false,
  isVisited: false,
  data: {
    selectedCategories: [] as EventCategory[],
    primaryCategory: '' as EventCategory,
    isConfirmed: false,
    tags: [] as Tag[],
  },
});

const initialVenueState = () => ({
  isValid: false,
  isVisited: false,
  data: {
    selectedItem: {} as ComboboxSingleItem,
  },
});

const initialPerformerState = () => ({
  isValid: false,
  isVisited: false,
  data: {
    selectedItems: [] as ComboboxSingleItem[],
  },
});

const initialImageState = () => ({
  isValid: false,
  isVisited: false,
  data: {
    images: [],
  },
});

const initialMetadataFilmState = () => ({
  isValid: false,
  isVisited: false,
  data: {
    selectedItem: {} as ComboboxSingleItem,
    values: {
      originalName: '',
      countryIsos: [],
      minorPerformers: [],
      director: '',
      released: '',
      length: '',
      tags: [],
    },
  },
});

const initialMetadataPlayState = () => ({
  isValid: false,
  isVisited: false,
  data: {
    values: {
      director: '',
      premiereDate: '',
      name: '',
      actors: '',
      author: '',
      length: '' as unknown as number,
    },
  },
});

const initialDateState = () => ({
  isValid: false,
  isVisited: false,
  data: {
    dates: [
      {
        endDate: '',
        startDate: '',
        endTime: {
          hour: '' as unknown as number,
          minute: '' as unknown as number,
        },
        startTime: {
          hour: '' as unknown as number,
          minute: '' as unknown as number,
        },
      },
    ],
  },
});

const initialOrganizerState = () => ({
  isValid: false,
  isVisited: false,
  data: {
    selectedItems: [] as ComboboxSingleItem[],
  },
});

const initialPublishState = () => ({
  isValid: false,
  isVisited: false,
  data: {
    test: true,
  },
});

export {
  initialGeneralState,
  initialCategoryState,
  initialVenueState,
  initialPerformerState,
  initialImageState,
  initialMetadataFilmState,
  initialMetadataPlayState,
  initialDateState,
  initialOrganizerState,
  initialPublishState,
};

/**
 * Used to create a structure for multi-language fields
 * @returns { [languageIso in LanguageNameType]: string }
 */
function createLocaleStructure(): LocalizedInput {
  return {
    en: '',
    cs: '',
    de: '',
    pl: '',
    sk: '',
    uk: '',
  };
}
