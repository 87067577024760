<template>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    class="stroke"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.55 11.09v5.15m2.485-5.15v5.15m7.417-5.15v5.15m2.487-5.15v5.15M3.745 17.734h16v2.404h-16v-2.404Zm15.999-8.17h-16v-1.11l8.077-4.316 7.923 4.315v1.111Z"
      stroke="currentColor"
    />
  </svg>
</template>
