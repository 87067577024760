<script setup lang="ts">
import { useModalStore } from '../stores/modal.store';
import Button from 'goout-ui-kit/button/Button.vue';
import { ModalTypeEnum } from '../utils/modalTypes';

const { switchForms } = useModalStore();
</script>

<template>
  <div class="flex flex-col items-center w-full">
    <div class="bg-snow-white h-[1px] w-full mb-6"></div>
    <Button
      class="w-full"
      variant="border"
      round
      @click="switchForms(ModalTypeEnum.signIn)"
    >
      {{ $t('loginModule.ok') }}
    </Button>
  </div>
</template>
