import { z } from 'zod';
import { StateEnumSchema } from '../state.schema';
import { TagEnumSchema } from '../tag.schema';
import {
  RelationshipToMany,
  RelationshipToOne,
} from '../jsonApi/jsonApiRelationships.schema';
import { EntityTypeEnumSchema } from '../entityType.schema';
import { ImageSchema } from '../image/image.schema';
import { VideoSchema } from '../video/video.schema';
import { LanguageEnumSchema } from '../language.schema';
import { Prettify } from '../jsonApi';

const PerformerCounts = z
  .object({ events: z.number().int(), followers: z.number().int() })
  .partial()
  .passthrough();

const PerformerDtoLocale = z.object({
  name: z.string(),
  description: z.string(),
  descriptionHtml: z.string(),
  metaDescription: z.string().nullish(),
  metaTitle: z.string().nullish(),
  siteUrl: z.string().nullish(),
});

export const PerformerDtoAttributesSchema = z.object({
  state: StateEnumSchema,
  mainCategory: TagEnumSchema,
  categories: z.array(TagEnumSchema),
  tags: z.array(TagEnumSchema),
  innerTags: z.array(z.string()).nullish(),
  countriesIso: z.array(z.string()),
  keywords: z.array(z.string()),
  updatedAt: z.string(),
  siteUrl: z.string().nullish(),
  sourceUrl: z.string().nullish(),
  facebookUrl: z.string().nullish(),
  soundCloudUrl: z.string().nullish(),
  lastFmUrl: z.string().nullish(),
  instagramUrl: z.string().nullish(),
  spotifyUrl: z.string().nullish(),
  youtubeUrl: z.string().nullish(),
  rateYourMusicUrl: z.string().nullish(),
  counts: PerformerCounts,
  locales: z.record(LanguageEnumSchema, PerformerDtoLocale).nullish(),
});

export const PerformerDtoRelationshipsSchema = z.object({
  images: RelationshipToMany(EntityTypeEnumSchema.Enum.images),
  videos: RelationshipToMany(EntityTypeEnumSchema.Enum.videos),
});

export const PerformerDtoSchema = z.object({
  attributes: PerformerDtoAttributesSchema,
  id: z.string(),
  type: z.literal(EntityTypeEnumSchema.Values.performers),
  relationships: PerformerDtoRelationshipsSchema,
});

export const PerformerSchema = z
  .object({
    id: z.string(),
    type: z.literal(EntityTypeEnumSchema.Values.performers),
  })
  .merge(PerformerDtoAttributesSchema)
  .merge(
    z.object({
      images: z.array(ImageSchema),
      videos: z.array(VideoSchema),
      revisionParent: z.lazy(() => z.union([PerformerSchema, z.null()])),
      revisions: z.lazy(() => z.array(PerformerSchema)),
    })
  )
  .partial();

export type PerformerDto = z.infer<typeof PerformerDtoSchema>;
export type PerformerDtoAttributes = z.infer<
  typeof PerformerDtoAttributesSchema
>;
export type PerformerDtoRelationships = z.infer<
  typeof PerformerDtoRelationshipsSchema
>;

export type Performer = Prettify<
  {
    type: typeof EntityTypeEnumSchema.Values.performers;
    id: string;
  } & PerformerDtoAttributes &
    PerformerDtoRelationships
>;
