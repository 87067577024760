import { ref } from 'vue';
import { ModalTypeEnum } from '../utils/modalTypes';

const currentModal = ref<ModalTypeEnum>(ModalTypeEnum.signIn);
const isRemovable = ref(false);
const isOpen = ref(true);
export function useModalStore() {
  function switchForms(modalType: ModalTypeEnum) {
    currentModal.value = modalType;
  }

  return {
    currentModal,
    switchForms,
    isRemovable,
    isOpen,
  };
}
