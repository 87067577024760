<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 9 7"
    fill="none"
    class="stroke"
  >
    <path d="m1 3 2.5 2.5L8 1" stroke="currentColor" />
  </svg>
</template>
