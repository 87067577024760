import z from 'zod';
import { LanguageEnumSchema } from '../language.schema';
import { EntityTypeEnumSchema } from 'goout-schemas';
const VideoSource = z.enum(['youtube', 'soundcloud']);

const VideoDtoLocale = z.object({
  hidden: z.boolean(),
});

const VideoDtoAttributesSchema = z.object({
  name: z.string().nullish(),
  source: VideoSource.nullish(),
  videoId: z.string().nullish(),
  durationSeconds: z.number().int().nullish(),
  views: z.number().int().nullish(),
  locales: z.record(LanguageEnumSchema, VideoDtoLocale).nullish(),
});


export const VideoDtoSchema = z.object({
  attributes: VideoDtoAttributesSchema,
  id: z.string(),
  type: z.literal(EntityTypeEnumSchema.Values.videos),
});

export const VideoSchema = VideoDtoAttributesSchema;

export type VideoDto = z.infer<typeof VideoDtoSchema>;
export type VideoDtoAttributes = z.infer<typeof VideoDtoAttributesSchema>;
