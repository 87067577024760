<script lang="ts" setup>
import { format, parseISO } from 'date-fns';
import { DateSchema } from '~/definitions/schemas/date/date.schema';
import { Image } from '~/definitions/schemas/image/image.schema';
import { cs, de, pl, enGB, sk } from 'date-fns/locale';
import { VenueTabState } from '~/types/form.types';
import { computed } from 'vue';
import ThumbnailSkeleton from './ThumbnailSkeleton.vue';
import { getCurrentLocale } from '~/i18n';

const { date, name, image, primaryCategory, venue } = defineProps<{
  name: string;
  image: Image;
  primaryCategory: string | null;
  venue: VenueTabState['selectedItem'];
  date: DateSchema;
}>();

const locales = {
  en: enGB,
  cs,
  de,
  pl,
  sk,
};

const currentLocale = getCurrentLocale();

const dateFormatted = computed(() => {
  const locale = locales[currentLocale];
  const parsedDate = parseISO(date.startDate);

  const formattedDate = format(parsedDate, 'd. MMMM', { locale });

  const hour = date.startTime?.hour?.toString().padStart(2, '0') ?? '00';
  const minute = date.startTime?.minute?.toString().padStart(2, '0') ?? '00';

  return `${formattedDate} ${hour}:${minute}`;
});
</script>

<template>
  <div class="flex flex-col w-64">
    <div class="shadow-md">
      <img
        v-if="image?.url"
        :src="image?.url"
        loading="eager"
        alt="event image"
        class="object-cover max-h-[160px] w-full"
      />
      <ThumbnailSkeleton v-else class="object-cover min-h-[160px] w-64" />
      <div class="flex flex-col p-8">
        <h3 class="text-lg font-medium text-dark max-w-xs break-words">
          {{ name }}
        </h3>
        <p class="text-sm text-gray capitalize my-1 font-medium">
          {{ $t(`general.tags.${primaryCategory}`) }}, {{ dateFormatted }}
        </p>
        <p class="text-sm text-gray font-medium leading-6">
          {{ venue?.title }}
        </p>
      </div>
    </div>
  </div>
</template>
