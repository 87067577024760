import * as t from 'io-ts';
import { UserIO } from '../entities/User';
import { optional } from '../common';

export const UserCollectionIO = t.interface({
  included: t.interface({}),
  meta: t.interface({
    nextScrollId: optional(t.string),
  }),
  users: t.array(UserIO),
});

export type UserCollectionResponse = t.TypeOf<typeof UserCollectionIO>;
