import { apiCall } from './fetch';
import { LOGOUT } from './endpoints';
import { useCookie } from 'goout-utils/cookie';

export async function logout(reload = true) {
  try {
    await apiCall(LOGOUT, {}, 'POST');
  } catch (error) {
    console.error('Failed to logout', error);
  } finally {
    useCookie().remove('accessTokenIAt');
    useCookie().remove('accessToken');
    useCookie().remove('goout-auth.nonce');
    useCookie().remove('goout-auth.strategy');
    window.localStorage.removeItem('userData');
    const url = new URL(window.location.toString());
    window.history.replaceState({}, '', url);

    // Get the current URL
    let currentUrl = new URL(window.location.href);

    // Remove the query parameters and hash
    currentUrl.search = '';
    currentUrl.hash = '';
    // Update the window location without reloading the page
    window.history.replaceState({}, document.title, currentUrl.toString());
    
    if (reload) window.location.reload();
  }
}
