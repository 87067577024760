<script setup lang="ts">
import { useEditor, EditorContent, Content } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Typography from '@tiptap/extension-typography';
import Link from '@tiptap/extension-link';
import CharacterCount from '@tiptap/extension-character-count';
import EditorTool from './EditorTool.vue';
import { computed, ref, watch, nextTick } from 'vue';

/**
 * Tiptap WYSIWYG editor: https://tiptap.dev/
 */

interface Props {
  label?: string;
  tooltips?: Record<string, string>;
  locale?: string;
  limit?: number;
  modelValue: string;
}
const props = withDefaults(defineProps<Props>(), {
  label: '',
  content: '',
  locale: 'en',
  limit: 0,
});

const emit = defineEmits(['update:modelValue']);
const content = computed({
  get: () => props.modelValue,
  set: (value: string) => {
    if (value !== props.modelValue) {
      emit('update:modelValue', value);
    }
  },
});
const onType = ref(false);
const hasContent = computed(() => onType.value || content.value);

const editor = useEditor({
  extensions: [
    StarterKit,
    Underline,
    Typography,
    Link,
    CharacterCount.configure({
      limit: props.limit,
    }),
  ],
  editorProps: {
    attributes: {
      class:
        'prose pr-16 leading-snug max-w-none prose-sm bg-snow-white outline-none text-dark w-full sm:min-h-48 px-4 pt-20 min-h-56 sm:pt-13 pb-4 transition-all hover:bg-gray/20 hover:disabled:bg-snow-white focus:shadow-border focus:shadow-blue !focus:bg-gray/16 focus:diabled:bg-snow-white',
    },
  },
  content: content.value,
  // Listen to changes and emit them to the parent
  onUpdate: ({ editor }) => {
    nextTick(() => {
      const isEmpty = editor.state.doc.textContent.length === 0;
      onType.value = isEmpty ? false : true;

      if (isEmpty) {
        emit("update:modelValue", '');
      } else {
        const html = editor.getHTML();
        emit("update:modelValue", html);
      }
    });
  },
});

// Set new content on modelValue change
watch(() => props.modelValue, (newValue) => {
  if (newValue !== editor.value?.getHTML()) {
    editor.value?.commands.setContent(newValue as Content);
  }
});

</script>

<template>
  <div class="relative">
    <div
      class="border-b border-gray/10 flex flex-col bottom-auto py-1.5 px-2 inset-0 z-10 overflow-x-hidden absolute justify-between sm:flex-row sm:items-center"
      w:sm="flex-row items-center overflow-x-visible"
    >
      <div
        v-if="editor"
        class="flex sm:pb-1 sm:gap-x-1.5 overflow-x-auto"
        w:sm="pb-0 mx-0 overflow-x-visible"
      >
        <EditorTool
          name="Bold"
          :tooltip="tooltips?.['Bold']"
          @click="editor?.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        />

        <EditorTool
          name="Italic"
          :tooltip="tooltips?.['Italic']"
          @click="editor?.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        />

        <EditorTool
          name="Underline"
          :tooltip="tooltips?.['Underline']"
          @click="editor?.chain().focus().toggleUnderline().run()"
          :disabled="!editor.can().chain().focus().toggleUnderline().run()"
          :class="{ 'is-active': editor.isActive('underline') }"
        />

        <EditorTool
          name="H1"
          :tooltip="tooltips?.['H1']"
          @click="editor?.chain().focus().toggleHeading({ level: 1 }).run()"
          :disabled="
            !editor?.can().chain().focus().toggleHeading({ level: 1 }).run()
          "
          :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
        />

        <EditorTool
          name="H2"
          :tooltip="tooltips?.['H2']"
          @click="editor?.chain().focus().toggleHeading({ level: 2 }).run()"
          :disabled="
            !editor?.can().chain().focus().toggleHeading({ level: 2 }).run()
          "
          :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
        />

        <EditorTool
          name="H3"
          :tooltip="tooltips?.['H3']"
          @click="editor?.chain().focus().toggleHeading({ level: 3 }).run()"
          :disabled="
            !editor.can().chain().focus().toggleHeading({ level: 3 }).run()
          "
          :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
        />

        <EditorTool
          name="ListUnordered"
          :tooltip="tooltips?.['ListUnordered']"
          @click="editor?.chain().focus().toggleBulletList().run()"
          :disabled="!editor.can().chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        />

        <EditorTool
          name="ListOrdered"
          :tooltip="tooltips?.['ListOrdered']"
          @click="editor?.chain().focus().toggleOrderedList().run()"
          :disabled="!editor.can().chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        />
      </div>
    </div>
    <p
      v-if="editor"
      class="z-10 text-gray transform top-13 left-4 absolute pointer-events-none text-sm"
      :class="[
        hasContent && 'hidden',
        $attrs.disabled === '' && 'text-gray/30',
      ]"
    >
      {{ label }}
    </p>

    <EditorContent :editor="editor" />

    <!-- Character limit display -->
    <div class="right-0 absolute">
      <p
        v-if="editor && limit"
        class="bg-snow-white rounded-md text-xs text-gray py-2 px-3 inline-flex mt-2"
        :class="[
          editor.storage.characterCount.characters() >= limit - 10 &&
            'text-yellow',
          editor.storage.characterCount.characters() >= limit && '!text-red',
        ]"
      >
        {{ editor.storage.characterCount.characters() }}/{{ limit }}
      </p>
    </div>
  </div>
</template>

<!-- Not scoped to propagate to EditorContent -->
<style lang="scss">
.prose strong {
  @apply font-bold;
}

.prose h1 {
  @apply text-xl font-medium mb-6;
}

.prose h2 {
  @apply text-lg font-medium mb-4;
}

.prose h3 {
  @apply text-base font-medium mb-2;
}

.prose ul > li::before {
  @apply bg-dark;
}

.prose ol {
  @apply pl-0;
}

.prose ol > li::before {
  @apply text-dark;
}

.prose-sm > ul > li > *:first-child,
.prose-sm > ul > li > *:last-child,
.prose-sm > ol > li > *:first-child,
.prose-sm > ol > li > *:last-child {
  @apply m-0;
}
</style>
