<script setup lang="ts">
import { Schedule } from 'goout-schemas';
import EventBox from '~/components/events/EventBox.vue';
import EventBoxSkeleton from '~/components/events/EventBoxSkeleton.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import Icon from 'goout-icons/Icon.vue';
import { computed, ref, nextTick } from 'vue';
import { useElementBounding } from '@vueuse/core';
interface Props {
  schedules: Schedule[];
  title: string;
  isFetching: boolean;
}

const { schedules } = defineProps<Props>();

const initialItemsToShow = 3;
const itemsToShowIncrement = 3;

const itemsToShow = ref(initialItemsToShow);

const numberOfShownSchedules = computed(() => {
  return itemsToShow.value;
});

const sectionRef = ref<HTMLElement | null>();

const showMoreItems = () => {
  itemsToShow.value += itemsToShowIncrement;
  if (sectionRef.value) {
    const { height } = useElementBounding(sectionRef.value);
    nextTick(() => {
      window.scrollTo({ top: height.value, behavior: 'smooth' });
    });
  }
};

const showLessItems = () => {
  itemsToShow.value = initialItemsToShow;
  if (sectionRef.value) {
    const { top } = useElementBounding(sectionRef.value);
    nextTick(() => {
      window.scrollTo({ top: top.value, behavior: 'smooth' });
    });
  }
};
</script>

<template>
  <section ref="sectionRef">
    <p v-if="schedules.length > 0" class="mb-6 lg:mb-8 text-sm text-gray-dark">
      {{ title }}
    </p>
    <div v-if="!isFetching">
      <div
        v-for="(schedule, index) in schedules"
        :key="schedule.id"
        class="flex flex-col"
      >
        <EventBox
          v-if="index < numberOfShownSchedules"
          :id="schedule.event.id"
          :date="schedule.startAt"
          :event-image-url="schedule.event.images.at(0)?.url"
          :locales="schedule.event.locales"
          :venue="schedule.venue"
          :scheduled-date="schedule?.publishedAt"
          class="mb-4"
        />
      </div>
    </div>
    <div v-else>
      <EventBoxSkeleton
        v-for="index in numberOfShownSchedules"
        :key="index"
        :compact="true"
        class="mb-4"
      />
    </div>
    <Button
      v-if="schedules.length > initialItemsToShow && !isFetching"
      variant="text"
      class="w-full"
      @click="
        () =>
          itemsToShow < schedules.length ? showMoreItems() : showLessItems()
      "
    >
      <span class="text-dark">{{
        itemsToShow < schedules.length
          ? $t('layout.mainPage.showMore')
          : $t('layout.mainPage.showLess')
      }}</span>
      <Icon
        class="ml-2 mt-[2.5px] transform !w-2.5 !h-3 text-blue transform"
        :class="{ 'rotate-180': itemsToShow >= schedules.length }"
        name="Chevron"
      />
    </Button>
  </section>
</template>
