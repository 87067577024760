import Cookies, { CookieAttributes } from 'js-cookie';

type Cookie =
  | 'isWelcomeBoxHidden'
  | 'isCalendarSyncHidden'
  | 'cityId'
  | 'countryIso'
  | 'locality'
  | 'goout-auth.nonce'
  | 'goout-auth.strategy'
  | 'goout-auth.state'
  | 'euCookie'
  | 'language'
  | 'accessToken'
  | 'refreshToken'
  | 'accessTokenIAt';

export function useCookie() {
  const defaultAttrs: CookieAttributes = {
    domain: '.goout.net',
    secure: true,
    sameSite: 'Lax',
  };

  return {
    set: (key: Cookie, value: any, attrs?: CookieAttributes) =>
      Cookies.set(key, value, { ...defaultAttrs, ...attrs }),
    get: (key: Cookie) => Cookies.get(key),
    remove: (key: Cookie, attrs?: CookieAttributes) =>
      Cookies.remove(key, { ...defaultAttrs, ...attrs }),
  };
}
