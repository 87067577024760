<script lang="ts">
import { defineComponent } from 'vue';
import Icons from './index';

export type IconName = keyof typeof Icons;

/*
  Before adding a new icon, please optimize it here: https://www.svgviewer.dev/,
  then add it to the `/src` folder and import it in index.ts

  Icon color: inherits text color, e.g. text-blue, text-red etc.
  Icon size: 24px × 24px by default, can be easily overridden with h-4 (16px), h-8 (32px) etc.
  Spin animation: animate-spin, etc.
*/

export default defineComponent({
  name: 'Icon',
  components: Icons,
  props: {
    name: {
      type: String as () => IconName,
      required: true,
    },
  },
});
</script>

<template>
  <component
    :is="name"
    :name="name"
    :aria-label="name"
    class="h-6 w-6"
    role="img"
    focusable="false"
  />
</template>
