import * as z from 'zod';
import { StateEnumSchema } from '../state.schema';
import { TagEnumSchema } from '../tag.schema';
import {
  RelationshipToMany,
  RelationshipToOne,
} from '../jsonApi/jsonApiRelationships.schema';
import { EntityTypeEnumSchema } from '../entityType.schema';
import { VideoDtoAttributes, VideoSchema } from '../video/video.schema';
import { ImageSchema, Image } from '../image/image.schema';
import {
  PerformerDtoAttributes,
  PerformerSchema,
} from '../performer/performer.schema';
import { Prettify } from '../jsonApi';
import { LanguageEnumSchema } from '../language.schema';
import { SourceSchema } from '../source.schema';

const FilmMeta = z
  .object({
    imdb: z.string().nullish(),
    csfd: z.string().nullish(),
    filmweb: z.string().nullish(),
    originalName: z.string().nullish(),
    released: z.string().nullish(),
    length: z.string().nullish(),
    director: z.string().nullish(),
    author: z.string().nullish(),
    countryIsos: z.array(z.string()).nullish(),
    rating: z.number().int().nullish(),
  })
  .partial()
  .passthrough();
const ExhibitionMeta = z.object({ curator: z.string().nullish() }).partial();
const Recommendation = z.enum(['recommended', 'recommended_in_category']);
const SchedulesRange = z
  .object({ first: z.string(), last: z.string() })
  .passthrough();

const EventDtoLocaleSchema = z.object({
  name: z.string().nullish(),
  note: z.string().nullish(),
  description: z.string().nullish(),
  descriptionHtml: z.string().nullish(),
  metaDescription: z.string().nullish(),
  metaTitle: z.string().nullish(),
});

export const EventDtoAttributesSchema = z.object({
  state: StateEnumSchema.nullish(),
  source: SourceSchema.nullish(),
  mainCategory: TagEnumSchema.nullish(),
  categories: z.array(TagEnumSchema).nullish(),
  keywords: z.array(z.string()).nullish(),
  tags: z.array(TagEnumSchema).nullish(),
  tagsManual: z.array(z.string()).nullish(),
  filmMeta: FilmMeta.nullish(),
  exhibitionMeta: ExhibitionMeta.nullish(),
  minorPerformers: z.array(z.string()).nullish(),
  recommendation: Recommendation.nullish(),
  schedulesRange: SchedulesRange.nullish(),
  hasTimeSlots: z.boolean().nullish(),
  locales: z.record(LanguageEnumSchema, EventDtoLocaleSchema).nullish(),
});

export const EventDtoRelationshipsSchema = z.object({
  videos: RelationshipToMany('videos'),
  images: RelationshipToMany('images'),
  performers: RelationshipToMany('performers'),
  revisionParent: RelationshipToOne('events'),
  revisions: RelationshipToMany('events'),
  venue: RelationshipToOne('venues')
});

export const EventDtoSchema = z.object({
  attributes: EventDtoAttributesSchema,
  id: z.string(),
  type: z.literal(EntityTypeEnumSchema.Values.events),
  links: z.object({ self: z.string() }).optional(),
  relationships: EventDtoRelationshipsSchema,
});

export const EventSchema = z
  .object({
    id: z.string(),
    type: z.literal(EntityTypeEnumSchema.Values.events),
  })
  .merge(EventDtoAttributesSchema)
  .merge(
    z
      .object({
        videos: z.array(VideoSchema),
        images: z.array(ImageSchema),
        performers: z.array(PerformerSchema),
        revisionParent: z.lazy(() => z.union([EventSchema, z.null()])),
        revisions: z.lazy(() => z.array(EventSchema)),
      })
      .partial()
  );

export type EventDto = z.infer<typeof EventDtoSchema>;
export type EventDtoAttributes = z.infer<typeof EventDtoAttributesSchema>;
export type EventDtoRelationships = z.infer<typeof EventDtoRelationshipsSchema>;
export type EventDtoLocale = z.infer<typeof EventDtoLocaleSchema>;
export type EventRelationships = {
  videos: VideoDtoAttributes[];
  images: Image[];
  performers: PerformerDtoAttributes[];
  revisionParent: EventDtoAttributes;
  revisions: EventDtoAttributes[];
};

export type Event = Prettify<
  {
    type: typeof EntityTypeEnumSchema.Values.events;
    id: string;
  } & EventDtoAttributes &
    EventRelationships
>;
