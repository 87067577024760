import { Id } from 'goout-data';

export function parseBase25Id(id: Id): number {
  let numId = 0;
  for (const n of [...String(id)].reverse()) {
    numId = numId * 25 + n.charCodeAt(0) - 97;
  }

  return numId;
}

export function stringifyToBase25(id: Id): string {
  const num = Number(id);

  if (num === 0) {
    return 'a';
  }

  let total = num;
  let str = '';
  let remainder;
  while (total > 0) {
    remainder = total % 25;
    str = String.fromCharCode(97 + remainder) + str;
    total = ((total - remainder) / 25) | 0;
  }
  return [...str].reverse().join('');
}
