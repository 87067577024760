import { onBeforeUnmount, onMounted, Ref } from 'vue';

export default function useDetectOutsideClick(
  component: Ref,
  callback: () => void
) {
  if (!component) return;

  const eventListener = (event: MouseEvent) => {
    if (
      event.target !== component.value &&
      event.composedPath().includes(component.value)
    ) {
      return;
    }

    if (typeof callback === 'function') {
      callback();
    }
  };

  onMounted(() => {
    window.addEventListener('click', eventListener);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('click', eventListener);
  });

  return { eventListener };
}
