import { IdIO, optional } from '../common';
import * as t from 'io-ts';
import { processEntity } from '../../serialization/entity';

export const UserAttributesIO = t.interface({
  firstName: optional(t.string),
  lastName: optional(t.string),
  email: optional(t.string),
  phone: optional(t.string),
  isPublic: t.boolean,
  image: optional(t.string),
  bio: optional(t.string),
  isAmbassador: optional(t.boolean),
  termsAgreedAt: optional(t.string),
  marketingAgreedAt: optional(t.string),
  updatedAt: t.string,
  counts: t.interface({
    followers: optional(t.number),
    following: optional(t.number),
    performers: optional(t.number),
    venues: optional(t.number),
    events: optional(t.number),
    eventsFuture: optional(t.number),
  }),
  calendarUrl: optional(t.string),
});

export const UserIO = t.interface({
  id: IdIO,
  type: t.literal('user'),
  url: t.string,
  attributes: UserAttributesIO,
  relationships: t.interface({}),
});

export type UserRaw = t.TypeOf<typeof UserIO>;
const serialize = (entity: UserRaw) => processEntity(entity, {});
export type User = ReturnType<typeof serialize>;
