<script setup lang="ts">
import Button from 'goout-ui-kit/button/Button.vue';
import { useRouter } from 'vue-router';
import IntroductionStep from '~/components/IntroductionStep.vue';
import { TabEnum } from '~/store/tabs.store';

interface Props {
  isNewUser: boolean;
  isBeginner: boolean;
}

defineProps<Props>();

const router = useRouter();
</script>

<template>
  <div class="flex flex-col h-screen justify-between">
    <div
      class="mx-6 mt-14 flex flex-col items-start justify-center sm:mx-20 xl:flex-row xl:justify-around xl:mt-32"
    >
      <div>
        <p v-if="isNewUser" class="mb-10">
          {{ $t('wizard.intro.firstEvent.addYourEvent') }}
        </p>
        <h2 class="font-semibold text-3xl xl:text-5xl xl:w-128">
          {{
            !isNewUser
              ? $t('wizard.intro.title')
              : $t('wizard.intro.firstEvent.title')
          }}
        </h2>

        <Button
          v-if="isNewUser"
          variant="primary"
          round
          class="!p-4.5 !px-8 mt-12"
          @click="
            router.push({
              name: 'Wizard',
              params: { step: TabEnum.General },
            })
          "
        >
          {{ $t('wizard.intro.firstEvent.button') }}
        </Button>
      </div>

      <ul class="my-12 xl:mt-0 xl:ml-32">
        <IntroductionStep
          :index="1"
          :title="$t('wizard.intro.steps.one.title')"
          :perex="$t('wizard.intro.steps.one.perex')"
        />
        <IntroductionStep
          :index="2"
          :title="$t('wizard.intro.steps.two.title')"
          :perex="$t('wizard.intro.steps.two.perex')"
        />
        <IntroductionStep
          :index="3"
          :title="$t('wizard.intro.steps.three.title')"
          :perex="$t('wizard.intro.steps.three.perex')"
        />
      </ul>
    </div>
    <Button
      v-if="!isNewUser"
      variant="primary-dark"
      size="lg"
      class="w-45 ml-auto"
      @click="
        router.push({ name: 'Wizard', params: { step: TabEnum.General } })
      "
    >
      {{ $t('btn.continue') }}
    </Button>
  </div>
</template>
