import * as t from 'io-ts';
import { PerformerIO } from '../entities/Performer';
import { ImageIO } from '../entities/Image';
import { VideoIO } from '../entities/Video';
import { optional } from '../common';

export const PerformerCollectionIO = t.interface({
  included: t.interface({
    images: t.array(ImageIO),
    videos: t.array(VideoIO),
  }),
  meta: t.interface({
    nextScrollId: optional(t.string),
  }),
  performers: t.array(PerformerIO),
});

export type PerformerCollectionResponse = t.TypeOf<
  typeof PerformerCollectionIO
>;
