<template>
  <svg
    width="87"
    height="22"
    viewBox="0 0 87 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        d="M61.679 3.929a7.071 7.071 0 1 1 0 14.142 7.071 7.071 0 0 1 0-14.142Zm10.607 3.928v5.5a2.357 2.357 0 0 0 4.713.088l.001-.088v-5.5h2.357v5.5a4.714 4.714 0 0 1-9.427.12l-.001-.12v-5.5h2.357ZM34.179 3.93c1.902 0 3.63.751 4.9 1.974L37.414 7.57a4.714 4.714 0 1 0 1.332 4.609H34.18V9.82h6.972a7.071 7.071 0 1 1-6.974-5.893Zm13.75 3.142a5.5 5.5 0 1 1 0 11 5.5 5.5 0 0 1 0-11ZM84.464 3.93v3.928h2.358v2.357h-2.358v4.322c0 .627.491 1.14 1.11 1.176l.069.002h1.178v2.357h-1.178a3.536 3.536 0 0 1-3.534-3.431l-.002-.104v-4.322h-1.571V7.857h1.571V3.93h2.357ZM61.68 6.286a4.714 4.714 0 1 0 0 9.428 4.714 4.714 0 0 0 0-9.428ZM47.93 9.429a3.143 3.143 0 1 0 0 6.285 3.143 3.143 0 0 0 0-6.285Z"
        fill="#191A1B"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11 0v11h11L11 22 0 11 11 0Z"
        fill="#00B6EB"
      />
    </g>
  </svg>
</template>
