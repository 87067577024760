<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="5.25 5.25 13.5 13.5"
  >
    <path
      d="M12 6v12m-6-6h12"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
