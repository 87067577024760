import * as z from 'zod';
import { EntityType, EntityTypeEnumSchema } from '../entityType.schema';
import { ScheduleSchema } from '../schedule/schedule.schema';
import { PerformerSchema } from '../performer/performer.schema';
import { UserSchema } from '../user/user.schema';
import { VenueSchema } from '../venue/venue.schema';
import { EventSchema } from '../event/event.schema';

export const EndpointsSchemaEnum = z.enum([
  'entities/v2',
  'entitystore/v3/events',
  'user/v2',
  'entitystore/v2',
]);
export type EndpointsType = z.infer<typeof EndpointsSchemaEnum>;
/**
 * Here are JSON API compliant endpoints
 **/
export const EntitiesEndpointsSchemaEnum = z.enum([
  EntityTypeEnumSchema.Enum.schedules,
  EntityTypeEnumSchema.Enum.performers,
  EntityTypeEnumSchema.Enum.users,
  EntityTypeEnumSchema.Enum.venues,
  EntityTypeEnumSchema.Enum.events,
]);

/**
 * Reflects endpoints include parameter possibilities
 **/
export const EntitiesIncludesMapper = {
  [EntitiesEndpointsSchemaEnum.Enum.schedules]: [
    EntityTypeEnumSchema.Enum.cities,
    EntityTypeEnumSchema.Enum.deals,
    EntityTypeEnumSchema.Enum.discounts,
    EntityTypeEnumSchema.Enum.events,
    EntityTypeEnumSchema.Enum.images,
    EntityTypeEnumSchema.Enum.performers,
    EntityTypeEnumSchema.Enum.sales,
    EntityTypeEnumSchema.Enum.venues,
    EntityTypeEnumSchema.Enum.contacts,
    EntityTypeEnumSchema.Enum.videos,
    EntityTypeEnumSchema.Enum.parents,
  ],
  [EntitiesEndpointsSchemaEnum.Enum.performers]: [
    EntityTypeEnumSchema.Enum.images,
    EntityTypeEnumSchema.Enum.videos,
  ],
  [EntitiesEndpointsSchemaEnum.Enum.venues]: [
    EntityTypeEnumSchema.Enum.cities,
    EntityTypeEnumSchema.Enum.images,
    EntityTypeEnumSchema.Enum.videos,
    EntityTypeEnumSchema.Enum.parents,
    EntityTypeEnumSchema.Enum.related,
  ],
  [EntitiesEndpointsSchemaEnum.Enum.events]: [
    EntityTypeEnumSchema.Enum.images,
    EntityTypeEnumSchema.Enum.videos,
    EntityTypeEnumSchema.Enum.performers,
    EntityTypeEnumSchema.Enum.venues,
  ],
  [EntitiesEndpointsSchemaEnum.Enum.users]: [
    EntityTypeEnumSchema.Enum.contacts,
    EntityTypeEnumSchema.Enum.guestlists
  ]
};

export const EntitesSchemaMapper = {
  [EntityTypeEnumSchema.Enum.schedules]: ScheduleSchema,
  [EntityTypeEnumSchema.Enum.performers]: PerformerSchema,
  [EntityTypeEnumSchema.Enum.users]: UserSchema,
  [EntityTypeEnumSchema.Enum.venues]: VenueSchema,
  [EntityTypeEnumSchema.Enum.events]: EventSchema,
} as const;

export type EntitiesSchemaEnumType = z.infer<
  typeof EntitiesEndpointsSchemaEnum
>;
export type EndpointsSchemaEnumType = z.infer<typeof EndpointsSchemaEnum>;
export type EntitiesIncludesMapperType = typeof EntitiesIncludesMapper;

export type EntityTypesWithInclude = keyof EntitiesIncludesMapperType;
export type ValidIncludes<T extends EntityTypesWithInclude> =
  EntitiesIncludesMapperType[T][number];
export type EntityKeyString<T extends keyof EntitiesIncludesMapperType> = {
  key: T;
  string: string;
};
