<script lang="ts" setup>
import Icon from 'goout-icons/Icon.vue';
import { ref } from 'vue';

const isOpen = ref(true);
</script>

<template>
  <div>
    <Icon v-if="!isOpen" name="Eye" @click="isOpen = !isOpen" />
    <Icon v-else name="EyeClosed" @click="isOpen = !isOpen" />
  </div>
</template>
