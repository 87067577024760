<script setup lang="ts">
import Icon from 'goout-icons/Icon.vue';
import { authWithProvider } from '../api/oauth';
import { useAuth } from '../composables/useAuth';
import { onMounted } from 'vue';
import Spinner from './Spinner.vue';

interface Props {
  action: 'signUp' | 'signIn';
}

const props = defineProps<Props>();
const { isFetchingFacebook, isFetchingGoogle } = useAuth();
const handleAction = (provider: 'google' | 'facebook') => {
  if (provider === 'google') {
    isFetchingGoogle.value = true;
  }

  if (provider === 'facebook') {
    isFetchingFacebook.value = true;
  }
  authWithProvider(provider, props.action);
};

onMounted(() => {
  if (window.location.hash.includes('google')) {
    isFetchingGoogle.value = true;
  }

  if (window.location.hash.includes('facebook')) {
    isFetchingFacebook.value = true;
  }
});
</script>

<template>
  <div class="flex flex-col gap-y-2 text-center">
    <button
      class="text-sm font-medium flex justify-center items-center disabled:opacity-80 disabled:cursor-not-allowed"
      @click="handleAction('google')"
      :disabled="isFetchingGoogle"
    >
      <Icon
        name="GoogleColor"
        class="h-6 w-6 absolute left-4 top-1/2 transform -translate-y-1/2"
      />
      <span v-if="!isFetchingGoogle">
        {{ $t(`loginModule.${action}.google`) }}
      </span>
      <Spinner v-else="isFetchingGoogle" class="mx-auto text-blue" />
    </button>
    <button
      class="text-sm font-medium flex justify-center items-center disabled:opacity-80 disabled:cursor-not-allowed"
      @click="handleAction('facebook')"
      :disabled="isFetchingFacebook"
    >
      <Icon
        name="FacebookColor"
        class="h-6 w-6 absolute left-4 top-1/2 transform -translate-y-1/2"
      />
       <span v-if="!isFetchingFacebook">
        {{ $t(`loginModule.${action}.facebook`) }}
      </span>
      <Spinner v-else="isFetchingFacebook" class="mx-auto text-blue" />
    </button>
  </div>
</template>

<style scoped>
button {
  @apply w-full relative border-1 border-gray-lighter border-opacity-24 rounded-[100px] h-[56px] py-3 px-4 hover:bg-gray-lighter hover:bg-opacity-5;
  outline: none;
}
</style>
