import { z } from 'zod';
import { EntityTypeEnumSchema } from '../entityType.schema';
import { LanguageEnumSchema } from '../language.schema';
import { Prettify } from '../jsonApi';

const CityDtoLocaleSchema = z.object({
  name: z.string(),
  regionName: z.string(),
});

export const CityDtoAttributesSchema = z
  .object({
    isFeatured: z.boolean(),
    countryIso: z.string(), //CountryISOEnumSchema, imho this is not correct, should be enum, but on BE it is string
    isFictiveCountry: z.boolean(),
    locales: z.record(LanguageEnumSchema, CityDtoLocaleSchema),
  })
  .partial();

//City is not migrated yet to JSON API
export const CityDtoSchema = z.object({
  id: z.string(),
  attributes: CityDtoAttributesSchema,
  type: z.literal(EntityTypeEnumSchema.Values.cities),
  relationships: z.unknown().nullish(),
});

export const CitySchema = CityDtoAttributesSchema;

export type CityDto = z.infer<typeof CityDtoSchema>;
export type CityDtoAttributes = z.infer<typeof CityDtoAttributesSchema>;
export type City = Prettify<
  {
    type: typeof EntityTypeEnumSchema.Values.venues;
    id: number;
  } & CityDtoAttributes
>;
