<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    class="stroke"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#a)">
      <path d="M1.667 9.667v-8h6v8h-6Z" stroke="currentColor" stroke-width="2" />
      <mask id="b" fill="#fff">
        <path d="m11.334 4.413 3.731 1.008-2.609 9.654-7.723-2.088" />
      </mask>
      <path
        d="m15.065 5.421 1.931.522.522-1.93-1.93-.522-.523 1.93Zm-2.609 9.654-.522 1.931 1.93.522.523-1.93-1.931-.523Zm-1.644-8.732 3.731 1.009 1.044-3.861-3.731-1.009-1.044 3.861ZM13.135 4.9l-2.61 9.654 3.862 1.044 2.61-9.654L13.134 4.9Zm-.157 8.246-7.723-2.088-1.044 3.861 7.723 2.088 1.044-3.861Z"
        fill="currentColor"
        mask="url(#b)"
      />
    </g>
    <defs>
      <clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z" /></clipPath>
    </defs>
  </svg>
</template>
