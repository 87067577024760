import * as t from 'io-ts';
import { Type } from 'io-ts';

export const IdIO = t.union([t.string, t.number]);
export type Id = t.TypeOf<typeof IdIO>;
export const optional = (type: t.Any) => t.union([type, t.undefined, t.null]);

export const CountryIsoIO = t.union([
  t.literal('CZ'),
  t.literal('DE'),
  t.literal('PL'),
  t.literal('SK'),
]);

export type CountryIso = t.TypeOf<typeof CountryIsoIO>;

export const CountryIsoLowerCaseIO = t.union([
  t.literal('cz'),
  t.literal('de'),
  t.literal('pl'),
  t.literal('sk'),
]);

export type CountryIsoLowerCase = t.TypeOf<typeof CountryIsoLowerCaseIO>;

/** Languages from localise */
export const LanguageIsoIO = t.union([
  t.literal('cz'),
  t.literal('de'),
  t.literal('pl'),
  t.literal('sk'),
  t.literal('en'),
  t.literal('uk'),
]);
export type LanguageIso = t.TypeOf<typeof LanguageIsoIO>;

export const EntityTypeIO = t.union([
  t.literal('schedule'),
  t.literal('sale'),
  t.literal('venue'),
  t.literal('performer'),
  t.literal('video'),
  t.literal('image'),
  t.literal('city'),
  t.literal('user'),
  t.literal('event'),
  t.literal('deal'),
  t.literal('contact'),
  t.literal('feed-activity'),
  t.literal('feed-entry'),
]);
export type EntityType = t.TypeOf<typeof EntityTypeIO>;

export const CurrencyTypeIO = t.union([
  t.literal('czk'),
  t.literal('pln'),
  t.literal('eur'),
  t.literal('gbp'),
]);
export type Currency = t.TypeOf<typeof CurrencyTypeIO>;

export const getLocalesIO = <L>(localesPropIO: Type<L>) =>
  t.record(t.string, localesPropIO);
export type LocalesIO = ReturnType<typeof getLocalesIO>;

export const getEntityRelationShip = (entity: EntityType) =>
  t.interface({
    id: IdIO,
    type: t.literal(entity),
  });

export const EntityStateIO = t.union([
  t.literal('do_not_use'), // deprecated, do not use
  t.literal('unapproved'),
  t.literal('visible'),
  t.literal('approved'),
  t.literal('deleted'),
]);

const hasMany = (type: EntityType) => t.array(getEntityRelationShip(type));
type HasManyIO = ReturnType<typeof hasMany>;
type HasOneIO = ReturnType<typeof getEntityRelationShip>;

type RelationshipIO = Record<
  string,
  | HasOneIO
  | HasManyIO
  | t.UnionC<[t.UndefinedC, t.NullC, HasOneIO]>
  | t.UnionC<[t.UndefinedC, t.NullC, HasManyIO]>
>;
export function createEntityIO<
  T extends string,
  A extends Record<string, any>,
  R extends RelationshipIO,
  L extends LocalesIO,
  M extends Record<string, any>
>(
  type: T,
  content: { attributes: A; relationships?: R; locales?: L; meta?: M }
) {
  return t.interface({
    id: IdIO,
    type: t.literal(type),
    attributes: t.interface(content.attributes),
    relationships: t.interface(content.relationships || {}),
    locales: optional(
      getLocalesIO(
        t.interface({
          regionName: t.string,
          name: t.string,
        })
      )
    ),
    meta: content.meta ? t.interface(content.meta) : t.undefined,
  });
}
