<script setup lang="ts">
import LogoButton from '~/components/general/LogoButton.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import { useRouter } from 'vue-router';
import QuestionMark from '~/components/general/QuestionMark.vue';

const router = useRouter();
</script>

<template>
  <nav>
    <header class="mx-6 mt-7 mb-4 flex justify-between">
      <LogoButton class="lg:my-3 lg:ml-4" />
      <div class="flex gap-x-4 pr-3">
        <QuestionMark />
        <Button
          v-tracking="{
            category: 'redirect_button',
            view: router.currentRoute.value,
            label: 'Leave',
          }"
          class="w-12 h-12 !p-4 text-dark !border-gray/24"
          variant="border"
          size="sm"
          icon="Close"
          round
          @click="router.push({ name: 'Overview' })"
        />
      </div>
    </header>
  </nav>
</template>
