<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g>
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12 0v12h12L12 24 0 12 12 0Z"
        fill="#00B6EB"
      />
    </g>
  </svg>
</template>
