import { z } from 'zod';

const dateFormatValidator = (date: string) => {
  // Explicitly remove quotes and whitespace from the input for cleaner validation
  const cleanDate = date.replace(/['" ]/g, '');

  // Updated regex to handle 'DD.MM.YYYY', 'dd/MM/yyyy', and 'yyyy-MM-dd'
  const regex =
    /^(?:\d{2}\.\d{2}\.\d{4}|\d{2}\/\d{2}\/\d{4}|\d{4}-\d{2}-\d{2})$/;
  if (!regex.test(cleanDate)) {
    return false;
  }

  // Splitting the date by dot, slash, or dash based on the format
  let day, month, year;
  if (cleanDate.includes('.')) {
    [day, month, year] = cleanDate.split('.').map(Number);
  } else if (cleanDate.includes('/')) {
    [day, month, year] = cleanDate.split('/').map(Number);
  } else if (cleanDate.includes('-')) {
    [year, month, day] = cleanDate.split('-').map(Number);
  } else {
    return false; // Handling cases with no valid separators
  }

  // Validating the actual date values to ensure they make a valid date
  const dateObj = new Date(year, month - 1, day);
  const isValidDate =
    dateObj.getFullYear() === year &&
    dateObj.getMonth() === month - 1 &&
    dateObj.getDate() === day;

  return isValidDate;
};

// Zod schema with custom validation to handle three date formats
export const dateSchema = z.string().refine(dateFormatValidator, {
  message: 'validation.date.format_dd/MM/yyyy_or_DD.MM.YYYY_or_yyyy-MM-dd',
});
