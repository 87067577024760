import * as t from 'io-ts';
import { IdIO, optional } from '../common';
import { CityIO } from '../entities/City';
import { ImageIO } from '../entities/Image';
import { VenueIO } from '../entities/Venue';
import { VideoIO } from '../entities/Video';

export const VenueCollectionIO = t.interface({
  included: t.interface({
    cities: t.array(CityIO),
    images: t.array(ImageIO),
    videos: t.array(VideoIO),
  }),
  meta: t.interface({
    donut: optional(t.string),
    featuredCities: t.array(IdIO),
    nextScrollId: optional(t.string),
  }),
  venues: t.array(VenueIO),
});

export type VenueCollectionResponse = t.TypeOf<typeof VenueCollectionIO>;
