<script setup lang="ts">
import Input from './Input.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import { useI18n } from 'vue-i18n';
import { logout } from '../api/logout';
import { ref, computed } from 'vue';
import { ModalTypeEnum } from '../utils/modalTypes';
import { useModalStore } from '../stores/modal.store';
import Spinner from './Spinner.vue';
import { useToast } from 'goout-ui-kit/composables/useToast';
import { z } from 'zod';
import { usePasswordSignIn } from '../api/usePasswordSignIn';
import { useAuth } from '../composables/useAuth';
import { ForbiddenError } from 'goout-utils';

const { t } = useI18n();

const loginCredentials = ref({
  username: '',
  password: '',
});

const { isAdminOnly } = useAuth();
const { showToast } = useToast();
const { switchForms } = useModalStore();

const {
  execute: signIn,
  error,
  isFetching,
  statusCode,
} = usePasswordSignIn(loginCredentials, {
  immediate: false,
});

const handleLogin = async () => {
  try {
    await signIn(true);
  } catch (err) {
    if (err instanceof ForbiddenError) {
      showToast({
        heading: 'loginModule.error.insufficient.permissions',
        message: 'loginModule.error.need.admin',
        position: 'top-right',
      });
    }
    await logout(false);
  }
};

const formattedError = computed(() => {
  switch (statusCode.value) {
    case 401:
      return t('loginModule.error.invalidCredentials');
    default:
      return '';
  }
});

const disabledButton = computed(() => {
  return (
    !loginCredentials.value.username ||
    !loginCredentials.value.password ||
    !z.string().email().safeParse(loginCredentials.value.username).success ||
    isFetching.value
  );
});

const buttonVariant = computed(() =>
  disabledButton.value ? 'primary-disabled-blue' : 'primary'
);
</script>

<template>
  <div class="flex flex-col items-center w-full">
    <form class="relative w-full" @submit="handleLogin">
      <p
        v-if="formattedError"
        class="text-red text-center font-medium pb-4 text-sm"
      >
        {{ $t(formattedError) }}
      </p>
      <Input
        v-model="loginCredentials.username"
        class="w-full"
        :label="t('loginModule.input.email')"
        :error="!!formattedError"
        required
        @input="error = false"
      >
      </Input>
      <Input
        v-model="loginCredentials.password"
        class="w-full mt-5"
        :label="t('loginModule.input.password')"
        :error="!!formattedError"
        type="password"
        required
        @input="error = false"
      >
      </Input>
      <div class="text-center py-5 flex flex-col gap-y-5">
        <span class="text-gray-dark pb-2 hover:text-gray underline text-sm">
          <a
            class="cursor-pointer"
            @click="switchForms(ModalTypeEnum.forgottenPassword)"
            >{{ $t('loginModule.forgottenPassword') }}</a
          >
        </span>
      </div>
      <Button
        class="w-full h-14"
        :variant="buttonVariant"
        round
        :disabled="disabledButton"
        @click="handleLogin"
      >
        <Spinner v-if="isFetching" />
        <span v-else>{{ $t('loginModule.password.log.in') }}</span>
      </Button>
      <p class="text-sm w-auto text-center pt-8 pb-3 font-medium">
        <span>{{ $t('loginModule.first.time') + ' ' }}</span>
        <a
          class="cursor-pointer text-blue hover:text-opacity-80"
          @click="switchForms(ModalTypeEnum.signUp)"
          >{{ $t('loginModule.create.profile') }}</a
        >
      </p>
    </form>
  </div>
</template>
