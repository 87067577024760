import qs from 'qs';
import { useCookie } from 'goout-utils/cookie';
import { connectFromOAuthToGoout } from './connectFromOAuthToGoout';
import { getUserData } from './getUserData';

const providers = {
  facebook: {
    clientId: '1112581076080518',
    endpoints: {
      authorization: 'https://facebook.com/v2.12/dialog/oauth',
    },
    scope: 'public_profile email',
  },

  google: {
    clientId:
      '922365957414-ic0q1lkuqj96m8cktvuqfd0jbqech9g5.apps.googleusercontent.com',
    endpoints: {
      authorization: 'https://accounts.google.com/o/oauth2/auth',
    },
    scope: 'openid profile email',
  },
};

export type ProviderName = keyof typeof providers;

// First step of the OAuth flow
// Redirects the user to the OAuth provider's login page
// After the user logs in, the provider will redirect the user back to the app
export function authWithProvider(
  providerName: ProviderName,
  action: 'signIn' | 'signUp'
) {
  const provider = providers[providerName];
  const nonce = generateNonce(10);

  useCookie().set('goout-auth.nonce', nonce);
  useCookie().set('goout-auth.strategy', providerName);

  const state = JSON.stringify({
    origin: window.location.pathname,
    nonce,
    action,
  });

  const options = {
    protocol: 'oauth2',
    response_type: 'token',
    client_id: provider.clientId,
    redirect_uri: window.location.origin + '?oauth=true',
    scope: provider.scope,
    state,
  };

  const queryParams = qs.stringify(options);

  const url = `${provider.endpoints.authorization}?${queryParams}`;
  window.location.replace(url);
}
// Second step of the OAuth flow
// We check if there is an access token in the URL and if nonce is valid
export async function useOAuth({ isAdminOnly = false }: { isAdminOnly?: boolean } = {} ) {
  const hashParams = new URLSearchParams(window.location.hash.substring(1));
  const state = hashParams.get('state');
  const accessToken = hashParams.get('access_token');
  
  if (!accessToken || !state) {
    return;
  }
  const nonce = JSON.parse(state).nonce;

  if (nonce !== useCookie().get('goout-auth.nonce')) {
    // clear auth token from the URL
    window.history.replaceState(null, '', '/');
    console.error('Nonce missmatch, might indicate CSRF breach!');
    return;
  }

  // Connect session to Goout
  await connectFromOAuthToGoout(accessToken);

  // Get user data
  await getUserData({
    isAdminOnly,
  });
}

function generateNonce(length: number) {
  let text = "";
  let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}