export class ApiError {
  public originalError?: Error;
  public readonly allowRetry: boolean = false; // whether it makes sense to retry upon this error

  constructor(error?: Error) {
    this.originalError = error;
  }
}

export class InternalServerError extends ApiError {
  allowRetry = true;
  status = 500;
  message = 'Internal server error';
} // 500
export class BadGatewayError extends ApiError {
  allowRetry = true;
  status = 502;
  message = 'Bad gateway error';
} // 502
export class ServiceUnavailableError extends ApiError {
  allowRetry = true;
  status = 503;
  message = 'Service unavailable error';
} // 503
export class GatewayTimeoutError extends ApiError {
  allowRetry = true;
  status = 504;
  message = 'Gateway timeout error';
} // 504
export class BadRequestError extends ApiError {
  allowRetry = false;
  status = 400;
  message = 'Bad request error';
} // 400
export class UnauthorizedError extends ApiError {
  allowRetry = false;
  status = 401;
  message = 'Unauthorized error';
} // 401
export class ForbiddenError extends ApiError {
  allowRetry = false;
  status = 403;
  message = 'Forbidden error';
} // 403
export class NotFoundError extends ApiError {
  allowRetry = false;
  status = 404;
  message = 'Not found error';
} // 404
export class ConflictError extends ApiError {
  allowRetry = false;
  status = 409;
  message = 'Conflict error';
}
export class UnknownApiError extends ApiError {
  allowRetry = true;
  message = 'Unknown error';
} // ???

export class NetworkError extends ApiError {
  allowRetry = true;
  status = 0;
  message = 'Network error';
}

export function getIdentifiedError(error: any, status: number): ApiError {
  const errorMap: Record<string, Function> = {
    '500': () => new InternalServerError(error),
    '502': () => new BadGatewayError(error),
    '503': () => new ServiceUnavailableError(error),
    '504': () => new GatewayTimeoutError(error),
    '400': () => new BadRequestError(error),
    '401': () => new UnauthorizedError(error),
    '403': () => new ForbiddenError(error),
    '404': () => new NotFoundError(error),
    '409': () => new ConflictError(error),
    '0': () => new NetworkError(error),
  };

  const identifiedError =
    errorMap[String(status)] && errorMap[String(status)]();
  if (identifiedError) {
    return identifiedError;
  }

  return new UnknownApiError(error);
}
