import {
  getEntityRelationShip,
  createEntityIO,
  IdIO,
  optional,
} from '../common';
import * as t from 'io-ts';
import { processEntity } from '../../serialization/entity';

export const FollowerInfoIO = createEntityIO('follower', {
  attributes: {
    followerCount: t.number,
    likeState: optional(t.string),
    followerImages: t.array(
      t.interface({
        id: IdIO,
        firstName: t.string,
        image: t.string,
      })
    ),
  },

  relationships: {
    schedule: t.union([t.undefined, t.null, getEntityRelationShip('schedule')]),
    user: t.union([t.undefined, t.null, getEntityRelationShip('user')]),
    performer: t.union([
      t.undefined,
      t.null,
      getEntityRelationShip('performer'),
    ]),
    venue: t.union([t.undefined, t.null, getEntityRelationShip('venue')]),
  },
});

export type FollowerInfoRaw = t.TypeOf<typeof FollowerInfoIO>;
const serialize = (entity: FollowerInfoRaw) => processEntity(entity, {});
export type FollowerInfo = ReturnType<typeof serialize>;
