<script lang="ts" setup>
import Datepicker from './Datepicker.vue';
import Timepicker from './Timepicker.vue';
import { DateSchema } from '~/definitions/schemas/date/date.schema';
import { computed, watch } from 'vue';

const { date } = defineProps<{
  date: DateSchema;
}>();

const forceLimit = computed(() => {
  return (
    date.startDate === date.endDate &&
    date.startDate !== '' &&
    String(date.startTime.hour) !== '' &&
    String(date.startTime.minute) !== ''
  );
});

// This is forcing the end time to be after the start time
// when the start and end dates are the same
watch(forceLimit, () => {
  if (
    !forceLimit.value ||
    String(date.endTime.hour) === '' ||
    String(date.endTime.minute) === ''
  ) {
    return;
  }
  date.endTime = {
    hour: +date.startTime.hour < 23 ? +date.startTime.hour + 1 : 23,
    minute: date.startTime.minute,
  };
});
</script>

<template>
  <div class="flex gap-x-2 w-full">
    <Datepicker
      v-model="date.startDate"
      class="flex-1"
      :label="$t('wizard.tabs.date.start.date')"
      :upper-limit="date.endDate"
      disable-past
    />
    <Datepicker
      v-model="date.endDate"
      class="flex-1"
      :label="$t('wizard.tabs.date.end.date')"
      :lower-limit="date.startDate"
      disable-past
    />
  </div>
  <div class="flex gap-x-2 w-full">
    <Timepicker
      :key="forceLimit ? 'startTime' : 'endTime'"
      v-model="date.startTime"
      :upper-limit="date.endTime"
      :force-limit="forceLimit"
      :label="$t('wizard.tabs.date.start.time')"
      class="flex-1"
    />
    <Timepicker
      :key="forceLimit ? 'endTime' : 'startTime'"
      v-model="date.endTime"
      :lower-limit="date.startTime"
      :force-limit="forceLimit"
      :label="$t('wizard.tabs.date.end.time')"
      class="flex-1"
    />
  </div>
</template>

<style lang="scss" scoped></style>
