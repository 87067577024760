import { IdIO } from '../common';
import * as t from 'io-ts';
import { processEntity } from '../../serialization/entity';

export const VideoAttributesIO = t.interface({
  videoId: IdIO,
  source: t.string,
  name: t.string,
});

export const VideoIO = t.interface({
  id: IdIO,
  type: t.literal('video'),
  attributes: VideoAttributesIO,
  relationships: t.interface({}),
});

export type VideoRaw = t.TypeOf<typeof VideoIO>;
const serialize = (entity: VideoRaw) => processEntity(entity, {});
export type Video = ReturnType<typeof serialize>;
