<script lang="ts" setup>
import DynamicModal from './components/modals/DynamicModal.vue';
import { createAuthModal, useAuth } from 'goout-auth';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';

onMounted(() => {
  createAuthModal({
    isAdminOnly: true,
  });
});

const { isLoggedIn } = useAuth();
const route = useRoute();
const isModal = computed(() => route.meta?.modal);
</script>

<template>
  <main v-if="isLoggedIn">
    <router-view />
    <DynamicModal v-if="isModal" />
  </main>
</template>
