<script lang="ts" setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import EventEdit from './EventEdit/EventEditModal.vue';
import EventDelete from './EventDelete/EventDeleteModal.vue';
import ConfirmModal from './ConfirmModal/ConfirmModal.vue';
import SuccessModal from './SuccessModal/SuccessModal.vue';
import ErrorModal from './ErrorModal/ErrorModal.vue';
import { ModalRoute } from '~/composables/useModal';
import EventEditModal from './EventEdit/EventEditModal.vue';
import EventDeleteModal from './EventDelete/EventDeleteModal.vue';
import EventSellModal from './EventSell/EventSellModal.vue';

const route = useRoute();
const isModal = computed(() => route.meta?.modal);
const component = computed(() => modalsMap[route.name as string]?.component);
const routeProps = computed(() => modalsMap[route.name as string]?.props);

const modalsMap: ComponentMapper = {
  [ModalRoute.EventEditModal]: { component: EventEditModal, props: {} },
  [ModalRoute.EventSellModal]: { component: EventSellModal, props: {} },
  [ModalRoute.EventDeleteModal]: { component: EventDeleteModal, props: {} },
  [ModalRoute.ConfirmModalEdit]: {
    component: ConfirmModal,
    props: {
      title: 'modal.confirm.edit.title',
      description: 'modal.confirm.edit.desc',
      successRoute: ModalRoute.SuccessModalEdit,
      errorRoute: ModalRoute.ErrorModalEdit,
    },
  },
  [ModalRoute.ConfirmModalDelete]: {
    component: ConfirmModal,
    props: {
      title: 'modal.confirm.delete.title',
      description: 'modal.confirm.delete.desc',
      successRoute: ModalRoute.SuccessModalDelete,
      errorRoute: ModalRoute.ErrorModalDelete,
    },
  },
  [ModalRoute.SuccessModalEdit]: {
    component: SuccessModal,
    props: {
      title: 'modal.success.edit.title',
      description: 'modal.success.edit.desc',
    },
  },
  [ModalRoute.SuccessModalDelete]: {
    component: SuccessModal,
    props: {
      title: 'modal.success.delete.title',
      description: 'modal.success.delete.desc',
    },
  },
  [ModalRoute.ErrorModalDelete]: {
    component: ErrorModal,
    props: {
      title: 'modal.error.delete.title',
      description: 'modal.error.delete.desc',
    },
  },
  [ModalRoute.ErrorModalEdit]: {
    component: ErrorModal,
    props: {
      title: 'modal.error.edit.title',
      description: 'modal.error.edit.desc',
    },
  },
  [ModalRoute.ConfirmModalSell]: {
    component: ConfirmModal,
    props: {
      title: 'modal.confirm.sell.title',
      description: 'modal.confirm.sell.desc',
      successRoute: ModalRoute.SuccessModalSell,
      errorRoute: ModalRoute.ErrorModalSell,
    },
  },
  [ModalRoute.SuccessModalSell]: {
    component: SuccessModal,
    props: {
      title: 'modal.success.sell.title',
      description: 'modal.success.sell.desc',
    },
  },
  [ModalRoute.ErrorModalSell]: {
    component: ErrorModal,
    props: {
      title: 'modal.error.sell.title',
      description: 'modal.error.sell.desc',
    },
  },
};

type ConfirmModalProps = InstanceType<typeof ConfirmModal>['$props'];
type EventEditProps = InstanceType<typeof EventEdit>['$props'];
type EventDeleteProps = InstanceType<typeof EventDelete>['$props'];
type ConfirmSuccessProps = InstanceType<typeof SuccessModal>['$props'];
type ErrorModalProps = InstanceType<typeof ErrorModal>['$props'];
type EventSellProps = InstanceType<typeof EventSellModal>['$props'];

type ComponentMapper = {
  [ModalRoute.EventEditModal]: ComponentEntry<EventEditProps>;
  [ModalRoute.EventDeleteModal]: ComponentEntry<EventDeleteProps>;
  [ModalRoute.ConfirmModalEdit]: ComponentEntry<ConfirmModalProps>;
  [ModalRoute.ConfirmModalDelete]: ComponentEntry<ConfirmModalProps>;
  [ModalRoute.ConfirmModalSell]: ComponentEntry<ConfirmModalProps>;
  [ModalRoute.SuccessModalDelete]: ComponentEntry<ConfirmSuccessProps>;
  [ModalRoute.SuccessModalEdit]: ComponentEntry<ConfirmSuccessProps>;
  [ModalRoute.SuccessModalSell]: ComponentEntry<ConfirmSuccessProps>;
  [ModalRoute.ErrorModalDelete]: ComponentEntry<ConfirmSuccessProps>;
  [ModalRoute.ErrorModalEdit]: ComponentEntry<ErrorModalProps>;
  [ModalRoute.ErrorModalSell]: ComponentEntry<ErrorModalProps>;
  [ModalRoute.EventSellModal]: ComponentEntry<EventSellProps>;
};

type ComponentEntry<ComponentProps> = {
  component: any;
  props: ComponentProps;
};
</script>

<template>
  <component
    :is="component"
    v-if="isModal && route.name"
    :key="route.name"
    v-bind="routeProps"
  />
</template>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
