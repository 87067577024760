<script lang="ts" setup>
import { useDataStore } from '~/store/data.store';
import { toRefs, computed, onMounted } from 'vue';
import Introduction from '../Introduction.vue';
import { useRouter } from 'vue-router';

const dataStore = useDataStore();
const { schedules } = toRefs(dataStore);

const isNewUser = computed(() => schedules.value.length === 0);
const isBeginner = computed(
  () => schedules.value.length > 0 && schedules.value.length < 3
);
const isAdvancedUser = computed(() => schedules.value.length > 3);

const router = useRouter();

onMounted(() => {
  if (isAdvancedUser.value) {
    router.push('/');
  }
});
</script>

<template>
  <Introduction
    v-if="!isAdvancedUser"
    :is-beginner="isBeginner"
    :is-new-user="isNewUser"
  />
</template>
