<template
  ><svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.333 13.333 9.95 9.95M6.933 11.2a4.267 4.267 0 1 0 0-8.533 4.267 4.267 0 0 0 0 8.533Z"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    /></svg
></template>
