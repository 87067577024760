import { createApp } from 'vue';
import App from './App.vue';
import './styles/fonts.scss';
import 'virtual:windi.css';
import { createPinia } from 'pinia';
import { i18n, setupI18n } from './i18n';
import { router } from './router';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import * as Sentry from '@sentry/vue';
import { vTrackingDirective } from './composables/useTracking';
import { TrackingDirectiveArgs } from './composables/useTracking.d';
import { detectVersionChange } from './utils/versioning.util';

const app = createApp(App);

// i18n
setupI18n();
app.use(i18n);

// Versioning
detectVersionChange();

// Pinia
const pinia = createPinia();
app.use(pinia);
pinia.use(piniaPluginPersistedstate);

// Router
app.use(router);

// Directives
app.directive<HTMLElement, TrackingDirectiveArgs>(
  'tracking',
  vTrackingDirective
);

// Sentry
Sentry.init({
  app,
  dsn: 'https://1b9f39c5f81dcdcd4007f8c296961fcf@o234691.ingest.us.sentry.io/4507424048021504',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  release: '1.0.0',
  environment: import.meta.env.MODE,
  // Performance Monitoring
  tracesSampleRate: 0.1, // Measuring throughtput, latency, and other performance metrics.
  // Session Replay
  replaysSessionSampleRate: import.meta.env.MODE === 'development' ? 1.0 : 0.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

app.mount('#app');
