import * as t from 'io-ts';
import { IdIO, optional } from '../common';
import { CityIO } from '../entities/City';
import { ImageIO } from '../entities/Image';
import { EventIO } from '../entities/Event';
import { VideoIO } from '../entities/Video';

export const EventCollectionIO = t.interface({
  included: t.interface({
    cities: t.array(CityIO),
    images: t.array(ImageIO),
    videos: t.array(VideoIO),
  }),
  meta: optional(
    t.interface({
      donut: optional(t.string),
      featuredCities: t.array(IdIO),
      nextScrollId: optional(t.string),
    })
  ),
  events: t.array(EventIO),
});

export type EventCollectionResponse = t.TypeOf<typeof EventCollectionIO>;
