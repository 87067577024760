<script lang="ts" setup>
import Button from 'goout-ui-kit/button/Button.vue';
import { useModalStore } from '../stores/modal.store';
import { ModalTypeEnum } from '../utils/modalTypes';

defineProps<{
  title: string;
  description?: string;
  closeIcon: boolean;
}>();

const { switchForms, isRemovable, isOpen } = useModalStore();

function handleClose() {
  if (isRemovable.value) {
    isOpen.value = false;
    return;
  }

  switchForms(ModalTypeEnum.signIn);
}
</script>

<template>
  <div class="p-6">
    <Button
      v-if="closeIcon"
      class="absolute w-6 h-6 m-2 !p-1 top-1 right-1 z-10"
      variant="text-dark"
      size="xs"
      icon="Close"
      @click="handleClose"
      round
    />
    <div class="flex gap-x-3 items-center">
      <slot name="icon" />
      <h1 class="text-lg font-semibold">{{ $t(title) }}</h1>
    </div>
    <p v-if="description" class="pt-3 text-gray text-sm">
      {{ $t(description) }}
    </p>
    <div class="flex flex-col pt-6">
      <slot />
    </div>
  </div>
</template>
