<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2645_3183)">
            <path
                d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23Z"
                stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
            <path d="M12 7V13" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
            <path
                d="M12 18C12.5523 18 13 17.5523 13 17C13 16.4477 12.5523 16 12 16C11.4477 16 11 16.4477 11 17C11 17.5523 11.4477 18 12 18Z"
                fill="currentColor" />
        </g>
        <defs>
            <clipPath id="clip0_2645_3183">
                <rect width="24" height="24" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>