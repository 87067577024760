<template>
  <p class="text-gray px-10 text-sm pt-4 text-center">
    <i18n-t keypath="auth.dialog.termsAgreement">
      <template #termsOfUse>
        <a
          :href="$t('auth.dialog.termsOfUseLink')"
          rel="noopener noreferrer"
          class="underline mr-1"
        >
          {{ $t('auth.dialog.termsOfUse') }}
        </a>
      </template>
      <template #privacyPolicy>
        <a
          :href="$t('auth.dialog.privacyPolicyLink')"
          rel="noopener noreferrer"
          class="underline"
        >
          {{ $t('auth.dialog.privacyPolicy') }}
        </a>
      </template>
      <template #cookiePolicy>
        <a
          :href="$t('auth.dialog.cookiePolicyLink')"
          rel="noopener noreferrer"
          class="underline"
        >
          {{ $t('auth.dialog.cookiePolicy') }}
        </a>
      </template>
    </i18n-t>
  </p>
</template>
