<script lang="ts" setup>
import { useFormStore } from '~/store/form.store';
import CategoryListItem from './CategoryListItem.vue';
import TagsComboBox from './TagsCombobox.vue';
import Tag from './Tag.vue';

const formStore = useFormStore();

function removeTag(tag: string) {
  formStore.category.data.tags = formStore.category.data.tags.filter(
    (t) => t !== tag
  );
}
</script>

<template>
  <div class="flex flex-col border-b-1 first:border-t-1 border-snow-white">
    <CategoryListItem
      v-for="category in formStore.category.data.selectedCategories"
      :key="category"
      class="border-b-1 border-snow-white"
      :category
    />
    <slot />
  </div>
  <TagsComboBox>
    <template #tags="{ open }">
      <div
        class="mt-5 flex flex-row flex-wrap gap-3"
        :class="{ 'pt-82': open }"
      >
        <Tag
          v-for="tag in formStore.category.data.tags"
          :key="tag"
          :name="tag"
          show-close-icon
          @close="removeTag(tag)"
        />
      </div>
    </template>
  </TagsComboBox>
</template>

<style lang="scss" scoped></style>
