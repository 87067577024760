import { z } from 'zod';
import { dateSchema } from '../date.schema';

export const playSchema = z.object({
  values: z
    .object({
      director: z.string(),
      author: z.string(),
      premiereDate: dateSchema.or(z.literal('')),
      name: z.string(),
      length: z.coerce
        .number()
        .max(10000, {
          message: 'max.length.exceeded',
        })
        .min(0),
      actors: z.string(),
    })
    .partial(),
});
