<script lang="ts" setup>
import {
  EventCategory,
  eventCategories,
  EventCategoriesWithTags,
} from 'goout-data';
import { useFormStore } from '~/store/form.store';
import CategoryOption from './CategoryOption.vue';

const { category } = useFormStore();

function getCategoryNumber(clickedCategory: EventCategory) {
  return category.data.selectedCategories.indexOf(clickedCategory) + 1;
}

function handleClick(clickedCategory: EventCategory) {
  const isSelected = category.data.selectedCategories.includes(clickedCategory);
  const { selectedCategories, tags } = category.data;

  if (isSelected) {
    // Remove the category from selected categories
    category.data.selectedCategories = selectedCategories.filter(
      (c) => c !== clickedCategory
    );

    // Update primary category if necessary
    if (clickedCategory === category.data.primaryCategory) {
      category.data.primaryCategory = selectedCategories[0] || null;
    }
    clickedCategory === category.data.primaryCategory
      ? selectedCategories[0] || null
      : category.data.primaryCategory;

    // Remove all tags associated with the removed category
    const tagsToRemove = EventCategoriesWithTags[clickedCategory].tags.flat();
    category.data.tags = tags.filter((tag) => !tagsToRemove.includes(tag));
  } else {
    // Add the category to selected categories
    category.data.selectedCategories.push(clickedCategory);

    // If this is the first category being selected, set it as the primary category
    if (selectedCategories.length === 1) {
      category.data.primaryCategory = clickedCategory;
    }
  }
}
</script>

<template>
  <div class="grid grid-cols-2 gap-3">
    <div v-for="category in eventCategories" :key="category">
      <CategoryOption
        :category
        :index="getCategoryNumber(category)"
        @click="handleClick(category)"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
