<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    class="animate-spin"
    viewBox="0 0 24 24"
  >
    <g
      stroke="currentColor"
      stroke-linecap="square"
      stroke-width="2"
      clip-path="url(#a)"
    >
      <path d="M23 12h-4" />
      <path
        d="M19.778 19.778 16.95 16.95M12 23v-4M4.222 19.778 7.05 16.95M1 12h4M4.222 4.222 7.05 7.05"
        opacity=".4"
      />
      <path d="M12 1v4" opacity=".6" />
      <path d="M19.778 4.222 16.95 7.05" opacity=".8" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
</template>
