import { z } from 'zod';
import { StateEnumSchema } from '../state.schema';
import { TagEnumSchema } from '../tag.schema';
import { EntityTypeEnumSchema } from '../entityType.schema';
import {
  RelationshipToMany,
  RelationshipToOne,
} from '../jsonApi/jsonApiRelationships.schema';
import { City, CitySchema } from '../city/city.schema';
import { User, UserSchema } from '../user/user.schema';
import { Image, ImageDtoAttributesSchema } from '../image/image.schema';
import { Prettify } from '../jsonApi/utils/helpers';
import { VideoSchema } from '../video/video.schema';

const VenueCountsSchema = z.object({
  events: z.number().nullish().describe('Number of events'),
  followers: z.number().nullish().describe('Number of followers'),
});

const VenueDtoLocaleSchema = z.object({
  name: z.string().describe('Roxy Nod'),
  description: z.string().describe('Just a *theatre*. (Deprecated)'),
  descriptionHtml: z
    .string()
    .default('')
    .describe('Just a <strong>theatre</strong>.'),
  siteUrl: z
    .string()
    .nullish()
    .describe('https://goout.net/cs/palac-akropolis/vzjb/'),
  metaDescription: z.string().nullish(),
  metaTitle: z.string().nullish(),
  noEvents: z
    .string()
    .nullish()
    .describe('Klub Matrix ukončil svojí činnost. Nově je z něj Storm.'),
});

export const VenueDtoAttributesSchema = z.object({
  state: StateEnumSchema,
  mainCategory: TagEnumSchema.describe('THEATRE'),
  categories: z.array(TagEnumSchema).describe('["CLUB","THEATRE"]'),
  tags: z.array(TagEnumSchema).describe('["ELECTRO"]'),
  address: z.string(),
  countryIso: z.string().describe('cz'),
  keywords: z.array(z.string()).describe('["roxy","nod"]'),
  latitude: z.number(),
  longitude: z.number(),
  updatedAt: z.coerce.date(),
  counts: VenueCountsSchema,
  email: z.string().nullish().describe('info@venue.com'),
  phone: z.string().nullish().describe('+400 123 456 789'),
  urlFacebook: z
    .string()
    .nullish()
    .describe('https://www.facebook.com/kinopilot'),
  sourceUrl: z.string().nullish().describe('https://www.palacakropolis.cz/'),
  relativeFollowersInWeek: z.number().nullish(),
  locales: z.record(VenueDtoLocaleSchema).nullish(),
});

export const VenueDtoRelationshipsSchema = z.object({
  childVenues: RelationshipToMany(EntityTypeEnumSchema.Values.venues),
  city: RelationshipToOne(EntityTypeEnumSchema.Values.cities),
  followers: RelationshipToMany(EntityTypeEnumSchema.Values.users),
  images: RelationshipToMany(EntityTypeEnumSchema.Values.images),
  parentVenue: RelationshipToOne(EntityTypeEnumSchema.Values.venues),
  revisionParent: RelationshipToOne(EntityTypeEnumSchema.Values.venues),
  revisions: RelationshipToMany(EntityTypeEnumSchema.Values.venues),
  similarsEstimated: RelationshipToMany(EntityTypeEnumSchema.Values.venues),
  similarsSelected: RelationshipToMany(EntityTypeEnumSchema.Values.venues),
  videos: RelationshipToMany(EntityTypeEnumSchema.Values.videos),
});

export const VenueDtoSchema = z.object({
  attributes: VenueDtoAttributesSchema,
  id: z.string(),
  type: z.literal(EntityTypeEnumSchema.Values.venues),
  relationships: VenueDtoRelationshipsSchema,
});

export const VenueIncludesSchema = [
  EntityTypeEnumSchema.Values.cities,
  EntityTypeEnumSchema.Values.images,
  EntityTypeEnumSchema.Values.videos,
  EntityTypeEnumSchema.Values.venues,
];

export const VenueSchema = z
  .object({
    type: z.literal(EntityTypeEnumSchema.Values.venues),
    id: z.string(),
  })
  .merge(VenueDtoAttributesSchema)
  .merge(
    z.object({
      // childVenues: z.lazy(() => z.array(VenueSchema)),
      city: CitySchema,
      followers: z.array(UserSchema),
      images: z.array(ImageDtoAttributesSchema),
      parentVenue: z.lazy(() => z.union([VenueSchema, z.null()])),
      revisionParent: z.lazy(() => z.union([VenueSchema, z.null()])),
      //revisions: z.lazy(() => z.array(VenueSchema)), // We do not want to validate these since it's usualy a lot of data
      //similarsEstimated: z.lazy(() => z.array(VenueSchema)), // Let's keep this so we know that the properties exists though
      //similarsSelected: z.lazy(() => z.array(VenueSchema)),
      videos: z.array(VideoSchema),
    })
  )
  .partial() as unknown as z.ZodSchema<Venue>;

export type VenueDto = z.infer<typeof VenueDtoSchema>;
export type VenueDtoAttributes = z.infer<typeof VenueDtoAttributesSchema>;
export type VenueDtoRelationships = z.infer<typeof VenueDtoRelationshipsSchema>;
export type VenueDtoLocale = z.infer<typeof VenueDtoLocaleSchema>;

export type VenueRelationships = {
  childVenues: Venue[];
  city: City;
  followers: User[];
  images: Image[];
  parentVenue: Venue;
  revisionParent: Venue;
  revisions: Venue[];
  similarsEstimated: Venue[];
  similarsSelected: Venue[];
  videos: Image[];
};

export type VenueIncludes = (typeof VenueIncludesSchema)[number];
export type Venue = Prettify<
  {
    type: typeof EntityTypeEnumSchema.Values.venues;
    id: string;
  } & VenueDtoAttributes &
    VenueRelationships
>;
