<script lang="ts" setup>
import Icon from 'goout-icons/Icon.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import { ref, onMounted } from 'vue';

export interface ToastProps {
  heading?: string;
  message: string;
  duration?: number;
  showCloseIcon?: boolean;
  position:
    | 'top-left'
    | 'top-center'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-center'
    | 'bottom-right';
}

const { duration = 3000, showCloseIcon = true } = defineProps<ToastProps>();
const isShown = ref(true);
const timeDecrement = ref(duration);
onMounted(() => {
  const timer = setInterval(() => {
    timeDecrement.value -= 10;
    if (timeDecrement.value <= 0) {
      isShown.value = false;
      clearInterval(timer);
    }
  }, 10);
});

const postionClass = {
  'top-left': 'fixed top-2 left-2',
  'top-center': 'fixed top-2 left-1/2 transform -translate-x-1/2',
  'top-right': 'fixed top-2 right-2',
  'bottom-left': 'fixed bottom-2 left-2',
  'bottom-center': 'fixed bottom-2 left-1/2 transform -translate-x-1/2',
  'bottom-right': 'fixed bottom-2 right-2',
};
</script>

<template>
  <Transition>
    <div
      v-if="isShown"
      role="toast"
      class="fixed z-50 p-5 shadow-snow-white bg-white shadow-md max-w-78 flex w-full justify-between gap-x-2"
      :class="postionClass[position]"
    >
      <div class="flex gap-x-2 items-center">
        <Icon name="Error" class="min-w-8 ml-2 text-red" />
        <div class="pl-2">
          <h4 class="text-dark font-semibold">{{ heading }}</h4>
          <p class="text-gray-dark font-medium text-sm leading-4">
            {{ message }}
          </p>
        </div>
      </div>
      <Button
        v-if="showCloseIcon"
        class="!px-2 !py-2"
        variant="text"
        :aria-label="'general.close'"
        size="sm"
      >
        <Icon class="p-1" name="Close" @click="isShown = false" />
      </Button>
      <progress
        class="absolute bottom-0 left-0 w-full h-0.5 text-red"
        :value="timeDecrement"
        :max="duration"
        min="0"
      ></progress>
    </div>
  </Transition>
</template>

<style lang="scss" scoped>
/* background: */
progress::-webkit-progress-bar {
  @apply bg-gray-light;
}

/* value: */
progress::-webkit-progress-value {
  @apply bg-red;
}

progress::-moz-progress-bar {
  @apply bg-red;
}

progress {
  @apply text-red;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
