<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 11 7" fill="none">
    <path
      d="M1 1L5.5 5.5L10 1"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
