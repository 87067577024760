const STORAGE_VERSION_KEY = 'creatorVersion';
const CURRENT_VERSION = 2; // Increment this number with each breaking change

/**
 * Detects if the version has changed and clears all local storage data if it has.
 * This is useful for when breaking changes are made to the local storage schema.
 * It will prevent issues with old data being used with a new schema.
 */
export function detectVersionChange() {
  const storedVersion = localStorage.getItem(STORAGE_VERSION_KEY);

  // If there's no version key or the stored version is less than the current version, clear storage and set the new version
  if (storedVersion === null || parseInt(storedVersion) !== CURRENT_VERSION) {
    // Clear all local storage data
    localStorage.removeItem('form'); // In case of other local storage data, add more here

    // Set the current version in local storage
    localStorage.setItem(STORAGE_VERSION_KEY, CURRENT_VERSION.toString());
  }
}
