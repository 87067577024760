import z from 'zod';
import { EntityType } from '../entityType.schema';
import { JsonApiDataSchema } from './jsonApiData.schema';
import { JsonApiIncludedSchema } from './jsonApiIncluded.schema';
import { JsonApiMetaSchema } from './jsonApiMeta.schema';

/**
 * JSON API response used in devour validation
 */
export const JsonApiResponseSchema = z.object({
  data: z.array(JsonApiDataSchema),
  included: z.array(JsonApiIncludedSchema).optional(),
  meta: JsonApiMetaSchema.optional(),
  links: z
    .object({
      self: z.string(),
      next: z.string().nullish(),
    })
    .nullish(),
  jsonapi: z
    .object({
      version: z.string(),
    })
    .nullish(),
  errors: z
    .array(
      z.object({
        title: z.string(),
        detail: z.string(),
        code: z.string(),
        status: z.string(),
        source: z
          .object({
            pointer: z.string(),
            parameter: z.string(),
          })
          .nullish(),
        meta: z.unknown().nullish(),
      })
    )
    .nullish(),
});

export type JsonApiResponse = z.infer<typeof JsonApiResponseSchema>;
