<template>
  <svg
    viewBox="0 0 16 16"
    fill="none"
    class="stroke"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 10V2.667"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M11.333 6.667 8 10 4.667 6.667"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path d="M2 2.667v10.666h12V2.667" stroke="currentColor" stroke-width="2" />
  </svg>
</template>
