<template>
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#a)">
      <path
        d="M12.333 6.667 15.267 14H13.83l-.8-2h-2.727l-.8 2H8.067L11 6.667h1.333ZM6.667 1.333v1.334h4V4H9.355A12.148 12.148 0 0 1 6.94 8.2a9.94 9.94 0 0 0 1.558 1.139l-.501 1.252A11.345 11.345 0 0 1 6 9.15a11.117 11.117 0 0 1-4.134 2.365l-.357-1.286A9.8 9.8 0 0 0 5.06 8.201a12.052 12.052 0 0 1-1.882-2.868h1.493c.372.686.817 1.33 1.329 1.918.833-.96 1.49-2.06 1.94-3.25L1.333 4V2.667h4V1.333h1.334Zm5 7.257-.832 2.077h1.662l-.83-2.077Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a"><path fill="#fff" d="M0 0h16v16H0z" /></clipPath>
    </defs>
  </svg>
</template>
