<script lang="ts" setup>
import TextInput from '~/components/general/TextInput.vue';
import { filmSchema } from '~/definitions/schemas/metadata/film.schema';
import { useFormStore } from '~/store/form.store';
import * as z from 'zod';
import { useJsonApiClient } from 'goout-api';
import { ref, computed } from 'vue';
import ComboboxSingle, {
  ComboboxSingleItem,
} from '~/components/general/ComboboxSingle.vue';
import { getCurrentLocale } from '~/i18n';
import Button from 'goout-ui-kit/button/Button.vue';
import { useI18n } from 'vue-i18n';
import Icon from 'goout-icons/Icon.vue';
import { ComputedRef } from 'vue';

const { metadata_film } = useFormStore();
const query = ref('');
const locale = getCurrentLocale();

const { data, isFetching, execute } = useJsonApiClient({
  entity: 'events',
  include: ['images'],
  baseUrl: 'entitystore/v3/events',
  category: 'film',
  query: query.value,
  immediate: false,
});

const { t } = useI18n();

/**
 * Generates an array of objects containing the key, schema and optional status of each input in the film schema.
 */
const filmMetadataInputs = Object.keys(filmSchema.shape.values.shape).map(
  (key) => ({
    key,
    schema: filmSchema.shape.values.shape[key],
    optional: filmSchema.shape.values.shape[key] instanceof z.ZodOptional,
  })
);

//replace watch with computed
const items: ComputedRef<ComboboxSingleItem[]> = computed(() => {
  return data.value.map((item) => {
    const { id, images, filmMeta } = item;
    return {
      id,
      title: item?.locales?.[locale]?.name || filmMeta?.originalName || '',
      url:
        images[0]?.url ||
        'https://storage.googleapis.com/goout-static/creator/movie-icon.svg',
    };
  });
});

function fillPrefilledFilmToStore(item: ComboboxSingleItem) {
  const id = item?.id;
  if (!id) return;

  const film = data.value.find((film) => film.id === id);
  if (!film) return;
  const filmMeta = filmSchema.shape.values.parse(film?.filmMeta);

  if (film?.filmMeta) {
    Object.keys(filmSchema.shape.values.shape).forEach((key) => {
      if (key === 'tags') {
        //translate tags
        metadata_film.data.values[key] = film[key]?.map((tag) =>
          t(`general.tags.${tag}`)
        );
        return;
      }
      metadata_film.data.values[key] = filmMeta[key] || film[key];
    });
  }
}
</script>

<template>
  <div
    v-if="metadata_film.data?.selectedItem?.id"
    class="flex items-center py-5"
  >
    <img
      v-if="metadata_film.data.selectedItem.url"
      :src="metadata_film.data.selectedItem.url"
      alt="event image"
      class="w-full h-full object-cover rounded-full w-12 h-12 mr-5"
    />
    <div
      v-else
      class="bg-snow-white object-cover rounded-full w-12 h-12 mr-5 text-gray-lighter flex items-center justify-center"
    >
      <Icon name="Movie" class="text-gray-lighter" />
    </div>
    <p class="text-sm font-medium text-dark">
      {{ metadata_film.data.selectedItem.title }}
    </p>
    <Button
      class="w-8 h-8 !p-2 ml-auto"
      variant="border-snow-white"
      size="sm"
      icon="Close"
      round
      @click="
        () => {
          metadata_film.data.selectedItem = {} as ComboboxSingleItem;
          metadata_film.data.values = {};
        }
      "
    />
  </div>
  <ComboboxSingle
    v-model:selected="metadata_film.data.selectedItem"
    v-model:query="query"
    class="mb-5"
    :items
    :label="$t('wizard.tabs.film.search')"
    :is-fetching="!!isFetching"
    @update:query="execute"
    @update:selected="fillPrefilledFilmToStore"
  />
  <!-- Loop through the zod schema fields for given category and render a TextInput component for each input -->
  <div v-for="input in filmMetadataInputs" :key="input.key">
    <TextInput
      v-model.capitalize="metadata_film.data.values[input.key]"
      class="mb-5 pointer-events-none"
      :schema="input.schema"
      :label="$t(`wizard.tabs.film.${input.key}`)"
      disabled
    />
  </div>
</template>

<style lang="scss" scoped></style>
