<script lang="ts">
export default {
  inheritAttrs: false,
};
</script>

<script setup lang="ts">
import Icon, { IconName } from 'goout-icons/Icon.vue';
import Tooltip from '../tooltip/Tooltip.vue';

interface Props {
  name: IconName;
  tooltip?: string;
}
defineProps<Props>();
</script>

<template>
  <Tooltip :text="tooltip || name" position="top" hide-on-mobile>
    <button
      class="rounded-md cursor-pointer font-medium text-gray text-sm outline-0 p-1.5 transition-colors duration-150 btn inline-flex justify-center"
      w:hover="text-gray-dark bg-snow-white"
      v-bind="$attrs"
      type="button"
    >
      <Icon :name="name" class="!h-4 !h-4 !sm:h-4.5 !sm:w-4.5" />
    </button>
  </Tooltip>
</template>

<style lang="scss" scoped>
.is-active {
  @apply bg-blue-light text-blue;
}
</style>
