<script lang="ts" setup>
import TabContainer from '~/components/layout/TabContainer.vue';
import { useFormStore } from '~/store/form.store';

import Button from 'goout-ui-kit/button/Button.vue';
import Icon from 'goout-icons/Icon.vue';
import DateTimePicker from './DateTimePicker.vue';
const formStore = useFormStore();

function addDate() {
  // If there is already a date copy the time from the last date
  if (formStore.date.data.dates.length > 0) {
    const lastDate =
      formStore.date.data.dates[formStore.date.data.dates.length - 1];
    formStore.date.data.dates.push({
      endDate: '',
      startDate: '',
      startTime: {
        hour: lastDate.startTime.hour,
        minute: lastDate.startTime.minute,
      },
      endTime: {
        hour: lastDate.endTime.hour,
        minute: lastDate.endTime.minute,
      },
    });
    return;
  }

  formStore.date.data.dates.push({
    endDate: '',
    startDate: '',
    startTime: {
      hour: '' as unknown as number,
      minute: '' as unknown as number,
    },
    endTime: {
      hour: '' as unknown as number,
      minute: '' as unknown as number,
    },
  });
}

function removeDate(index: number) {
  formStore.date.data.dates = formStore.date.data.dates.filter(
    (_, i) => i !== index
  );
}
</script>

<template>
  <TabContainer
    :title="$t('wizard.tabs.date.title')"
    :perex="$t('wizard.tabs.date.perex')"
  >
    <div
      v-for="(date, index) in formStore.date.data.dates"
      :key="index"
      class="flex flex-col gap-y-2 justify-start"
    >
      <div
        v-if="formStore.date.data.dates.length > 1"
        class="flex justify-between items-center"
        :class="{ 'mt-12': index !== 0 }"
      >
        <div class="w-full bg-snow-white h-0.25"></div>
        <Button
          class="w-8 h-8 !p-2.3 z-10 block !border-opacity-10 mr-auto self-end"
          variant="border"
          icon="Close"
          round
          @click="removeDate(index)"
        />
      </div>

      <DateTimePicker :date />

      <div v-if="index === formStore.date.data.dates.length - 1">
        <div class="bg-gray-lighter w-full h-0.2 my-8 opacity-[0.12]"></div>
        <Button variant="add" class="!px-0 !py-0 self-start" @click="addDate">
          <Icon
            name="Plus"
            class="mr-4 text-snow-white bg-blue rounded-full p-2.5 !w-8 !h-8"
          />
          {{ $t('wizard.tabs.date.add') }}
        </Button>
      </div>
    </div>
  </TabContainer>
</template>

<style lang="scss" scoped></style>
