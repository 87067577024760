<script setup lang="ts">
import Input from './Input.vue';
import Button from 'goout-ui-kit/button/Button.vue';
import ThirdPartySignIn from './ThirdPartySignIn.vue';
import { computed, ref, onMounted } from 'vue';
import { useSignUp } from '../api/useSignUp';
import { ModalTypeEnum } from '../utils/modalTypes';
import { useModalStore } from '../stores/modal.store';
import { z } from 'zod';
import Spinner from './Spinner.vue';
import TermsAndConditions from './TermsAndConditions.vue';
import { useToast } from 'goout-ui-kit/composables/useToast';

const email = ref<string>('');
const { execute: signUp, isFetching, statusCode } = useSignUp(email);

const { switchForms } = useModalStore();
const { showToast } = useToast();

const disabledButton = computed(() => {
  return !email.value || !z.string().email().safeParse(email.value).success;
});

const buttonVariant = computed(() =>
  disabledButton.value ? 'primary-disabled-blue' : 'primary'
);

const handleSignUp = async () => {
  try {
    await signUp(true);
    switchForms(ModalTypeEnum.signUpSuccess);
  } catch (error) {
    if (statusCode.value === 409) {
      showToast({
        heading: 'loginModule.accountExists.error.title',
        message: 'loginModule.accountExists.error.desc',
        position: 'top-right',
      });
      return;
    }
    showToast({
      heading: 'loginModule.error.signUp',
      message: 'loginModule.error.try.again.later',
      position: 'top-right',
    });
  }
};  
</script>

<template>
  <div>
    <ThirdPartySignIn action="signUp" />
    <div class="bg-snow-white h-[1px] w-full my-8"></div>
    <div class="flex flex-col items-center w-full">
      <div class="flex flex-col items-center w-full">
        <form class="relative w-full" @submit="handleSignUp">
          <Input
            v-model="email"
            class="w-full mb-8"
            :label="$t('loginModule.input.email')"
            autofocus
            required
          />
          <Button
            class="w-full"
            :variant="buttonVariant"
            :disabled="disabledButton"
            round
            @click="handleSignUp"
          >
            <Spinner v-if="isFetching" />
            <span v-else>
              {{ $t('loginModule.password.signUp') }}
            </span>
          </Button>
          <TermsAndConditions />

          <p class="text-sm w-auto text-center pt-8 pb-3 font-medium">
            <span>{{ $t('loginModule.existing.profile') + ' ' }}</span>
            <a
              class="cursor-pointer text-blue hover:text-opacity-80"
              @click="switchForms(ModalTypeEnum.signIn)"
              >{{ $t('loginModule.password.sign.in') }}</a
            >
          </p>
        </form>
      </div>
    </div>
  </div>
</template>
