import z from 'zod';
/* 
Donut defines range around given city to search for entities (venues, events, schedules, etc.)
It can container values from D0 up to D6
*/

export const DonutSchema = z.union([
  z.literal('d0'),
  z.literal('d1'),
  z.literal('d2'),
  z.literal('d3'),
  z.literal('d4'),
  z.literal('d5'),
  z.literal('d6'),
]);

export type Donut = z.infer<typeof DonutSchema>;
