<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <path
      d="M6 2.52666C7.86333 1.70333 10.2747 1.80066 11.0987 3.13399C11.922 4.46666 11.354 6.01666 9.942 7.21333C8.53 8.40999 8 9.05933 8 9.99999"
      stroke="currentColor"
      stroke-width="2"
      stroke-miterlimit="10"
      stroke-linecap="square"
    />
    <path
      d="M8 14.6667C8.55228 14.6667 9 14.2189 9 13.6667C9 13.1144 8.55228 12.6667 8 12.6667C7.44772 12.6667 7 13.1144 7 13.6667C7 14.2189 7.44772 14.6667 8 14.6667Z"
      fill="currentColor"
    />
  </svg>
</template>
