<script lang="ts" setup>
import Modal from '~/components/general/Modal.vue';
import TextInput from '~/components/general/TextInput.vue';
import { ImageSchema } from 'goout-schemas';
import { useFormStore } from '~/store/form.store';
import { defineModel, computed, ref } from 'vue';

const { id } = defineProps<{
  url: string;
  id: string;
}>();

const open = defineModel<boolean>();
const name = ref('');
const origin = ref('');
const copyright = ref('');
const formStore = useFormStore();

function handleSave() {
  const image = formStore.image.data.images.find((img) => img.id === id);
  if (!image) return;
  image.name = name.value;
  image.origin = origin.value;
  image.copyright = copyright.value;
  open.value = false;
}

const disabled = computed(
  () => name.value === '' && origin.value === '' && copyright.value === ''
);
</script>

<template>
  <Modal
    v-if="open"
    width="xl"
    :disabled
    :on-close="() => (open = false)"
    @submit="handleSave"
  >
    <template #title>
      {{ $t('image.edit.title') }}
    </template>

    <template #content>
      <div class="flex gap-x-4">
        <div class="min-w-20 min-h-10 flex-1">
          <img class="max-h-56 pr-10" :src="url" />
        </div>
        <div class="flex flex-col gap-3 flex-1 min-w-86">
          <TextInput
            v-model="name"
            :label="$t('image.name')"
            :schema="ImageSchema.shape.name"
          />
          <TextInput
            v-model="origin"
            :label="$t('image.origin')"
            :schema="ImageSchema.shape.origin"
          />
          <TextInput
            v-model="copyright"
            :label="$t('image.copyright')"
            :schema="ImageSchema.shape.copyright"
          />
        </div>
      </div>
    </template>

    <template #confirm>
      {{ $t('image.save') }}
    </template>
  </Modal>
</template>
