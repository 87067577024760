import * as t from 'io-ts';
import {
  IdIO,
  EntityStateIO,
  getEntityRelationShip,
  optional,
} from '../common';
import { processEntity, AtKeysToDate } from '../../serialization/entity';

const TicketingStateIO = t.union([
  t.literal('active'),
  t.literal('sold_out'),
  t.literal('hidden'),
  t.literal('scheduled'),
  t.literal('ended'),
  t.literal('cancelled'),
  t.literal('free'),
  t.literal('voluntary'),
  t.literal('on_venue'),
  t.literal('unknown'),
  t.literal('inner_event_sales'),
  t.literal('external'),
]);

export const ScheduleAttributesIO = t.interface({
  hasTime: t.boolean,
  hasTimeEnd: t.boolean,
  sourceUrls: t.array(t.string),
  startAt: t.string,
  endAt: optional(t.string),
  state: EntityStateIO,
  tags: t.array(t.string),
  updatedAt: t.string,
  doorsTimeAt: optional(t.string),
  announcedAt: optional(t.string),
  publishedAt: optional(t.string),
  isPermanent: t.boolean,
  hash: optional(t.union([t.string, t.number])),
  externalTicketsUrl: optional(t.string),
  ticketingState: TicketingStateIO,
  pricing: optional(t.string),
  externalStreamUrl: optional(t.string),
  parsedAt: optional(t.string),
  counts: t.interface({
    followers: t.number,
    groupedSchedules: optional(t.number),
    groupedSchedulesInVenues: optional(t.number),
  }),
  stage: optional(t.string),
});

export const ScheduleRelationshipsIO = t.interface({
  venue: getEntityRelationShip('venue'),
  event: getEntityRelationShip('event'),
  sale: optional(getEntityRelationShip('sale')),
  parent: optional(getEntityRelationShip('schedule')),
  parentInnerSchedules: optional(t.array(getEntityRelationShip('schedule'))),
  innerSchedules: optional(t.array(getEntityRelationShip('schedule'))),
  duplicateSchedules: t.array(getEntityRelationShip('schedule')),
  contacts: optional(t.array(getEntityRelationShip('contact'))),
});

export const ScheduleIO = t.interface({
  id: IdIO,
  type: t.literal('schedule'),
  url: t.string,
  relationships: ScheduleRelationshipsIO,
  attributes: ScheduleAttributesIO,
});

export type TicketingState = t.TypeOf<typeof TicketingStateIO>;
export type ScheduleRaw = t.TypeOf<typeof ScheduleIO>;
const serialize = (entity: ScheduleRaw) => processEntity(entity, {});
export type Schedule = AtKeysToDate<ReturnType<typeof serialize>>;
