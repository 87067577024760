import { createI18n } from "vue-i18n";
import cs from '../locales/cs.json';
import en from '../locales/en.json';

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  fallbackLocale: 'en',
  messages: {
    en,
    cs,
  },
});

// Rather show the key than an empty string
const originalT = i18n.global.t.bind(i18n.global);
i18n.global.t = <Key extends string>(key: Key, ...args: unknown[]): string => {
    const translation = originalT(key, args); // Pass args as a rest parameter
    if (translation === '') {
        console.warn(`i18n: empty translation for '${key}' in locale '${i18n.global.locale}'`);
        return key;
    }
    return translation;
};