import JsonApi, { ResponsePayload } from 'devour-client';
import {
  EntityTypeSchemaRelationshipMapper,
  EndpointsSchemaEnum,
  EntityTypeSchemaAttributeMapper,
  convertZodToDevourModel,
  JsonApiResponseSchema,
  EntitiesIncludesMapperType,
  ValidIncludes,
  LanguageEnum,
  EntitesSchemaMapper,
  Schedule,
  User,
  Venue,
  Performer,
  EndpointsType,
  Event,
} from 'goout-schemas';
import { z } from 'zod';
import * as Sentry from '@sentry/vue';
import { isRef } from 'vue';

export interface GetArgs<T extends keyof EntitiesIncludesMapperType> {
  entity: T;
  include: ValidIncludes<T>[];
  limit?: number;
  language?: LanguageEnum;
  query?: string;
  donuts?: boolean;
  baseUrl?: EndpointsType;
}

export type ReturnTypeForEntity<T extends keyof EntitiesIncludesMapperType> =
  T extends 'schedules'
    ? Schedule
    : T extends 'users'
    ? User
    : T extends 'venues'
    ? Venue
    : T extends 'performers'
    ? Performer
    : T extends 'events'
    ? Event
    : '';

/**
 * This class is a wrapper around the devour client. It's used to fetch data from the API.
 * It's also responsible for defining the entities and their relationships.
 * It's also responsible for defining the middlewares.
 * It's also responsible for defining the response validation.
 */
export class JsonApiClient {
  public client: JsonApi & { axios?: any };

  constructor() {
    this.client = new JsonApi({
      apiUrl: '/services/' + EndpointsSchemaEnum.Enum['entities/v2'], // Defaults to /services/entities/v2/
      pluralize: false,
      logger: false,
      trailingSlash: {
        resource: false,
        collection: false,
      },
    });

    // Define entities
    for (const entity in EntityTypeSchemaAttributeMapper) {
      this.client.define(entity, {
        ...convertZodToDevourModel(EntityTypeSchemaAttributeMapper[entity]),
        ...convertZodToDevourModel(EntityTypeSchemaRelationshipMapper[entity]),
      });
    }

    // Init middlewares
    this.initMiddlewares();
  }

  private initMiddlewares() {
    this.client.replaceMiddleware('axios-request', cancellableRequest);
    this.client.insertMiddlewareBefore(
      'axios-cancellable-request',
      requestMiddleware
    );

    this.client.insertMiddlewareAfter(
      'axios-cancellable-request',
      ResponseValidationMiddleware
    );

    this.client.replaceMiddleware('errors', errorMiddleware);
  }

  public setApiUrl(newPath: EndpointsType) {
    if (this.client) this.client['apiUrl'] = '/services/' + newPath;
  }

  /**
   * Method to fetch data from the API in a type safe generic way
   * It takes an entity and use it to find the correct schema to parse the response
   * @param param
   * @returns Promise<ReturnTypeForEntity<T>[]>
   */
  public async get<T extends keyof EntitiesIncludesMapperType>({
    entity,
    language,
    include,
    limit,
    query,
    donuts,
    baseUrl,
    ...args
  }: GetArgs<T>): Promise<ReturnTypeForEntity<T>[]> {
    this.client.all(this.urlAppendBasedOnEntity(entity));
    try {
      if (baseUrl) this.setApiUrl(baseUrl);
      const body = {
        donuts,
        ...args,
      };

      // If any of the args is a ref, we need to get the value using isRef
      for (const key in body) {
        if (body[key] && isRef(body[key])) {
          body[key] = body[key].value;
        }
      }

      body['include'] = include.join(',');

      if (query && query.length > 0) {
        body['query'] = (query?.length === 1 ? ' ' + query : query) || ''; // For keywords search where query keyword is of size 1 it's matched exactly against the keywords in ES. Otherwise wildcard search is performed https://gitlab.com/gooutnet/issuetracker/-/issues/42512
      }

      body['limit'] = limit;

      body['languages'] = [language];

      const result = await this.client.get(body);

      const parsedResult = z
        .array(EntitesSchemaMapper[entity as string])
        .parse(result.data);
      return parsedResult as ReturnTypeForEntity<T>[];
    } catch (error) {
      // We don't want to log 401 errors and abort errors, as they are expected
      if (this.client.axios.isCancel(error) || error.response.status === 401) {
      } else {
        Sentry.captureException(error);

        // Let's keep this for debugging purposes
        // This is sort of check if devour is working correctly and the data schemas (particulary the relationships) are correct
        // And we recieve the data in a format we expect
        if (import.meta.env.MODE === 'development') console.error(error);
      }

      // Let's keep this for debugging purposes
      // This is sort of check if devour is working correctly and the data schemas (particulary the relationships) are correct
      // And we recieve the data in a format we expect
      if (import.meta.env.DEV) console.error(error.message);
      Sentry.captureException(error.message);
      throw error;
    }
  }

  /**
   * Method that appends the correct url based on the entity
   **/
  private urlAppendBasedOnEntity(entity: string) {
    if (entity === 'events') {
      return 'find';
    }
    return entity;
  }
}

/**
 * This middleware is responsible for validating the response directly after it's received
 * It's inserted after the axios-request middleware and it checks that the response is JSON API compliant
 */
const ResponseValidationMiddleware = {
  name: 'validation-middleware',
  res: (payload: ResponsePayload) => {
    try {
      JsonApiResponseSchema.parse(payload.res.data);
    } catch (error) {
      // Let's keep this for debugging purposes
      // Basically if the endpoint response is not JSON API compliant, we'll get an error here
      console.error({error});
      Sentry.captureException(error);
    }
    return payload;
  },
};

// It's used to extract the error message from the response
const errorMiddleware = {
  name: 'error-middleware',
  error: function (payload: any) {
    return payload;
  },
};

// To bypass cloudflare gate
const requestMiddleware = {
  name: 'request-headers-middleware',
  req: (payload) => {
    // Add headers
    payload.req.headers['CF-Access-Client-Id'] =
      'ea8553adfbcad6bec65593d2f83832b2.access'; // TODO If here to stay should be moved to env
    payload.req.headers['CF-Access-Client-Secret'] =
      '134042fd0d2e1fbba59e369d83e9032590d512359e48164d02bfb2f4e5299c66';
    return payload;
  },
};

// To be able to cancel jsonApi calls
const cancellableRequest = {
  name: 'axios-cancellable-request',
  req: function (payload) {
    let jsonApi = payload.jsonApi;
    return jsonApi.axios(
      Object.assign(payload.req, {
        cancelToken: new jsonApi.axios.CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          jsonApi.cancel = c;
        }),
      })
    );
  },
};

export default new JsonApiClient();
