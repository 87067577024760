<script setup lang="ts">
import { z } from 'zod';
import { computed, ref } from 'vue';
import PasswordInputEye from './PasswordInputEye.vue';

export interface TextInputProps {
  label: string;
  required?: boolean;
  error?: boolean;
  disabled?: boolean;
  type?: 'text' | 'password';
}

const {
  disabled = false,
  error = false,
  type = 'text',
} = defineProps<TextInputProps>();

const model = defineModel<string>();
const typeRef = ref(type);

function toggleType() {
  typeRef.value = typeRef.value === 'text' ? 'password' : 'text';
}

const autocompleteMap = {
  text: 'email',
  password: 'current-password',
};
</script>

<template>
  <div class="relative h-16">
    <PasswordInputEye
      v-if="type === 'password'"
      class="icon-style"
      @click="toggleType"
    />
    <label class="text-sm relative block h-full">
      <input
        v-model="model"
        :class="{
          '!bg-red/16 shadow-border !shadow-red': error,
          '!text-gray-lighter': disabled,
        }"
        :aria-label="label"
        :type="typeRef"
        :disabled
        :autocomplete="autocompleteMap[type]"
      />
      <slot name="errors" />
      <span
        class="text-gray transform transition-all top-1/2 -translate-y-1/2 left-4 absolute pointer-events-none"
        :class="{ 'has-value': model }"
      >
        {{ label }}
      </span>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.has-value {
  transform: translateY(-110%);
  font-size: 11px;
}

label:focus-within > .text-gray {
  transform: translateY(-110%);
  font-size: 11px;
}

.icon-style {
  @apply text-gray absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer z-50;
}

input {
  @apply bg-snow-white outline-none text-dark w-full pl-4 pr-16 pt-7 pb-4 transition-all focus:shadow-border hover:bg-gray/20 disabled:bg-snow-white;
}

input:-webkit-autofill {
  @apply bg-snow-white outline-none text-dark w-full pl-4 pr-16 pt-7 pb-4 transition-all focus:shadow-border hover:bg-gray/20 disabled:bg-snow-white;
}
</style>
