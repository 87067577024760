<template>
  <svg
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <path
      role="presentation"
      d="M14 2.33333C14 1.10444 12.8956 0 11.6667 0H2.33333C1.10444 0 0 1.10444 0 2.33333V11.6667C0 12.8956 1.10444 14 2.33333 14H7V8.71111H5.28889V6.37778H7V5.47556C7 3.90444 8.18222 2.48889 9.62889 2.48889H11.5111V4.82222H9.62889C9.42667 4.82222 9.17778 5.07111 9.17778 5.44444V6.37778H11.5111V8.71111H9.17778V14H11.6667C12.8956 14 14 12.8956 14 11.6667V2.33333Z"
      fill="currentColor"
      fill-rule="nonzero"
    />
  </svg>
</template>