<script lang="ts" setup>
import { ModalRoute, useModal } from '~/composables/useModal';
import Modal from '~/components/general/Modal.vue';
import { ref } from 'vue';
import { useRouter } from 'vue-router';

export interface ConfirmModalProps {
  title: string;
  description: string;
  successRoute: ModalRoute;
  errorRoute: ModalRoute;
}

const { successRoute, errorRoute } = defineProps<ConfirmModalProps>();
const router = useRouter();
const { callback } = useModal();
const isOpen = ref<boolean>(true);
const isLoading = ref<boolean>(false);

const handleSubmit = async () => {
  try {
    if (!callback.value) {
      router.push({ name: errorRoute });
      return;
    }
    isLoading.value = true;

    const result = await callback.value();
    result.success
      ? router.push({ name: successRoute })
      : router.push({ name: errorRoute });
  } catch (error) {
    router.push({ name: errorRoute });
  } finally {
    isLoading.value = false;
    isOpen.value = false;
  }
};

const handleClose = () => {
  isOpen.value = false;
  router.push({ name: 'Overview' });
};
</script>

<template>
  <Modal
    :open="isOpen"
    overlay-color="gray-dark"
    :is-loading="isLoading"
    width="md"
    :on-close="handleClose"
    @submit="handleSubmit"
  >
    <template #title>
      {{ $t(title) }}
    </template>

    <template #description>
      {{ $t(description) }}
    </template>
    <template #confirm>
      {{ $t('general.confirm') }}
    </template>
  </Modal>
</template>
