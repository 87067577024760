import { getCurrentLocale } from '~/i18n';

export function getDateTimeFormat(date: string) {
  return `${new Date(date).toLocaleDateString()} ${new Date(
    date
  ).toLocaleTimeString(getCurrentLocale(), {
    hour: '2-digit',
    minute: '2-digit',
  })}`;
}

export function getDateFormat(date: string) {
  return `${new Date(date).toLocaleDateString(getCurrentLocale())}`;
}
