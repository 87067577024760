<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 2 14"
    class="stroke fill"
  >
    <path d="M1.5 12.5a.5.5 0 1 1-1 0 .5.5 0 0 1 1 0Z" stroke="currentColor" />
    <path d="M1 0v9" stroke="currentColor" />
  </svg>
</template>
