import { useGooutFetch } from 'goout-api';
import { Ref } from 'vue';
import { PASSWORD_LOGIN } from './endpoints';
import { storeTokens } from './storeTokens';
import { getUserData } from './getUserData';
import { useAuth } from '../composables/useAuth';

type SignInResponse = {
  isNewUser: boolean;
  accessToken: string;
  refreshToken: string;
  userId: string;
};

/**
 * Sign in with username and password
 */
export function usePasswordSignIn(
  loginCredentials: Ref<{
    username: string;
    password: string;
  }>,
  options = { immediate: false }
) {
  const { isAdminOnly } = useAuth();
  const gooutFetchResponse = useGooutFetch<SignInResponse>(
    PASSWORD_LOGIN,
    options
  )
    .post(loginCredentials)
    .json();
  const originalExecute = gooutFetchResponse.execute;

  gooutFetchResponse.execute = async () => {
    // This function returns the accessToken from the server
    const response: Response = await originalExecute(true);

    // Status in the range 200-299
    if (!response?.ok) {
      console.error('Failed to sign in', response);
    }

    // Success response from the server
    const { accessToken } = (await response.json()) as SignInResponse;

    // Store tokens in cookies
    storeTokens(accessToken);

    await getUserData({
      isAdminOnly: isAdminOnly.value,
    });
  };

  return gooutFetchResponse;
}
