<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 19a7 7 0 1 0 0-14 7 7 0 0 0 0 14Z"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 8v4h4"
      stroke="currentColor"
      stroke-width="1.5"
      stroke-miterlimit="10"
    />
  </svg>
</template>
