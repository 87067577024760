export const debounce = (fn: (...args: any[]) => void, ms = 500) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  const debouncedFunction = function (this: any, ...args: any[]) {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  };

  debouncedFunction.cancel = function () {
    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }
  };

  return debouncedFunction;
};
