import { EntityType } from '../entityType.schema';
import z from 'zod';

// See https://jsonapi.org/format/#document-resource-object-relationships
// Function to create a RelationshipToOne schema
export const RelationshipToOne = (entityType: EntityType) => {
  const schema = z
    .object({
      data: z.object({
        id: z.string(),
        type: z.literal(entityType),
      }),
    })
    .nullish()
    .describe('one');

  // Add the entityType to the schema so we can access it later
  // Used for choosing right schema in the mapper
  (schema as any).entityType = entityType;

  return schema;
};

// Function to create a RelationshipToMany schema
export const RelationshipToMany = (entityType: EntityType) => {
  const schema = z
    .object({
      data: z.array(
        z.object({
          id: z.string(),
          type: z.literal(entityType),
        })
      ),
    })
    .describe('many');

  // Add the entityType to the schema so we can access it later
  // Used for choosing right schema in the mapper
  (schema as any).entityType = entityType;

  return schema;
};
