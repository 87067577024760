<script lang="ts" setup>
import ContextMenu from 'goout-ui-kit/contextMenu/ContextMenu.vue';
import Icon from 'goout-icons/Icon.vue';
import { useRouter } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { UserTrackedItemDto } from '~/composables/useTracking.d';
import { useTracking } from '~/composables/useTracking';
import { useDataStore } from '~/store/data.store';
import { useFormStore } from '~/store/form.store';
import { findLocaleWithValue } from '~/utils/findLocalewithValue.util';
import { LocalizedInput } from '~/types/form.types';
import { Tag } from 'goout-schemas';

//id is an event id
const { id } = defineProps<{
  id: string;
}>();

const router = useRouter();
const { t } = useI18n();
const { trackClick } = useTracking();
const dataStore = useDataStore();
const formStore = useFormStore();

const locales = dataStore.getEventById(id)?.locales as LocalizedInput;

const items: UserTrackedItemDto[] = [
  {
    affiliation: 'goout.net',
    itemId: +id,
    itemName: findLocaleWithValue(locales).name,
    itemCategory:
      (formStore.category.data.primaryCategory?.toUpperCase() as Uppercase<Tag>) ||
      null,
  },
];

interface DropdownItem {
  label: string;
  action: () => void;
}

const contextMenuItems: DropdownItem[] = [
  {
    label: t('modal.event.edit'),
    action: () => {
      router.push({ name: 'EventEditModal', params: { id } });

      trackClick({
        category: 'edit_button',
        label: 'Edit',
        type: 'button_click',
        items,
      });
    },
  },
  {
    label: t('modal.event.delete'),
    action: () => {
      router.push({ name: 'EventDeleteModal', params: { id } });
      trackClick({
        category: 'delete_button',
        label: 'Delete',
        type: 'button_click',
        items,
      });
    },
  },
];
</script>

<template>
  <div>
    <ContextMenu v-slot="{ open }" :items="contextMenuItems">
      <Icon
        name="Dots"
        class="text-dark hover:text-blue cursor-pointer"
        :class="{
          'text-blue': open,
        }"
      />
    </ContextMenu>
  </div>
</template>
