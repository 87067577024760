import { createVNode, render } from 'vue';
import { useI18n } from 'vue-i18n';
import Toast, { ToastProps } from '../toast/Toast.vue';

interface ToastQueueItem {
  id: string;
  node: HTMLElement;
  position: ToastProps['position'];
}

class Queue {
  queue: ToastQueueItem[];
  duration: number;
  constructor() {
    this.queue = [];
    this.duration = 0;
  }
  add(toast: ToastQueueItem) {
    this.queue.push(toast);
    const child = toast.node;
    const height = child.clientHeight;
    const PADDING = 8;

    // The - height + PADDING is to make sure the toast is at the bottom of the screen
    if (toast.position.startsWith('bottom'))
      child.style.bottom += this.calculateOffset(
        toast.position,
        height,
        PADDING
      );

    if (toast.position.startsWith('top'))
      child.style.top += this.calculateOffset(toast.position, height, PADDING);

    // Set timeout to remove the toast
    setTimeout(() => {
      this.removeCallback(toast.position, height);
    }, this.duration);
  }

  removeCallback(position: ToastProps['position'], height: number) {
    // Remove item from queue
    this.queue.shift();

    // Update the position of the remaining toasts
    this.queue.forEach((item) => {
      const child = item.node;
      if (position.startsWith('bottom-'))
        child.style.bottom = `${parseInt(child.style.bottom) - height}px`;

      if (position.startsWith('top-'))
        child.style.top = `${parseInt(child.style.top) - height}px`;
    });
  }

  length() {
    return this.queue.length;
  }

  calculateOffset(
    position: ToastProps['position'],
    height: number,
    padding: number
  ) {
    if (position.startsWith('bottom')) {
      return `${this.length() * height - height + padding}px`;
    }

    if (position.startsWith('top')) {
      return `${this.length() * height - height - padding}px`;
    }
  }
}

/**
 * Use toast composable
 * @returns showToast function
 */
export function useToast() {
  const { t } = useI18n();
  const queue = new Queue();

  function showToast({
    heading = 'general.error.title',
    message,
    position,
    duration = 3000,
    showCloseIcon,
  }: ToastProps) {
    // Create a mount point
    const mountEl = document.createElement('div');
    const id = Date.now().toString();
    mountEl.id = id;

    // Create vnode with props and event listeners
    const vnode = createVNode(Toast, {
      heading: t(heading),
      message: t(message),
      position,
      duration,
      showCloseIcon,
    });

    //Remove after timeout the mount element
    setTimeout(() => {
      document.body.removeChild(mountEl);
    }, duration);

    // Mount the vnode to the mountEl
    render(vnode, mountEl);

    // Append the mount element to the body
    document.body.appendChild(mountEl);

    const child = mountEl.children[0] as HTMLElement;
    queue.duration = duration ?? Infinity;
    queue.add({ id, node: child, position });
  }
  return { showToast };
}
