<script setup lang="ts">
import Icon from 'goout-icons/Icon.vue';
import Tooltip from 'goout-ui-kit/tooltip/Tooltip.vue';
import EventBoxActions from './EventBoxActions.vue';
import { getDateTimeFormat } from '~/utils';
import { computed } from 'vue';
import { getCurrentLocale } from '~/i18n';
import { Event, Schedule } from 'goout-schemas';

interface Props {
  id: Event['id'];
  locales: Event['locales'];
  venue: Schedule['venue'];
  date: Schedule['startAt'];
  scheduledDate: Schedule['publishedAt'];
  eventImageUrl?: Event['images'][0]['url'];
  compact?: boolean;
}

const {
  eventImageUrl = 'https://via.placeholder.com/100',
  compact = false,
  date,
  scheduledDate,
  locales,
  venue,
} = defineProps<Props>();
const name = computed(() => locales?.[getCurrentLocale()]?.name);
const venueComputed = computed(
  () => venue.address ?? venue.locales?.[getCurrentLocale()]?.name
);
</script>

<template>
  <div
    class="flex flex-col sm:flex-row text-sm sm:items-center sm:justify-between"
  >
    <div
      class="flex h-20 min-w-32 sm:max-w-32 mr-6"
      :class="{ '!mr-4': compact }"
    >
      <img
        v-if="eventImageUrl"
        :src="eventImageUrl"
        alt="event image"
        class="w-full h-full object-cover"
      />
    </div>
    <div
      class="flex flex-col flex-1 whitespace-nowrap lg:items-around lg:flex-row lg:justify-between sm:gap-x-8 md:gap-x-10 3xl:gap-x-32"
      :class="{
        '!flex-col !items-start gap-y-1 border-b py-2 border-snow-white':
          compact,
      }"
    >
      <p
        class="text-sm font-medium text-dark lg:truncate text-ellipsis w-32 max-w-72 flex-1 self-start"
        :class="{ truncate: compact }"
      >
        {{ name }}
      </p>
      <p
        class="text-gray-dark items-center font-medium white text-gray-dark flex-1 sm:hidden xl:block"
      >
        <Tooltip
          :text="$t('event.startAt')"
          position="top"
          rounded
          class="inline-block"
        >
          <span class="cursor-pointer">{{
            getDateTimeFormat(date.toString())
          }}</span>
        </Tooltip>
      </p>

      <p class="text-gray-dark font-medium truncate text-ellipsis flex-1">
        {{ venueComputed }}
      </p>

      <div v-if="!compact" class="min-w-32 flex items-center">
        <div
          v-if="scheduledDate"
          class="flex items-center text-blue flex-1 mr-5"
        >
          <Icon name="Clock" class="mr-1 mt-[2px] text-blue/40" />
          <p class="font-medium text-right">
            {{ getDateTimeFormat(scheduledDate.toString()) }}
          </p>
        </div>
      </div>
    </div>
    <EventBoxActions
      v-if="!compact"
      :id
      class="flex items-center justify-end pl-10 ml-10"
    />
  </div>
</template>
